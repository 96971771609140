<template>
    <Button
        :variant="supplier?.isInCustomerFollowings ? 'accent' : 'outline'"
        size="small"
        :on-click="handleFollow"
    >
        <div class="flex items-center justify-center space-x-2">
            <UnfollowSolid v-if="supplier?.isInCustomerFollowings" class="w-5 h-5" />
            <FollowSolid v-else class="w-5 h-5" />
            <div>{{ supplier?.isInCustomerFollowings ? $t('text-following-user') : $t('text-follow-user') }}</div>
        </div>
    </Button>
</template>

<script setup>
import Button from "@components/ui/button";
import FollowSolid from "@components/icons/solid/follow-solid";
import UnfollowSolid from "@components/icons/solid/unfollow-solid";

const props = defineProps({
    supplier: {
        type: Object,
        default: () => { }
    }
})

const { $eventBus } = useNuxtApp();

const handleFollow = async () => {
    if (props.supplier?.isInCustomerFollowings) {
        if (customerUnfollowSupplier(props.supplier.id)) {
            props.supplier.isInCustomerFollowings = false;
            $eventBus.emit('supplier:update', {
                id: props.supplier.id,
                isInCustomerFollowings: props.supplier.isInCustomerFollowings
            });
        }
    } else {
        if (customerFollowSupplier(props.supplier.id)) {
            props.supplier.isInCustomerFollowings = true;
            $eventBus.emit('supplier:update', {
                id: props.supplier.id,
                isInCustomerFollowings: props.supplier.isInCustomerFollowings
            });
        }
    }
}

</script>
