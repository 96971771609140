<template>
  <Link
    :href="translatePath(ROUTES.HOME)"
    class="inline-flex"
  >
    <Logo :variant="variant" class="w-22 h-10.5 lg:w-31.5 lg:h-15" />
  </Link>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { siteSettings } from "@settings/site.settings";
import { useSelectedStore } from '@stores/selected';
import Image from "@components/ui/image";
import Logo from "@components/icons/logo";
import Link from "@components/ui/link/link";

const props = defineProps({
  variant: {
    type: String,
    default: 'dark' 
  }
});

const { $eventBus } = useNuxtApp();
const localePath = useLocalePath();
const store = useSelectedStore();

</script>

