<template>
  <div
    :class="className"
    @click.stop="() => {}"
  >
    <button
      @click="onDecrement"
      :class="{ 'cursor-pointer p-2 transition-colors duration-200 focus:outline-none hover:bg-accent-hover': true, 'px-3 py-3 sm:px-2': variant === 'single', 'px-5': variant === 'big', 'hover:!bg-gray-100': variant === 'pillVertical' }"
    >
      <span class="sr-only">{{ $t("text-minus") }}</span>
      <MinusIcon class="h-3 w-3 stroke-2.5" />
    </button>
    <input
      type="number"
      :value="modelValue"
      :disabled="disabled"
      :class="[ 'flex-1 flex items-center justify-center text-sm font-semibold', 'bg-accent w-full text-center border-none px-0', variant === 'pill' ? 'appearance-none hide-arrows' : '' ]"
      :min="0"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="onChange"
    />
    <button
      @click="onIncrement"
      :disabled="plusDisabled"
      :class="{ 'p-2 transition-colors duration-200 focus:outline-none hover:bg-accent-hover': true, 'px-3 py-3 sm:px-2': variant === 'single', 'px-5': variant === 'big', 'hover:!bg-gray-100': variant === 'pillVertical', 'cursor-pointer': !plusDisabled, 'cursor-not-allowed': plusDisabled }"
      :title="plusDisabled ? $t(variant?.product?.type === PRODUCT_TYPE.OFFER ? 'text-out-stock': 'text-not-wanted') : ''"
    >
      <span class="sr-only">{{ $t("text-plus") }}</span>
      <PlusIcon class="h-3.5 w-3.5 md:h-4.5 md:w-4.5 stroke-2.5" />
    </button>
  </div>
</template>

<script setup>
import { PRODUCT_TYPE } from "@utils/constants";
import PlusIcon from "@components/icons/plus-icon";
import MinusIcon from "@components/icons/minus-icon";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  variant: {
    type: String,
    required: false,
    default: () => '' 
  },
  onDecrement: {
    type: Function,
    default: () => { } 
  },
  onIncrement: {
    type: Function,
    default: () => { } 
  },
  onChange: {
    type: Function,
    default: () => { } 
  },
  maxValue: {
    type: Number,
    default: null
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false 
  }
});

const emit = defineEmits(['update:modelValue']);

const className = computed(() => {
  const variantClasses = {
    aquarist: "w-full h-7 md:h-9 bg-accent text-light rounded",
    pill: "flex-row items-center w-20 h-8 bg-accent text-white rounded-full",
    big: "w-full h-14 rounded text-light bg-accent inline-flex justify-between",
  };

  return 'flex overflow-hidden ' + variantClasses[props.variant];
});

const plusDisabled = computed(() => props.disabled || (props.maxValue !== null && props.modelValue >= props.maxValue));

</script>

<style scoped>
/* Custom CSS to hide number input arrows */
.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-arrows {
  -moz-appearance: textfield; /* Firefox */
}
</style>
