import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';

export default async function (variant, quantity) {
  const { $eventBus } = useNuxtApp();
  
  const auth = useAuthStore();
  const cart = useCartStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();

  const item = cart.getItem(variant?.id);

  if (quantity <= 0) {
    throw new Error("cartQuantity can't be zero or less than zero");
  } else if (!auth.isLoggedIn) {
    store.setProduct(variant);
    store.setAction('ADD_TO_CART');
    $eventBus.emit('modal:close');
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
    return;
  } 
  
  await checkSupplier();

  const isBuyerBlocked = !!_.chain(customer)
    .get('supplier.blockedBy', [])
    .filter({ id: variant?.product?.supplier?.id })
    .value()
    .length;
  
  if (isBuyerBlocked) {
    if (process.client) {
      $eventBus.emit('toast:error', 'text-add-to-cart-blocked-notice');
    }
    return;    
  }

  if (item) {
    // cart.setLoading(true);
    store.setAction(null);
    item.quantity = (item.quantity || 0) + quantity;
    item.sum = item.quantity * item?.price;
    item.sumNet = item.quantity * item?.priceNet;

    const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
      method: 'PUT',
      params: {
        idProductVariant: variant?.id,
        quantity: item.quantity
      }
    });

    if (status.value === 'error') {
      if (process.client) {
        $eventBus.emit('toast:error', 'error.cart.add.item');
      }
      sentryCaptureEvent({
        message: 'cart-add-item-to-cart',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      cartAddItem(data.value);
    }
    // cart.setLoading(false);
  } else {
    // cart.setLoading(true);
    store.setAction(null);
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
      method: 'POST',
      params: {
        idProductVariant: variant?.id,
        quantity
      }
    });

    if (status.value === 'error') {
      if (process.client) {
        $eventBus.emit('toast:error', 'error.cart.add.item');
      }
      sentryCaptureEvent({
        message: 'cart-add-item-to-cart',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      cartAddItem(data.value);
      store.setCart(data.value);
      $eventBus.emit('modal:close');
      $eventBus.emit('modal:open', 'CART_ADDED_VIEW');
    }
    // cart.setLoading(false);
  }
}
