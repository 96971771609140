<template>
  <div :class="className">
    <Combobox 
      v-model="selectedOption"
      :name="name"
      :displayValue="displayValue"
      @update:modelValue="value => $emit('update:modelValue', value.id)"
      as="div"
      by="id"
      class="mt-px"
    >
      <ComboboxLabel v-if="label" class="block text-body-dark font-semibold text-sm leading-none mb-3 mt-0.25">
        {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
      </ComboboxLabel>
      <div class="relative mt-1">
        <div
          class="relative w-full cursor-default overflow-hidden text-left focus:outline-none"
        >
          <ComboboxInput
            :class="[ 'px-4 flex items-center w-full appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 mt-0.5', shadow ? 'focus:shadow' : '', variantClasses[variant], sizeClasses[dimension], disabled ? 'bg-gray-100 cursor-not-allowed' : '', error ? '!border-red-500' : '', inputClassName, classBorder ]"
            :displayValue="displayValue"
            @change="query = $event.target.value"
          />
          <ComboboxButton
            class="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            :class="[ 'absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white text-sm', shadow ? 'focus:shadow' : '', variantClasses[variant], disabled ? 'bg-gray-100 cursor-not-allowed' : '', inputClassName, 'border rounded' ]"
          >
            <div
              v-if="filteredOptions.length === 0 && query !== ''"
              class="cursor-default select-none px-4 py-2 text-dark"
            >
              Nothing found.
            </div>

            <ComboboxOption
              v-for="option in filteredOptions"
              as="ul"
              :key="option.id"
              :value="option"
              v-slot="{ selected, active }"
            >
              <li
                class="relative cursor-default select-none py-2"
                :class="{
                  'pl-10 pr-4': showSelectedCheck,
                  'px-4': !showSelectedCheck,
                  'bg-accent text-white': active,
                  'text-dark': !active,
                }"
              >
                <span
                  class="block whitespace-nowrap"
                  :class="{ 'font-medium': selected, 'font-normal': !selected, 'truncate': showSelectedCheck }"
                >
                  <slot name="option" :option="option">
                    {{ option.title || option.name }}
                  </slot>
                </span>
                <span
                  v-if="selected && showSelectedCheck"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                  :class="{ 'text-white': active, 'text-accent': !active }"
                >
                  <CheckIcon class="h-4 w-4" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <p v-if="error" class="my-2 text-xs text-red-500">
        {{ error }}
    </p>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { Combobox, ComboboxLabel, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue';
import CheckIcon from "@components/icons/check-icon";
import ChevronUpDownIcon from "@components/icons/chevron-up-down-icon";

const variantClasses = {
  normal:
    "bg-gray-100 border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border-border-100 focus:bg-light focus:border-accent",
  outline: "border-border-base focus:border-accent",
  line: "ps-0 border-b border-border-base rounded-none focus:border-accent",
};

const sizeClasses = {
  small: "text-sm h-10",
  medium: "h-12",
  big: "h-14",
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [Object, Number],
        default: null
    }, 
    options: {
        type: Array,
        default: ''
    },
    displayValue: {
        type: Function,
        default: (option) => option.title || option.name
    },
    className: {
        type: String,
        default: ''
    },
    classBorder: {
        type: String,
        default: 'border rounded'
    },
    inputClassName: {
        type: String,
        default: ''
    }, 
    label: {
        type: String,
        default: ''
    }, 
    name: {
        type: String,
        default: ''
    }, 
    error: {
        type: String,
        default: ''
    },
    shadow: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    }, 
    showSelectedCheck: {
        type: Boolean,
        default: true
    }, 
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline" | "line"
    }, 
    dimension: {
        type: String,
        default: 'medium' // "small" | "medium" | "big"
    }
});

const selectedOption = ref({ id: null, name: '' });
const query = ref('');

watchEffect(() => {
  selectedOption.value = _.find(props.options, { id: props.modelValue }) || props.options[0];
  if (_.has(selectedOption.value, 'id')) {
    emit('update:modelValue', selectedOption.value?.id);
  }
});

const filteredOptions = computed(() =>
  query.value === ''
    ? props.options
    : props.options.filter((option) =>
        props.displayValue(option)
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)

</script>
