<template>
  <div class="w-fit flex flex-row items-start ml-12 mb-2 group" :data-m-id="`m-${item.id}`">
    <MessageBubble 
      :item="item" 
      :selected="selected" 
      :class="[ 'mr-2', item.id === selected ? 'bg-accent-dark' : 'bg-chat-supplier text-white', sameSupplier ? 'rounded-xl' : 'rounded-b-xl rounded-l-xl' ]" 
      position="right"
    />
    <div :class="[ sameSupplier ? 'w-10 h-10 group-hover:hidden' : '' ]" />
    <div :class="[ sameSupplier ? 'hidden group-hover:block' : '' ]">
      <Avatar v-if="!_.isEmpty(item?.supplier) && item?.supplier?.name" :src="item?.supplier?.logo" class="cursor-pointer" :title="item?.supplier?.name" @click="navigate(`${ROUTES.SUPPLIER}/${slugify(item?.supplier?.id, item?.supplier?.name)}`)" />
      <Avatar v-else :title="`${item?.customer?.firstName} ${item?.customer?.lastName}`.trim()" />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import Avatar from "@components/ui/avatar";
import MessageBubble from "@components/chat/message-bubble";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  prevItem: {
    type: Object,
    required: () => {}
  },
  selected: {
    type: Number,
    default: null
  }
});

const { $eventBus } = useNuxtApp();
const localePath = useLocalePath();

const sameSupplier = computed(() => (props.item?.supplier && props.item?.supplier?.id === props.prevItem?.supplier?.id) 
  || (props.item?.customer && props.item?.customer?.id === props.prevItem?.customer?.id)
);

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const navigate = async (path) => {
  closeModal();
  await navigateTo(translatePath(path));
}

</script>
