<template>
    <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
      <div class="flex justify-center">
        <Logo />
      </div>
      <p v-if="store.action === 'ADD_TO_CART'" class="text-center text-sm md:text-base text-body mt-4 sm:mt-5 mb-8 sm:mb-10">
        {{ $t('text-login-auth-message') }}
        <button
          @click="() => openModal('REGISTER')"
          class="underline font-semibold transition-colors duration-200 focus:outline-none hover:no-underline focus:no-underline"
        >
          {{ $t('text-login-auth-message-link-title') }}
        </button>.
      </p>
      <p v-else class="text-center text-sm md:text-base text-body mt-4 sm:mt-5 mb-8 sm:mb-10">
        {{ $t('login-helper') }}
      </p>
      <form @submit.prevent="">
        <Input
          :label="$t('text-email')"
          v-model="email"
          name="email"
          type="email"
          variant="outline"
          class-name="mb-5"
          :error="errors?.email?.message ? $t(errors.email.message) : ''"
          :required="true"
        />
        <PasswordInput
          :label="$t('text-password')"
          v-model="password"
          name="password"
          :error="errors?.password?.message ? $t(errors.password.message) : ''"
          variant="outline"
          class-name="mb-5"
          :required="true"
          :forgot-page-route-on-click="() => openModal('FORGOT_VIEW')"
        />
        <div class="mt-8">
          <Button
            class="w-full h-11 sm:h-12"
            :loading="loading"
            :disabled="loading"
            :on-click="handleSubmit"
          >
            {{ $t('text-login') }}
          </Button>
        </div>
      </form>
      <Alert
        v-if="errorMsg"
        variant="error"
        :message="errorMsg"
        class="mt-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      />

      <div class="flex flex-col items-center justify-center relative text-sm text-heading mt-8 sm:mt-11 mb-6 sm:mb-8">
        <hr class="w-full" />
      </div>
      <div class="flex flex-col text-sm sm:text-base text-body text-center">
        <div>{{ $t('text-no-account') }}</div>
        <button
          @click="() => openModal('REGISTER')"
          class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline focus:no-underline"
        >
          {{ $t('text-register-here') }}
        </button>
      </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import Logo from "@components/ui/logo";
import Input from "@components/ui/input";
import PasswordInput from "@components/ui/password-input";
import Button from "@components/ui/button";
import Facebook from "@components/icons/facebook";
import Google from "@components/icons/google";
import Alert from "@components/ui/alert";

const loading = ref(false);
const email = ref('');
const password = ref('');
const errors = ref({});
const errorMsg = ref('');

const { $eventBus } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const auth = useAuthStore();
const cart = useCartStore();
const store = useSelectedStore();
const customer = useCustomerStore();

const login = async (body, callbacks) => {
    loading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.SIGN, {
      method: 'POST',
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      callbacks.onSuccess(data.value);
    }
};

const openModal = (view) => {
    $eventBus.emit('modal:close');
    $eventBus.emit('modal:open', view);
}

const closeModal = async () => {
    $eventBus.emit('modal:close');
    if (route?.query?.redirect_uri) {
      router.replace(route?.query?.redirect_uri);
    }
}

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (!email.value) {
    errors.value.email = { message: 'error-email-required' };
  } 
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value) === false) {
    errors.value.email = { message: 'error-email-format' };
  } 
  if (!password.value) {
    errors.value.password = { message: 'error-password-required' };
  } 
  if (Object.keys(errors.value).length !== 0) {
    errorMsg.value = 'error-some-fields-required';
    $eventBus.emit('focus:error', _.first(_.keys(errors.value)));
    return false;
  }

  return true;
}

const handleSubmit = async () => {
  if (!isValid()) {
    return;
  }
  await login(
    {
      email: email.value,
      password: password.value
    },
    {
      onSuccess: async(data) => {
        if (data.sid && data.isLoggedIn) {
          auth.setData(data);
          await customer.load();
          await cartLoad();
          await evalStoreAction();
          await closeModal();
          store.setLoadResetAll();
        } else {
          errorMsg.value = 'error-credential-wrong';
        }
        loading.value = false;
      },
      onError: async (error) => {
        errorMsg.value = 'error-credential-wrong';
        if (error?.data?.statusCode !== 401) {
          sentryCaptureEvent({
            message: 'login.handleSubmit',
            level: 'error',
            extra: { error }
          });
        }
        loading.value = false;
      }
    }
  );
}

</script>
