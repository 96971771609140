<template>
  <div :class="className">
    <label
      v-if="label"
      :htmlFor="name"
      class="block text-body-dark font-semibold text-sm leading-none mb-3 mt-0.5"
    >
      {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
    </label>
    <select
      :id="name"
      :name="name"
      :placeholder="placeholder"
      ref="inputField"
      :value="modelValue"
      :class="[ 'px-4 flex items-center w-full appearance-none transition duration-300 ease-in-out text-sm focus:outline-none', shadow ? 'focus:shadow' : '', variantClasses[variant], sizeClasses[dimension], disabled ? 'bg-gray-100 cursor-not-allowed' : '', error ? '!border-red-500 focus:ring-2 focus:ring-offset-2' : 'focus:ring-0', inputClassName, classBorder ]"
      :disabled="disabled"
      :required="required"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      :aria-invalid="error ? 'true' : 'false'"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option 
        v-for="option in options" 
        :key="option.id"
        :value="option.id"
        :selected="selectedOption?.id === option.id"
        :class="optionsClassName"
      >
        &nbsp;<span v-if="_.has(option, 'level')" v-for="indent in _.times(option.level || 0, _.constant('&nbsp;'))" v-html="indent" />
        {{ option.name }}
      </option>
    </select>
    <p v-if="error" class="my-2 text-xs text-red-500">
        {{ error }}
    </p>
  </div>
</template>

<script setup>
import _ from 'lodash-es';

const variantClasses = {
  normal:
    "bg-gray-100 border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border-border-100 focus:bg-light focus:border-accent",
  outline: "border-border-base focus:border-accent",
  line: "ps-0 border-b border-border-base rounded-none focus:border-accent",
};

const sizeClasses = {
  tiny: "text-xs h-10",
  small: "text-sm h-10",
  medium: "h-12",
  big: "h-14",
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [Number, String],
        default: null
    }, 
    options: {
        type: Array,
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    classBorder: {
        type: String,
        default: 'border rounded'
    },
    inputClassName: {
        type: String,
        default: 'text-heading'
    }, 
    optionsClassName: {
        type: String,
        default: 'text-heading'
    }, 
    label: {
        type: String,
        default: ''
    }, 
    name: {
        type: String,
        default: ''
    }, 
    placeholder: {
        type: String,
        default: ''
    }, 
    error: {
        type: String,
        default: ''
    },
    shadow: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    }, 
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline" | "line"
    }, 
    dimension: {
        type: String,
        default: 'medium' // "tiny", small" | "medium" | "big"
    }
});

const selectedOption = ref(_.find(props.options, { id: props.modelValue }) || props.options[0]);

emit('update:modelValue', selectedOption.value?.id);

const { $eventBus } = useNuxtApp();
const inputField = ref(null);

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
    }
  });
};

onMounted(async () => {
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:error', focusError);
});

</script>
