import _ from 'lodash-es';
import { useCartStore } from '@stores/cart';

export default function (file, notify = false) {
  const { $eventBus } = useNuxtApp();

  const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        const maxWidth = 1920;
        const scaleSize = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleSize;
        
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
        const content = canvas.toDataURL('image/jpeg', 0.8);
        file.encoded = content.substring(content.indexOf(',') + 1);
        
        if (notify) {
          $eventBus.emit('file:loaded', file);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
}
