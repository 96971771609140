import * as Sentry from "@sentry/browser";
import _ from 'lodash-es';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

export default defineNuxtPlugin((nuxtApp) => {

  const { $eventBus } = nuxtApp;

  const config = useRuntimeConfig();

  const firebaseConfig = config.public.firebase;

  let getFcmToken = async () => {};

  if ('serviceWorker' in navigator) {
    const app = initializeApp(firebaseConfig);

    const messaging = getMessaging(app);

    onMessage(messaging, (payload) => {
      // console.log('Message received. ', payload);
      $eventBus.emit('notification:open', payload);
    });

    const fcmToken = ref(null);

    getFcmToken = async () => {
      try {
        const token = await getToken(messaging, { vapidKey: config.public.firebaseVapidKey });
        if (token) {
          // console.log('FCM Token:', token);
          await heartbeatTokenUpdate(token);
        } else {
          Sentry.captureMessage('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        // Sentry.captureException(error);
      }
    };

    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        // console.log('Service Worker registered with scope:', registration.scope);
        let serviceWorker;
        if (registration.installing) {
            serviceWorker = registration.installing;
            // console.log('Service worker installing');
        } else if (registration.waiting) {
            serviceWorker = registration.waiting;
            // console.log('Service worker installed & waiting');
        } else if (registration.active) {
            serviceWorker = registration.active;
            // console.log('Service worker active');
        }

        if (serviceWorker) {
            // console.log("sw current state", serviceWorker.state);
            if (serviceWorker.state == "activated") {
                // console.log("sw already activated - Do watever needed here");
                getFcmToken();
            }
            serviceWorker.addEventListener("statechange", function(e) {
                // console.log("sw statechange : ", e.target.state);
                if (e.target.state == "activated") {
                    // console.log("Just now activated. now we can subscribe for push notification")
                    getFcmToken();
                }
            });
        }
      }).catch((error) => {
        if (typeof Sentry !== 'undefined' && typeof Sentry.captureException === 'function') {
          Sentry.captureException(error);
        }
      });
  }

  return {
    provide: {
        getFcmToken
    }
  }

});
