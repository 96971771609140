<template>
  <button
    @click="onClick"
    :disabled="sending"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark hover:bg-accent-dark-hover focus:bg-accent-dark-hover"
  >
    {{ $t('text-recommend') }}
  </button>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';

const props = defineProps({
  data: {
    type: Object,
    required: true 
  },
  accent: {
    type: Boolean,
    default: false 
  }
});

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const localePath = useLocalePath();
const store = useSelectedStore();
const ui = useUIStore();
const config = useRuntimeConfig();
const sending = ref(false);

const fetchUrl = computed(() => config?.public?.appBaseUrl + API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_ID_MESSAGE.replace('{idVariant}', store.product.id));

const submitVariantMessage = async () => {
  sending.value = true;
  const body = {
    content: `${t('text-recommendation-message')} [${props.data.name}](advert#${props.data.id})`
  }
  try {
    const data = await $fetch(fetchUrl.value, {
      method: 'POST',
      body
    });
    $eventBus.emit('toast:success', 'success.recommend-product.submit');
    $eventBus.emit('message:add', {
      id: store.product.id,
      type: 'variant',
      message: data
    });
  } catch (error) {
    if (process.client) {
      $toast.error(t('error.recommend-product.add.comment'));
    }
    Sentry.captureException(error);
  }
  sending.value = false;
}

const onClick = async () => { 
  await submitVariantMessage();
};

onMounted(() => {
  ui.setDisplayModalStickyBar(false);
});

</script>
