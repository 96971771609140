<template>
  <AddToCartBtn 
    v-if="cart.isInCart(data?.id)"  
    :data="data" 
    variant="outline" 
    :disabled="disabled || (isInCart && outOfStock)" 
    :on-click="handleAddClick" 
  >
    {{ $t('text-in-cart') }}
  </AddToCartBtn>
  <AddToCartBtn 
    v-else-if="_.get(data, 'stockCount', 0) !== 0 && _.get(data, 'availability.isProductOrderable', true)"  
    :data="data" 
    :variant="['offer', 'wanted'].includes(data?.product?.type) ? data?.product?.type : variant" 
    :disabled="disabled || (isInCart && outOfStock)" 
    :on-click="handleAddClick" 
  >
    {{ data?.product?.type === PRODUCT_TYPE.WANTED ? $t('text-to-offer') : $t('text-want-to-buy') }}
  </AddToCartBtn>
  <AddToCartBtn 
    v-else 
    :data="data" 
    variant="disabled" 
    :disabled="true" 
  >
    {{ data?.product?.type === PRODUCT_TYPE.WANTED ? $t('text-not-wanted') : $t('text-out-stock') }}
  </AddToCartBtn>
</template>

<script setup>
import _ from 'lodash-es';
import { PRODUCT_TYPE } from "@utils/constants";
import { cartAnimation } from "@utils/cart-animation";
import { useCartStore } from '@stores/cart';
import { useAuthStore } from '@stores/auth';
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';
import AddToCartBtn from "@components/product/add-to-cart/add-to-cart-btn";

const props = defineProps({
  data: {
    type: Object,
    required: true 
  },
  variant: {
    type: String,
    required: false,
    default: () => 'aquarist' // "helium" | "neon" | "argon" | "oganesson" | "single" | "big"
  },
  counterVariant: {
    type: String,
    required: false,
    default: () => '' // "helium" | "neon" | "argon" | "oganesson" | "single" | "details"
  },
  counterClass: {
    type: String,
    required: false,
    default: () => '' 
  },
  variation: {
    type: Object,
    required: false,
    default: () => { }
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false 
  }
});

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const cart = useCartStore();
const ui = useUIStore();
const store = useSelectedStore();

const handleAddClick = async (e) => {
  e.stopPropagation();
  if (props.data?.product?.type === PRODUCT_TYPE.WANTED) {
    if (!auth.isLoggedIn) {
      $eventBus.emit('modal:close');
      $eventBus.emit('modal:open', 'LOGIN_VIEW');
    } else {
      if (ui.modalView && ui.displayModal) {
        store.setAction('RETURN_TO_PRODUCT_MODAL');
        $eventBus.emit('modal:close');
      }
      store.setProduct(props.data);
      $eventBus.emit('modal:open', 'RECOMMEND_PRODUCT');
    }
  } else if (!cart.isInCart(props.data.id)) {
    if (auth.isLoggedIn) {
      cartAnimation(e);
    }
    await cartAddItemToCart(props.data, 1);
  } else {
    $eventBus.emit('modal:close');
    $eventBus.emit('sidebar:open', 'CART_VIEW');
  }
};

const handleRemoveClick = async (e) => {
  e.stopPropagation();
  await cartRemoveItemOrQuantity(props.data.id, 1);
};

const handleOnChange = async (e) => {
  if (+e.target.value) {
    await cartUpdateItemInCart(props.data.id, +e.target.value);
  } else if (/^\d+?$/.test(e.target.value)) {
    await cartRemoveItemOrQuantity(props.data.id);
  }
};

const isInCart = computed(() => cart.isInCart(props.data.id));
const outOfStock = computed(() => isInCart.value && !cart.isInStock(props.data.id));

</script>
