<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">   
    <ConfirmationCard 
      :icon="CloseIcon"
      :title="$t('text-refuse-order-number', { number: store?.order?.number })"
      :description="$t('text-confirm-refuse-order-title')"
      confirm-btn-text="text-refuse-order"
      :confirm-btn-loading="loading"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { ORDER_STATUS } from "@utils/constants";
import { useSelectedStore } from '@stores/selected'
import ConfirmationCard from "@components/common/confirmation-card";
import CloseIcon from '@components/icons/close-icon';

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const store = useSelectedStore();
const loading = ref(false);

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const handleSubmit = async () => {
  loading.value = true;
  await orderUpdateStatus(store?.order?.id, ORDER_STATUS.REFUSED);
  closeModal();
}

const handleCancel = () => {
  closeModal();
}

</script>
