import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected'

export default function (id, quantity) {
  const { $eventBus } = useNuxtApp();
  const auth = useAuthStore();
  const store = useSelectedStore();

  if (!auth.isLoggedIn) {
    store.setAction(null);
    $eventBus.emit('modal:close');
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
    return false;
  }

  return true;
}
