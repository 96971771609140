import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected'
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const { $eventBus } = useNuxtApp();
  const auth = useAuthStore();
  const cart = useCartStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();

  if (!auth.isLoggedIn || !auth.idCustomer) {
    return;
  }

  customer.loading = true;

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_ID, {
    method: 'GET',
    path: {
      id: auth.idCustomer
    },
  });

  if (status.value === 'error') {
    if (process.client && !auth.reloadTimeout) {
      $eventBus.emit('toast:error', 'error.customer-detail.load');
    }
    sentryCaptureEvent({
      message: 'load-customer',
      level: 'error',
      extra: { error: error.value }
    });
    auth.reset();
    customer.reset();
    cart.reset();
    store.setLoadResetAll();
  } else {
    customer.setData(data.value);
  }
  customer.loading = false;

  return data.value;
}
