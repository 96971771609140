import { useUIStore } from '@stores/ui';

export default function (observedElement, size = 'page') {
  const ui = useUIStore();
  const loading = ref(true);
  const observer = ref(null);
  const pageModal = ref(size);

  onMounted(() => {
    ui.setDisplayModalStickyBar(false);
    observer.value = new window.IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (pageModal.value === 'modal' && !entry.isIntersecting && loading.value) {
          loading.value = false;
          ui.setDisplayModalStickyBar(false);
        } else {
          ui.setDisplayModalStickyBar(!entry.isIntersecting);
        }
      });
    }, {});

    if (observedElement.value) {
      observer.value.observe(observedElement.value);
    }
  });

  onUnmounted(() => {
    if (observedElement.value) {
      observer.value.unobserve(observedElement.value);
    }
    ui.setDisplayModalStickyBar(false);
  });
}
