import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CART_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';

export default async function () {
  const { $eventBus } = useNuxtApp();
  const auth = useAuthStore();
  const cart = useCartStore();

  cart.loading = true;
  cart.list.items = [];

  if (!auth.isLoggedIn || !auth.idSupplier) {
    cart.loading = false;
    return;
  }

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
    method: 'GET',
    params: {
      type: CART_TYPE.BUYER,
      page: cart.pagination.page,
      limit: cart.pagination.itemsPerPage,
      status: 'active'
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'cart-load',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    cart.list.items = data.value.items;
    cart.pagination = data.value.pagination;
  }
  cart.loading = false;
}
