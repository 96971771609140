import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { SELECTED_KEY } from "@utils/constants";
import { PRODUCT_SUBTYPE } from "@utils/constants";

export const useSelectedStore = defineStore(SELECTED_KEY, () => {
  const route = useRoute();

  const routeName = computed(() => route?.name || '');

  const action = ref(null);
  const setAction = (value) => {
    action.value = value;
  }

  const content = ref({});
  const setContent = (value) => {
    content.value = value;
  }

  const product = ref({});
  const setProduct = (value) => {
    product.value = value;
  }

  const variant = ref({});
  const setVariant = (value) => {
    variant.value = value;
  }

  const image = ref({});
  const setImage = (value) => {
    image.value = value;
  }

  const selected = ref({
    'text-billing-address': null,
    'text-shipping-address': null,
  });
  const setSelected = (id, value) => {
    selected.value[id] = value;
  }

  const customerId = ref(null);
  const setCustomerId = (value) => {
    customerId.value = value;
  }

  const errorMsg = ref('');
  const setErrorMsg = (value) => {
    errorMsg.value = value;
  }

  const show = ref(false);
  const toggleShow = () => {
    show.value = !show.value;
  }

  const cart = ref({});
  const setCart = (value) => {
    cart.value = value;
  }

  const order = ref({});
  const setOrder = (value) => {
    order.value = value;
  }

  const score = ref({});
  const setScore = (value) => {
    score.value = value;
  }

  const rating = ref({});
  const setRating = (value) => {
    rating.value = value;
  }

  const data = ref({});
  const setData = (value) => {
    data.value = value;
  }

  const supplier = ref({});
  const setSupplier = (value) => {
    supplier.value = value;
  }

  const mapLocation = ref({});
  const setMapLocation = (value) => {
    mapLocation.value = value;
  }

  const verifiedEmail = ref('');
  const setVerifiedEmail = (value) => {
    verifiedEmail.value = value;
  }

  const verifiedToken = ref('');
  const setVerifiedToken = (value) => {
    verifiedToken.value = value;
  }

  const loadReset = useState('loadReset', () => ({
    [PRODUCT_SUBTYPE.main]: false,
    [PRODUCT_SUBTYPE.imain]: false,
    [PRODUCT_SUBTYPE.ads]: false,
    [PRODUCT_SUBTYPE.category]: false,
    [PRODUCT_SUBTYPE.supplier]: false,
    [PRODUCT_SUBTYPE.isupplier]: false,
    [PRODUCT_SUBTYPE.offer]: false,
    [PRODUCT_SUBTYPE.wanted]: false,
    [PRODUCT_SUBTYPE.favorite]: false,
    [PRODUCT_SUBTYPE.ifavorite]: false,
    [PRODUCT_SUBTYPE.nfavorite]: false,
    [PRODUCT_SUBTYPE.inspiration]: false,
    [PRODUCT_SUBTYPE.news]: false,
  }));
  const setLoadReset = (subtype, value) => {
    loadReset.value[subtype] = value;
  }
  const setLoadResetAll = () => {
    _.assign(loadReset.value, _.mapValues(loadReset.value, () => true));
  }
  const setLoadResetOthers = () => {
    _.assign(loadReset.value, {
      [PRODUCT_SUBTYPE.main]: !routeName.value.startsWith('index___'),
      [PRODUCT_SUBTYPE.imain]: !routeName.value.startsWith('index___'),
      [PRODUCT_SUBTYPE.ads]: !routeName.value.startsWith('classified-ads___'),
      [PRODUCT_SUBTYPE.category]: !routeName.value.startsWith('category-slug___'),
      [PRODUCT_SUBTYPE.supplier]: !routeName.value.startsWith('supplier-supplier_id___'),
      [PRODUCT_SUBTYPE.isupplier]: !routeName.value.startsWith('supplier-supplier_id___'),
      [PRODUCT_SUBTYPE.offer]: !routeName.value.startsWith('offers___'),
      [PRODUCT_SUBTYPE.wanted]: !routeName.value.startsWith('requests___'),
      [PRODUCT_SUBTYPE.favorite]: !routeName.value.startsWith('favorites___'),
      [PRODUCT_SUBTYPE.ifavorite]: !routeName.value.startsWith('favorites___'),
      [PRODUCT_SUBTYPE.nfavorite]: !routeName.value.startsWith('favorites___'),
      [PRODUCT_SUBTYPE.inspiration]: !routeName.value.startsWith('inspiration___'),
      [PRODUCT_SUBTYPE.news]: !routeName.value.startsWith('news___'),
    });
  }

  const coordinates = useState('geolocation.coordinates', () => ({ 
    latitude: _.get(route.query, 'latitude', null), 
    longitude: _.get(route.query, 'longitude', null) 
  }));
  const setCoordinates = (value) => {
    _.assignIn(coordinates.value, value);
  }

  const hasCurrentLocation = () => {
    return coordinates.value.latitude && coordinates.value.longitude;
  }

  const stack = useState('modal.stack', () => []);
  const resetStack = () => {
    stack.value = process.client ? [ window.location.href ] : [];
  }
  const lastInStack = () => {
    return stack.value?.length ? (stack.value[stack.value?.length - 1] || null) : null;
  }
  const addToStack = (value) => {
    stack.value.push(value);
  }
  const removeFromStack = () => {
    stack.value.splice(stack.value?.length - 1, 1);
  }

  return { 
    action, setAction, 
    content, setContent, 
    product, setProduct, 
    variant, setVariant, 
    image, setImage, 
    selected, setSelected,
    customerId, setCustomerId,
    errorMsg, setErrorMsg,
    show, toggleShow,
    cart, setCart,
    order, setOrder,
    score, setScore,
    rating, setRating,
    data, setData,
    supplier, setSupplier,
    mapLocation, setMapLocation,
    verifiedEmail, setVerifiedEmail,
    verifiedToken, setVerifiedToken,
    loadReset, setLoadReset, setLoadResetAll, setLoadResetOthers,
    coordinates, setCoordinates, hasCurrentLocation,
    stack, addToStack, removeFromStack, lastInStack, resetStack
  }
})
