import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CONTENT_TYPE } from "@utils/constants";

export default async function (subtype) {
  try {
    const config = useRuntimeConfig();
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.CONTENT_POST}`, {
      method: 'POST',
      params: { type: CONTENT_TYPE.PAGE, subtype, page: 1, limit: 100 }
    });

    return data?.items || [];
  } catch (error) {
    Sentry.captureException(error);
  }

  return null;
}
