import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCustomerStore } from '@stores/customer';

export default async function (idLanguage = 1, idCurrency = 1) {
  const customer = useCustomerStore();
  const { $eventBus } = useNuxtApp();

  customer.loading = true;

  const body = { idLanguage, idCurrency };

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.VISITOR, {
    method: 'PUT',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'visitor-update',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    customer.setLanguage(data.value?.language || {});
    customer.setCurrency(data.value?.currency || {});
    $eventBus.emit('visitor:update', data.value);
  }
  customer.loading = false;
}
