<template>
  <div v-if="value" class="group">
    <span v-if="format === 'html'" :class="[ 'prose max-w-none', className ]" v-html="textToHtml((value && value.length < character) || expanded ? value : _truncate(value, { length: character }))" />
    <span v-else :class="[ 'prose max-w-none', className ]">
      {{ (value && value.length < character) || expanded ? value : _truncate(value, { length: character }) }}
    </span>
    <template v-if="value && value.length > character && !expanded">
      <span class="ml-1 inline-block">
        <a
          href="#"
          @click="toggleLines"
          :class="[ className, 'font-bold !text-primary-dark group-hover:text-primary-dark-hover focus:text-primary-dark-hover group-hover:underline' ]"
        >
          {{ more ? more : $t('common:text-read-more') }}
        </a>
      </span>
    </template>
    <template v-if="value && value.length > character && expanded">
      <span class="inline-block">
        <a
          href="#"
          @click="toggleLines"
          :class="[ className, 'font-bold !text-primary-dark group-hover:text-primary-dark-hover focus:text-primary-dark-hover group-hover:underline' ]"
        >
          {{ less ? less : $t('common:text-less') }}
        </a>
      </span>
    </template>
  </div>
</template>

<script setup>
import _truncate from 'lodash-es/truncate';

const props = defineProps({
    value: {
        type: String,
        default: ''
    },
    character: {
        type: Number,
        default: 150
    },
    more: {
        type: String,
        default: ''
    },
    less: {
        type: String,
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    format: {
        type: String,
        default: 'plain'
    }
})

const expanded = ref(false);

const toggleLines = (event) => {
    event.preventDefault();
    expanded.value = !expanded.value;
};

</script>
