import * as Sentry from "@sentry/vue";
import _get from 'lodash-es/get';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';

export default async function (id, quantity = null) {
  const { $eventBus } = useNuxtApp();
  const cart = useCartStore();
  const store = useSelectedStore();
  const item = cart.getItem(id);

  if (item) {
    // cart.setLoading(true);
    if (item.quantity > 1 && quantity !== null && (item.quantity || 0) - quantity > 0) {
      const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
        method: 'PUT',
        params: {
          idProductVariant: id,
          quantity: (item.quantity || 0) - quantity
        }
      });

      if (status.value === 'error') {
        sentryCaptureEvent({
          message: 'cart-remove-item-or-quantity',
          level: 'error',
          extra: { error: error.value }
        });
      } else {
        cartAddItem(data.value);
      }
    } else {
      store.setVariant({ id });
      $eventBus.emit('sidebar:close');
      $eventBus.emit('modal:close');
      $eventBus.emit('modal:open', 'CART_REMOVED_VIEW');
    }
    // cart.setLoading(false);
  }
}
