<template>
    <section class="flex flex-col h-full relative">
      <header class="fixed max-w-md w-full top-0 z-10 bg-light py-4 px-6 flex items-center justify-between border-b border-border-200 border-opacity-75">
        <div class="flex text-primary-dark font-semibold">
          <CartCheckBag class="flex-shrink-0" :width="24" :height="22" />
          <span class="flex ms-2">
            {{ formatString(cart.totalUniqueItems, $t("common:text-item")) }}
          </span>
        </div>
        <button
          @click="closeSidebar"
          class="w-7 h-7 ms-3 -me-2 flex items-center justify-center rounded-full text-muted bg-gray-100 transition-all duration-200 focus:outline-none hover:bg-accent focus:bg-accent hover:text-light focus:text-light"
        >
          <span class="sr-only">{{ $t("text-close") }}</span>
          <CloseIcon class="w-3 h-3" />
        </button>
      </header>

      <div v-if="cart.loading" class="grid place-items-center h-full">
          <Spinner :text="$t('common:text-loading')" />
      </div>

      <div v-else class="flex-grow pt-16" :style="{ height: 'calc(100vh - 85px)' }">
        <div v-if="cart.list.items.length > 0">
          <div v-if="visibleCarts.length" class="border-b border-solid border-border-200 border-opacity-75 p-5 text-xs lg:text-sm">
            <p  
              v-for="(line, idx) in (visibleCarts.length === 1 ? $t('text-one-active-cart-hint-title') : $t('text-more-active-carts-hint-title')).split(/(?<=\.)/)"
              :key="idx"
            >
              {{ line }}
            </p>
          </div>
          <div 
            v-for="group in cart.list.items" 
            :key="group.id" 
            @click="() => handleCheckoutCart(group.id)"
          >
            <div 
              v-if="group.variants.length > 0"
              class="border-b border-solid border-border-200 border-opacity-75 hover:bg-gray-100 pt-8" 
            >
              <div 
                class="flex flex-row items-center justify-between space-x-2 ml-3 mr-4 cursor-pointer focus:outline-none focus:bg-accent focus:text-light rounded"
              >
                <ProfileHeader :data="group" class="m-2 overflow-hidden text-primary-dark" :type="CART_TYPE.SELLER" :show="{ rating: true, status: true }" />
              </div>
              <CartItem v-for="item in group.variants" :key="item.id" :data="item" :currency="group.currency" />
              <div class="text-center mb-9">
                <Button class="ms-auto" variant="submit" size="small">
                  <div class="flex items-center space-x-2 text-sm">
                    <div>{{ $t('text-proceed-order') }}</div>
                    <ChevronRight class="w-4 h-4" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <footer v-if="false" class="w-full py-5 px-6 z-10 bg-light">
            <div
              class="flex justify-between w-full h-12 md:h-14 p-1 text-sm font-bold border bg-gray-200 rounded-full transition-colors"
              @click="handleCheckout"
            >
              <span class="flex flex-1 items-center h-full px-5 text-heading">
                {{ $t("text-total-price") }}
              </span>
              <span class="flex items-center flex-shrink-0 h-full bg-light text-heading rounded-full px-5">
                {{ totalPrice }}<span v-if="hasNegotiable" :title="$t('text-some-items-have-price-negotiable')">&nbsp;*</span>
              </span>
            </div>
          </footer>
        </div>
        <div v-else
            layout
            initial="from"
            animate="to"
            exit="from"
            variants="fadeInOut(0.25)"
            class="h-full flex flex-col items-center justify-center"
          >
            <CartCheckBag class="w-40 h-40 text-primary-dark" />
            <h4 class="mt-6 text-base font-semibold text-primary-dark">
              {{ $t("text-no-products") }}
            </h4>
          </div>
      </div>
    </section>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { CART_TYPE } from "@utils/constants";
import { formatString } from "@utils/format-string";
import { useCartStore } from '@stores/cart';
import { useCustomerStore } from '@stores/customer';
import usePrice from "@utils/use-price";
import Spinner from "@components/ui/loaders/spinner/spinner";
import CartItem from "@components/cart/cart-item";
import CartCheckBag from "@components/icons/cart-check-bag";
import CloseIcon from "@components/icons/close-icon";
import ProfileHeader from "@components/profile/profile-header";
import Button from "@components/ui/button";
import ChevronRight from "@components/icons/chevron-right";

const router = useRouter();
const cart = useCartStore();
const customer = useCustomerStore();
const { $eventBus } = useNuxtApp();
const localePath = useLocalePath();

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
}

const hasNegotiable = computed(
  () => _.reduce(
    _.flatMap(cart.list.items, (item) => item?.variants || []), 
    (result, item) => {
      return result || _.get(item, 'price', null) === null
    }, 
    false
  )
);

const handleCheckoutCart = async (id) => {
    closeSidebar();
    await navigateTo(translatePath(`${ROUTES.CHECKOUT}/${id}`));
}

const totalPrice = computed(() => usePrice({
  amount: cart.total / (customer?.language?.currency?.currencyRate || 1),
  currencyCode: customer?.language?.currency?.code
}).price);

const visibleCarts = computed(() => _.filter(cart.list.items, group => group.variants.length > 0));

</script>
