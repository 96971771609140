<template>
  <button
    v-if="accent"
    @click="onClick"
    :disabled="disabled"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-primary-dark rounded-full transition-colors bg-accent-dark hover:bg-accent-dark-hover focus:bg-accent-dark-hover"
  >
    {{ $t('text-edit') }}
  </button>
  <button
    v-else
    @click="onClick"
    :disabled="disabled"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:bg-primary-dark-hover focus:bg-primary-dark-hover"
  >
    {{ $t('text-edit') }}
  </button>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import PencilIcon from "@components/icons/pencil-icon";

const props = defineProps({
  data: {
    type: Object,
    required: true 
  },
  accent: {
    type: Boolean,
    default: false 
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false 
  }
});

const { $eventBus } = useNuxtApp();
const localePath = useLocalePath();

const onClick = async () => { 
  $eventBus.emit('modal:close');
  await navigateTo(translatePath(`${ROUTES.INSPIRATION_EDIT}/${props.data?.id}`));
};

</script>
