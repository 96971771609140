<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <Toaster />
    <Notifications />
    <ManagedModal />
    <SidebarContainer />
  </div>
</template>

<script setup>
import { useUIStore } from '@stores/ui';
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useCustomerStore } from '@stores/customer';
import ManagedModal from "@components/ui/modal/managed-modal";
import SidebarContainer from "@components/common/sidebar/sidebar-container";
import Notifications from "@components/ui/toast/notifications";
import Toaster from "@components/common/toaster";

const ui = useUIStore();
const auth = useAuthStore();
const cart = useCartStore();
const customer = useCustomerStore();

const loaded = ref(false);

watch(() => ui.displaySidebar, (value) => {
  if (process.client) {
    if (value) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
});

onServerPrefetch(async () => {
  await loadMe();
  await loadCurrencies();
  await loadCategories();
  loaded.value = true;
});

onMounted(async () => {
  await loadMe();
  await loadLanguages();
  await loadCurrencies();
  await loadCategories();
  await customer.load();
  loaded.value = true;
  await cartLoad();
  await loadParameters();
  await loadCountries();
  await loadEshopAvailabilities();
});

</script>
