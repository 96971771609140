<template>
  <div v-for="idParameter in siteSettings.product.card.tags.parameters" :key="idParameter">
    <FilterTag 
      v-for="item in _.filter(data?.parameters || [], item => item?.parameter?.id === idParameter)" 
      :key="item?.id" 
      :link="`${ROUTES['ADS']}?idCategory=${data?.product?.category?.id}&p[${item.parameter.id}]=${item?.value?.id}`"
      :on-click="() => handleClick(item)"
      :class="className"
    >
      {{ item?.value?.value }}
    </FilterTag>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import FilterTag from "@components/filter/filter-tag";

const props = defineProps({
  data: {
    type: Object,
    required: true 
  },
  className: {
    type: String,
    value: ''
  }
});

const { $eventBus } = useNuxtApp();

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleClick = (item) => {
  closeModal();
  useSearchParam(`p[${item.parameter.id}]`, `${item?.value?.id}`);
}

</script>
