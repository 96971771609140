<template>
    <div>
      <Input
        :label="$t('text-email')"
        v-model="email"
        name="email"
        type="email"
        variant="outline"
        class-name="mb-5"
        placeholder=""
        :error="errors?.email?.message ? $t(errors.email.message) : ''"
        :required="true"
      />
      <Button 
        class-name="w-full h-11" 
        :loading="loading"
        :disabled="loading"
        :on-click="handleSubmit"
      >
        {{ $t('text-submit-email') }}
      </Button>
      <Alert
        v-if="errorMsg"
        variant="error"
        :message="errorMsg"
        class="mt-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      />
    </div>
</template>

<script setup>
import Input from "@components/ui/input";
import Button from "@components/ui/button";
import Alert from "@components/ui/alert";

const props = defineProps({
    onSubmit: {
        type: Function,
        default: () => { }
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const email = ref('');
const errors = ref({});
const errorMsg = ref('');

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (!email.value) {
    errors.value.email = { message: 'error-email-required' };
  } 
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value) === false) {
    errors.value.email = { message: 'error-email-format' };
  } 
  if (Object.keys(errors.value).length !== 0) {
    errorMsg.value = 'error-some-fields-required';
    return false;
  }

  return true;
}

const handleSubmit = () => {
  if (!isValid()) {
    return;
  }
  props.onSubmit({ email: email.value });
};

</script>
