<template>
    <div :class="sizeClass" :title="title">
        <div :class="[ 'relative overflow-hidden rounded-full', sizeClass ]">
          <Image v-if="src" :alt="title" :src="src" layout="fill" :priority="true" />
          <div v-else class="flex items-center justify-center text-sm font-bold text-light w-full h-full bg-accent">
            {{ initials }}
          </div>
        </div>
    </div>
</template>

<script setup>
import _chain from 'lodash-es/chain';
import _upperFirst from 'lodash-es/upperFirst';
import Image from "@components/ui/image";

const props = defineProps({
    src: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: 'normal'
    }
});

const sizeClass = computed(() => {
    return {
        small: 'w-7.5 h-7.5',
        normal: 'w-10 h-10',
        reference: 'w-30 h-30'
    }[props.size];
});

const initials = computed(() => _chain(props.title)
    .split(' ')
    .map((part) => _upperFirst(part.charAt(0)))
    .join('')
    .value()
);

</script>
