<template>
  <article :class="['product-card cart-type-aquarist rounded-xxl w-full sm:w-74 h-full bg-light overflow-hidden transition-all duration-200 hover:shadow transform hover:-translate-y-0.5 border border-primary-neutral hover:border-primary-neutral-hover', className, `product-card-${variant.id}`]">
    <div class="relative flex items-center justify-center cursor-pointer w-auto h-50">
      <span class="sr-only">{{ $t('text-product-image') }}</span>
      <a 
        :href="`${ROUTES.PRODUCT}/${slugify(variant.id, variant.name)}`"
        class="block overflow-hidden absolute top-0 left-0 bottom-0 right-0 box-border m-0 text-light bg-radial-gradient" 
        @click.prevent="handleProductQuickView"
      >
        <Image 
          :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage" 
          :alt="variant.name" 
          layout="fill" 
          object-fit="cover" 
          class-name="product-image" 
        />
      </a>
      <div class="absolute top-0 pt-2.5 px-5 w-full flex justify-between space-x-5">
        <div v-if="discount" class="rounded-xl text-xs leading-6 font-semibold bg-orange text-light" v-text="discount" />
        <Link v-else class="flex flex-row space-x-1 items-center overflow-hidden cursor-pointer group" :href="translatePath(`${ROUTES.SUPPLIER}/${slugify(variant?.product?.supplier?.id, variant?.product?.supplier?.name)}`)">
          <Avatar :src="variant?.product?.supplier?.logo" size="small" :title="variant?.product?.supplier?.name" />
          <div class="flex flex-col w-full text-light font-bold text-xs group-hover:underline">
            <div>{{ supplierName?.first }}</div>
            <div>{{ supplierName?.last }}</div>
          </div>
        </Link>
        <VariantFavorite v-if="_.has(variant, 'isInCustomerFavorites')" :variant="variant" :accent="true" />
      </div>
      <div v-if="_.has(variant, 'isInCustomerLikes')" class="absolute bottom-4 start-5 flex flex-row items-center justify-start space-x-2.5">
        <VariantViews :variant="variant" :accent="true" />
        <VariantLikes :variant="variant" :accent="true" />
        <MessagesBadge :variant="variant" :accent="true" :on-click="openDetailsWithComments" />
      </div>
      <div v-if="variant?.product?.type === PRODUCT_TYPE.WANTED" class="absolute bottom-0 end-0 flex items-center justify-center">
        <CornerTriangle class="w-17.5 h-17.5 text-accent-light" />
        <div class="absolute uppercase text-2xs font-bold -rotate-45 top-7.5 left-4 w-17.5">{{ $t('text-request') }}</div>
      </div>
    </div>
    <header class="flex flex-col justify-between px-5 pt-4 pb-5 h-46">
      <div>
        <a :href="`${ROUTES.PRODUCT}/${slugify(variant.id, variant.name)}`" @click.prevent="handleProductQuickView"
        >
          <h3 class="line-clamp-2 leading-tight text-lg text-primary-dark font-bold overflow-ellipsis overflow-hidden cursor-pointer mb-1 hover:primary-dark-hover hover:underline focus:primary-dark-hover focus:underline" v-text="variant.name" :title="variant.name" />
        </a>
        <div class="w-full mb-2 text-xs text-primary-dark font-normal truncate">
          <span v-if="variant?.publishedAt">{{ $dayjs(variant?.publishedAt).fromNow() }}</span>
          <span v-if="variant?.publishedAt" class="text-primary-neutral"> • </span>
          <span v-if="variant?.product?.supplier?.city"><span :class="variant?.product?.supplier?.latitude && variant?.product?.supplier?.longitude ? 'hover:underline cursor-pointer' : ''" @click="() => handleOpenLocation(variant?.product?.supplier)">{{ variant?.product?.supplier?.city }}</span>, </span>
          <span v-if="_.isNumber(props.variant?.product?.supplier?.distanceKm)">{{ variant?.product?.supplier?.distanceKm }} {{ $t('text-unit-km') }}</span>
          <span v-else :title="store.hasCurrentLocation() ? null : $t('text-allow-geolocation')" class="cursor-pointer" @click="handleGetCoordinates">? {{ $t('text-unit-km') }}</span>
          <span class="text-primary-neutral"> • </span>
          <span v-if="variant?.product?.type">{{ variant?.product?.type === PRODUCT_TYPE.WANTED ? $t('text-request') : $t('text-offer') }}</span>
        </div>
      </div>
      <div>
        <Scrollbar class="w-full" auto-hide="leave">
          <div class="w-full flex flex-nowrap pb-2">
            <FilterTagCategory :data="variant" />
            <FilterTagParameters v-if="windowSize.width.value >= 480" :data="variant" />
          </div>
        </Scrollbar>
        <div class="flex items-center justify-between">
          <div class="flex items-center justify-start mr-2">
            <span class="text-base text-primary-dark font-bold" v-text="_.get(variant, 'price', null) === null ? $t('text-negotiable') : (basePrice ? basePrice : price)" />
            <del v-if="discount" class="text-sm text-primary-neutral ms-2" v-text="discount" />
          </div>
          <RecommendProductBtn v-if="action === 'recommend'" :data="variant" />
          <ViewProductBtn v-else-if="action === 'view'" :data="variant" />
          <EditProductBtn v-else-if="_.get(variant, 'product.supplier.id') === auth?.idSupplier" :data="variant.product" variant="simple" />
          <AddToCart v-else :data="variant" variant="simple" />
        </div>
      </div>
    </header>
  </article>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE, STATUS } from "@utils/constants";
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import usePrice from "@utils/use-price";
import Image from "@components/ui/image";
import Button from "@components/ui/button";
import EditIcon from "@components/icons/edit-icon";
import AddToCart from "@components/product/add-to-cart/add-to-cart";
import EditProductBtn from "@components/product/product-edit/edit-product-btn";
import RecommendProductBtn from "@components/product/recommend-product-btn";
import ViewProductBtn from "@components/product/view-product-btn";
import ProductStatus from "@components/product/product-edit/product-status";
import VariantFavorite from "@components/product/product-details/variant-favorite";
import VariantViews from "@components/product/product-details/variant-views";
import VariantLikes from "@components/product/product-details/variant-likes";
import MessagesBadge from "@components/common/messages-badge";
import FilterTagCategory from "@components/filter/filter-tag-category";
import FilterTagParameters from "@components/filter/filter-tag-parameters";
import Scrollbar from "@components/ui/scrollbar";
import CornerTriangle from "@components/icons/corner-triangle";
import Avatar from "@components/ui/avatar";
import Link from "@components/ui/link/link";

const props = defineProps({
  variant: {
    type: Object,
    required: true 
  },
  action: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    required: false,
    default: () => '' 
  }
})

const auth = useAuthStore();
const store = useSelectedStore();
const ui = useUIStore();
const { t } = useI18n();
const { $eventBus, $toast, $dayjs } = useNuxtApp();
const localePath = useLocalePath();
const geolocation = useGeolocation();
const windowSize = useWindowSize();

const { price, basePrice, discount } = usePrice({
  amount: props.variant.price,
  baseAmount: props.variant.sale_price,
  currencyCode: props.variant?.currency?.code
});

const subheader = computed(() => _.join(_.filter([ 
    props.variant?.publishedAt ? $dayjs(props.variant.publishedAt).fromNow() : null,
    props.variant?.product?.supplier?.city, 
]), ' • '));

const supplierName = computed(() => {
  const str = props.variant?.product?.supplier?.name || '';
  const middleIndex = Math.floor(str.length / 2);
  let leftIndex = str.lastIndexOf(' ', middleIndex);
  let rightIndex = str.indexOf(' ', middleIndex + 1);
  if (rightIndex === -1) {
    rightIndex = str.length;
  }
  if (leftIndex === -1) {
    leftIndex = 0;
  }
  const useLeft = (middleIndex - leftIndex) < (rightIndex - middleIndex);
  const splitIndex = useLeft ? leftIndex : rightIndex;
  const first = _.trim(_.slice(str, 0, splitIndex).join(''));
  const last = _.trim(_.slice(str, splitIndex + 1).join(''));
  return { first, last };
});

const handleProductQuickView = async (action = null) => { 
  if (ui.displayModal) {
    $eventBus.emit('modal:close');
  }
  nextTick(async () => {
    if (_.get(props.variant, 'product.status', STATUS.DRAFT) !== STATUS.ACTIVE) {
      await navigateTo(translatePath(`${ROUTES.AD_EDIT}/${props.variant?.product?.id}`));
    } else {
      store.setAction(action);
      store.setProduct(props.variant);
      $eventBus.emit('modal:open', 'PRODUCT_DETAILS');
    }
  });
};

const openDetailsWithComments = () => handleProductQuickView('SELECT_TAB_COMMENTS');

const handleGetCoordinates = () => {
  geolocation.withLocation(() => {
    if (store.hasCurrentLocation()) {
      $toast.success(t('success.geolocation.accepted'));
      store.setLoadResetAll();
    } else {
      $toast.error(t('error.geolocation.denied'));
    }
  });
}

const handleOpenLocation = (supplier) => {
  if (supplier?.latitude && supplier?.longitude) {
    store.setMapLocation(supplier);
    $eventBus.emit('modal:open', 'LOCATION_MAP_MODAL');
  }
}

</script>
