import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";

export default function (value) {
  const localePath = useLocalePath();

  if (_.isUndefined(value) || (_.isObject(value) && _.isUndefined(_.get(value, 'path'))) || value === undefined || value === 'undefined') {
    sentryCaptureEvent({
      message: 'translate-path.value',
      level: 'error',
      extra: { value }
    });

    return ROUTES.HOME;
  }

  const result = localePath(value);

  if (_.isUndefined(result) || (_.isObject(result) && _.isUndefined(_.get(result, 'path'))) || result === undefined || result === 'undefined') {
    sentryCaptureEvent({
      message: 'translate-path.result',
      level: 'error',
      extra: { value, result }
    });

    return ROUTES.HOME;
  }

  return result;
}
