<template>
  <div :class="[ 'w-full pt-4 border-t border-primary-neutral', isOpen ? 'mb-4' : 'mb-2' ]">
    <button
      :class="[ 'flex items-center w-full text-start outline-none focus:outline-none focus:ring-0 focus:text-primary-dark-accent hover:no-underline text-primary-dark text-base font-bold pb-2' ]"
    >
      <div class="flex justify-between items-center w-full cursor-pointer" @click="toggleCollapse">
        <label :htmlFor="`parameter_${parameter.idParameter}`">
          {{ parameter.name }}
        </label>
        <div class="ms-auto text-primary-dark">
          <MinusIcon v-if="isOpen" class="w-6 h-6" />
          <PlusIcon v-else class="w-6 h-6" />
        </div>
      </div>
    </button>
    <div v-if="isOpen" v-for="item in parameter.values" :key="item.id" class="truncate mt-2">
      <Checkbox
        v-model="item.value"
        :name="`parameter_${parameter.idParameter}_value_${item.id}`"
        :label="item.name"
        variant="outline"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import Checkbox from "@components/ui/checkbox";
import PlusIcon from "@components/icons/plus-icon";
import MinusIcon from "@components/icons/minus-icon";

const props = defineProps({
  parameter: {
    type: Object,
    required: true
  }
});

const route = useRoute();
const queryKey = `p[${props.parameter.idParameter}]`;

const isOpen = ref(_.includes(_.keys(route.query), `p[${props.parameter.idParameter}]`));

const toggleCollapse = () => {
  isOpen.value = !isOpen.value;
}

watch(() => props.parameter, (parameter) => {
  const checked = _.chain(parameter.values).filter((item) => item.value).map((item) => item.id).join(',').value();
  useSearchParam(queryKey, checked);
}, { deep: true });

</script>
