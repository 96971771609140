import _ from 'lodash-es';
import { siteSettings } from "@settings/site.settings";
import { useEnumStore } from '@stores/enum';
import { useCustomerStore } from '@stores/customer';

type CurrencyProps = {
  id?: number,
  code: string,
  name: string,
  sign: string,
  signPosition: string,
  decimals: number,
  decimalsSeparator: string,
  thousandsSeparator: string
};

export function formatPrice({
  amount,
  currency,
  locale,
}: {
  amount: number;
  currency: CurrencyProps;
  locale: string;
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: currency?.decimals,
    maximumFractionDigits: currency?.decimals
  });

  const value = formatCurrency.format(amount).replace(/([.,])00(\s*[^\d\s]*)$/, '$2');
  const formatted = currency?.signPosition === 'after' 
    ? `${value} ${currency?.sign || ''}` 
    : `${currency?.sign || ''}${value}`;
  
  return _.trim(formatted);
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currency,
  locale,
}: {
  baseAmount: number;
  amount: number;
  currency: CurrencyProps;
  locale: string;
}) {
  const hasDiscount = baseAmount < amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: "percent" });
  const discount = hasDiscount
    ? formatDiscount.format((amount - baseAmount) / amount)
    : null;

  const price = formatPrice({ amount, currency, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currency, locale })
    : null;

  return { price, basePrice, discount };
}

type PriceProps = {
  amount: number;
  baseAmount?: number;
  currencyCode?: string;
};

export default function usePrice(data?: PriceProps | null) {
  const { amount, baseAmount, currencyCode } = data ?? {};
  const enums = useEnumStore();
  const customer = useCustomerStore();
  const currency = _.find(enums.currencies, { code: currencyCode ?? siteSettings.currency.code }) || siteSettings.currency;
  const locale = _.get(customer, 'language.locale', siteSettings.language.code).replace('_', '-');
  const value = typeof amount !== "number" || !currency?.code 
    ? "" 
    : (baseAmount
        ? formatVariantPrice({ amount, baseAmount, currency, locale })
        : formatPrice({ amount, currency, locale })
    );

  return typeof value === "string"
    ? { price: value, basePrice: null, discount: null }
    : value;
}
