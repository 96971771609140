<template>
    <div :class="{ 'max-w-6xl h-auto hidden md:block bg-accent-neutral fixed top-0 start-1/2 transform -translate-x-1/2 z-50 px-8 py-6 shadow-350 transition-all duration-300 -ml-2': true, 'invisible opacity-0 -translate-y-1/2': !ui.displayModalStickyBar, 'visible opacity-100 translate-y-0': ui.displayModalStickyBar }" style="width: calc(100% - 40px);">
        <div class="flex items-center">
            <div class="border border-border-200 border-opacity-70 rounded relative flex items-center justify-center overflow-hidden flex-shrink-0 w-28 h-28 text-light">
                <Image
                  :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage"
                  :alt="variant.name"
                  :width="112"
                  :height="112"
                  object-fit="cover"
                  class-name="cursor-zoom-in bg-radial-gradient"
                />
            </div>
            <div class="px-8 flex flex-col justify-center me-auto overflow-hidden">
                <h3 class="font-semibold text-lg lg:text-xl tracking-tight text-heading truncate cursor-pointer transition-colors hover:text-accent" :title="variant.name" @click="navigate(`${ROUTES.PRODUCT}/${slugify(variant.id, variant.name)}`)">
                  {{ variant.name }}
                </h3>
            </div>
            <div class="w-full flex flex-shrink-0 max-w-max">
                <span v-if="_.get(variant, 'price', null) === null" class="me-8 flex items-center ">
                  <ins class="text-xl lg:text-2xl font-semibold text-heading no-underline whitespace-nowrap">
                    {{ $t('text-price-negotiable') }}
                  </ins>
                </span>
                <span v-else class="me-8 flex items-center ">
                  <ins class="text-xl lg:text-2xl font-semibold text-heading no-underline">
                    {{ basePrice ? basePrice : price }}
                  </ins>
                  <del v-if="discount" class="text-sm lg:text-base font-normal text-muted ms-2">
                    {{ price }}
                  </del>
                </span>

                <div class="w-full">
                    <EditProductBtn 
                        v-if="_.get(variant, 'product.supplier.id') === auth?.idSupplier" 
                        :data="variant.product" 
                        variant="big"
                    />
                    <template v-else-if="store.action !== 'HIDE_ADD_TO_CART'">
                        <AddToCart 
                          v-if="_.get(variant, 'stockCount', 0) !== 0 && _.get(variant, 'availability.isProductOrderable', true)" 
                          :data="variant" 
                          variant="simple" 
                        />
                        <div v-else class="bg-red-500 rounded text-sm text-light px-3 py-2">
                            {{ $t(variant?.product?.type === PRODUCT_TYPE.OFFER ? 'text-out-stock': 'text-not-wanted') }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>    
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE } from "@utils/constants";
import { useUIStore } from '@stores/ui';
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { siteSettings } from "@settings/site.settings";
import usePrice from "@utils/use-price";
import Image from "@components/ui/image";
import AddToCart from "@components/product/add-to-cart/add-to-cart";
import EditProductBtn from "@components/product/product-edit/edit-product-btn";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  }
})

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const auth = useAuthStore();
const store = useSelectedStore();
const localePath = useLocalePath();

const { price, basePrice, discount } = usePrice({
  amount: +props.variant?.price,
  baseAmount: +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async (path) => {
    closeModal();
    await navigateTo(translatePath(path));
}

</script>
