import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useEnumStore } from '@stores/enum';

export default async function () {
  const store = useEnumStore();

  if (store?.currencies?.length) return;

  store.setLoading(true);
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CURRENCY);

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-currencies',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    store.setCurrencies(data.value);
  }
  store.setLoading(false);
}
