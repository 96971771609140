<template>
  <div>
    <Swiper
      id="productGallery"
      :modules="[Thumbs]"
      :thumbs="{ swiper: thumbsSwiper }"
      :space-between="0"
      :slides-per-view="1"
    >
      <SwiperSlide
        v-for="(item, idx) in gallery" 
        :key="`product-gallery-${item.id}`"
        class="flex items-center justify-center"
      >
        <Image
          :src="item?.url || siteSettings.product.placeholderImage"
          :alt="`Product gallery ${item.id}`"
          :width="960"
          :height="960"
          layout="fill" 
          object-fit="cover"
          class-name="cursor-zoom-in"
          @click="() => showImage(idx)"
        />
      </SwiperSlide>
    </Swiper>

    <div class="w-full mt-5 lg:mt-8 mx-auto relative lg:pb-2">
      <Swiper
        id="productGalleryThumbs"
        @swiper="setThumbsSwiper"
        :modules="[SwiperNavigation]"
        :spaceBetween="20"
        :watchSlidesVisibility="true"
        :watchSlidesProgress="true"
        :breakpoints="galleryCarouselBreakpoints"
        :navigation="{ prevEl: '.product-gallery-prev', nextEl: '.product-gallery-next' }"
      >
        <SwiperSlide
          v-for="(item, idx) in gallery" 
          :key="`product-thumb-gallery-${item.id}`"
          class="flex items-center justify-center cursor-pointer rounded overflow-hidden border border-border-200 border-opacity-75 hover:opacity-75 !w-36 !h-36"
        >
          <Image
            :src="item?.url || siteSettings.product.placeholderImage"
            :alt="`Product thumb gallery ${item.id}`"
            :width="150"
            :height="150"
            object-fit="cover"
          />
        </SwiperSlide>
      </Swiper>
      <div class="product-gallery-prev cursor-pointer absolute top-2/4 -start-4 md:-start-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-gray-100">
        <ChevronLeft class="w-4 h-4" />
      </div>
      <div class="product-gallery-next cursor-pointer absolute top-2/4 -end-4 md:-end-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-heading transition-all duration-200 hover:bg-gray-100">
        <ChevronRight class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import GLightbox from 'glightbox-ssr';
import { Thumbs } from 'swiper/modules';
import { siteSettings } from "@settings/site.settings";
import Image from "@components/ui/image";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";

const props = defineProps({
  gallery: {
    type: Array,
    default: () => []
  }
});

const thumbsSwiper = ref(null);
const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const galleryCarouselBreakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 3,
  },
  800: {
    slidesPerView: 3,
  },
};

const lightbox = GLightbox({ touchNavigation: true, selector: null });

const mapElements = (value) => _.map(_.filter(value, image => image?.url), image => (_.assign({}, {
  href: image.url,
  type: 'image'
}, image?.description ? { description: image?.description } : {} )));

onMounted(() => {
  lightbox.setElements(mapElements(props?.gallery || []));
});

const showImage = (idx) => lightbox.open(idx);

</script>
