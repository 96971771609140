<template>
  <div class="flex flex-row flex-wrap items-stretch w-full">
    <div
      v-for="(image, idx) in item.images" 
      :key="`message-images-${image.id}`"
      class="flex-none w-24 h-24 sm:w-48 sm:h-48 mx-1 mt-2"
    >
      <img
        :src="image?.url"
        :alt="item.description"
        :width="200"
        :height="200"
        class="rounded object-cover w-24 h-24 sm:w-48 sm:h-48 cursor-zoom-in"
        loading="lazy"
        @click="() => showImage(idx)"
      />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import GLightbox from 'glightbox-ssr';

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
});

const lightbox = GLightbox({ touchNavigation: true, selector: null });

const mapElements = (value) => _.map(_.filter(value, image => image?.url), image => ({
  href: image.url,
  type: 'image'
}));

onMounted(() => {
  lightbox.setElements(mapElements(props.item.images));
});

const showImage = () => lightbox.open();

</script>
