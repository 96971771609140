import { ROUTES } from "../utils/routes";

export type GalleryType = {
  id: string | number;
  title: string;
  image: string;
};

export type BannerType = {
  heading: string;
  subheading: string;
  image: string;
  gallery?: GalleryType[];
};

export interface PageInfo {
  title: string;
  description: string;
  banner: BannerType;
}

export type PageName =
  | "aquarist";

export const sitePages: Record<PageName, PageInfo> = {
  aquarist: {
    title: "ForAquarist.com",
    description: "Získejte parťáka pro vaši akvarijní rybku",
    image: "/banner/aquarist.jpg",
    type: "website",
    main: [
      {
        heading: "heading-aquarist-main",
        subheading: "subheading-aquarist-main",
        image: "/banner/aquarist.jpg",
        link: ROUTES.ADS
      },
      {
        heading: "heading-aquarist-main-2",
        subheading: "subheading-aquarist-main-2",
        image: "/banner/aquarist2.jpg",
        link: ROUTES.ADS
      },
      {
        heading: "heading-aquarist-main-5",
        subheading: "subheading-aquarist-main-5",
        image: "/banner/aquarist5.jpg",
        link: ROUTES.NEWS
      }
    ],
    category: {
      heading: "heading-aquarist-category",
      subheading: "subheading-aquarist-category",
      image: "/banner/aquarist2.jpg",
    },
    ads: {
      heading: "heading-aquarist-ads",
      subheading: "subheading-aquarist-ads",
      image: "/banner/aquarist2.jpg",
      type: "product"
    },
    myAds: {
      heading: "heading-aquarist-my-ads",
      subheading: "subheading-aquarist-my-ads",
      image: "/banner/aquarist.jpg",
    },
    offers: {
      heading: "heading-aquarist-offers",
      subheading: "subheading-aquarist-offers",
      image: "/banner/aquarist2.jpg",
    },
    requests: {
      heading: "heading-aquarist-requests",
      subheading: "subheading-aquarist-requests",
      image: "/banner/aquarist6.jpg",
    },
    favorites: {
      heading: "heading-aquarist-favorites",
      subheading: "subheading-aquarist-favorites",
      image: "/banner/aquarist.jpg",
    },
    inspiration: {
      heading: "heading-aquarist-inspiration",
      subheading: "subheading-aquarist-inspiration",
      image: "/banner/aquarist.jpg",
      type: "article"
    },
    news: {
      heading: "heading-aquarist-news",
      subheading: "subheading-aquarist-news",
      image: "/banner/aquarist5.jpg",
      type: "article"
    },
    reference: {
      heading: "heading-aquarist-reference",
      subheading: "subheading-aquarist-reference",
      image: "/banner/aquarist5.jpg",
      type: "article"
    },
    profile: {
      type: "profile"
    },
  }
};
