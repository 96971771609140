<template>
  <div v-if="loading" class="w-full h-32 flex justify-center items-center">
    <Spinner
      :text="$t('text-loading')"
      :simple="true"
      class="w-6 h-6"
    />
  </div>
  <div v-else-if="ratings?.length" class="w-full border-t border-gray-200 p-7">
    <h3 class="text-lg font-semibold text-heading mb-7 text-center">
      {{ $t('text-rating') }}
    </h3>

    <div 
      v-for="rating in ratings"
      :key="rating.id"
      class="mb-7 last:mb-0 flex flex-col"
    >
      <ProfileHeader :data="rating" :show="{ rating: true }" class="mb-2 text-primary-dark" />
      <div class="text-sm text-body">
        {{ rating?.review }}
      </div>
    </div>

    <div v-if="hasNextPage || loadingMore" class="flex justify-center mt-8 lg:mt-12">
      <Button
          :loading="loadingMore"
          @click="handleLoadMore"
          class-name="text-sm md:text-base font-semibold h-11"
      >
          {{ $t("text-load-more") }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import Spinner from "@components/ui/loaders/spinner/spinner";
import Truncate from "@components/ui/truncate";
import ProfileHeader from "@components/profile/profile-header";
import Button from "@components/ui/button";
import { CART_TYPE } from "@utils/constants";

const props = defineProps({
    supplier: {
        type: Object,
        default: () => { }
    }
})

const { t } = useI18n();
const { $toast } = useNuxtApp();
const loading = ref(true);
const pages = ref([]);
const pagination = ref({ page: 1, pageCount: 1, itemsPerPage: 5, itemCount: 0 });
const loadingMore = ref(false);
const hasNextPage = computed(() => pagination.value.page < pagination.value.pageCount);

const ratings = computed(() => loading.value && !pages.value.length 
  ? [] 
  : _.flatMap(pages.value, (page) => _.map(page?.items, item => _.assign(item, { supplier: _.assignIn(item.supplier, { ratingScore: item.score }) })))
);

const reload = async (pending = loading) => {
  pending.value = true;
  
  const data = await supplierRatingLoad(props.supplier.id, pagination.value);
  if (data === null) {
    if (process.client) {
      $toast.error(t('error.supplier-rating.load'));
    }
  } else {
    pages.value.push(data);
    pagination.value = data.pagination;
  }
  pending.value = false;
}

const handleLoadMore = async () => {
  pagination.value.page++;
  await reload(loadingMore);
}

onMounted(async () => {
  if (!pages.value.length && loading.value) {
    await reload();
  }
});

onServerPrefetch(async () => {
  await reload();
});

</script>
