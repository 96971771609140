import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected'

export default async function (id) {
  const auth = useAuthStore();
  const store = useSelectedStore();

  if (!auth.isLoggedIn || !auth.idSupplier) {
    return;
  }
  
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_ID, {
    method: 'DELETE',
    path: { id }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'product-delete',
      level: 'error',
      extra: { error: error.value }
    });

    return error.value;
  }

  store.setLoadReset(PRODUCT_SUBTYPE.main, true);
  store.setLoadReset(PRODUCT_SUBTYPE.ads, true);
  store.setLoadReset(PRODUCT_SUBTYPE.category, true);
  store.setLoadReset(PRODUCT_SUBTYPE.offer, true);
  store.setLoadReset(PRODUCT_SUBTYPE.wanted, true);
  store.setLoadReset(PRODUCT_SUBTYPE.favorite, true);

  return true;
}
