import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { useEnumStore } from '@stores/enum';

export default async function () {
  const { $dayjs } = useNuxtApp();
  const config = useRuntimeConfig();
  const auth = useAuthStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();
  const enums = useEnumStore();
  const route = useRoute();
  const cookie = useCookie('i18n_redirected');
  const switchLocalePath = useSwitchLocalePath();

  if (route.path !== switchLocalePath(customer?.language?.code || cookie.value || config.public.defaultLanguage)) {
    const code = route.name.slice(-2);
    if (auth.isLoggedIn && auth.idCustomer) {
      $dayjs.locale(customer?.language?.code || cookie.value || config.public.defaultLanguage);
    } else {
      if (enums?.languages && enums?.languages?.length) {
        const language = _.find(enums.languages || [], { code });
        if (language && language !== customer?.language?.code) {
          await visitorUpdate(language?.id || customer?.language?.id || 1, language?.currency?.id || customer?.language?.currency?.id || 1);
        }
      }
      cookie.value = code;
      $dayjs.locale(code);
    }

    store.setLoadResetAll();
    await loadCategories(true);
    await loadParameters(true);
    await loadEshopAvailabilities(true);
    // await loadAbandonmentReasons(true);

    if (auth.isLoggedIn && auth.idCustomer) {
      await navigateTo(switchLocalePath(customer?.language?.code || cookie.value || config.public.defaultLanguage));
    } else {
      await navigateTo(switchLocalePath(code));
    }
  }
}
