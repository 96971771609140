<template>
    <div
      class="flex items-center py-2 px-4 sm:px-5 text-sm"
    >
      <div class="w-10 sm:w-16 h-10 sm:h-16 flex items-center justify-center overflow-hidden ml-1 sm:ml-0 mr-3 flex-shrink-0 relative rounded cursor-pointer text-light bg-radial-gradient">
        <Image
          :src="data.variant?.image?.url || _.get(data.variant, 'images.0.url', null) || siteSettings.product.placeholderImage"
          :alt="data.variant?.name"
          layout="fill"
          object-fit="cover" 
        />
      </div>
      <div class="w-full cursor-pointer">
        <h3 class="font-bold text-heading">{{ data.variant?.name }}</h3>
        <p v-if="_.get(data, 'price', null) === null" class="my-2.5 font-semibold text-accent">
          {{ $t('text-price-negotiable') }}
        </p>
        <p v-else class="my-2.5 font-semibold text-accent">
          {{ price }}<span class="text-body">/{{ data.unit || $t('text-unit-pc') }}</span>
        </p>
      </div>
      <div class="flex flex-col space-y-2 items-center justify-center">
        <div class="self-end flex space-x-2 items-center justify-end">
          <div class="ms-auto font-bold text-heading cursor-pointer">{{ _.get(data, 'price', null) === null ? $t('text-negotiable') : itemPrice }}</div>
          <button
            class="flex items-center justify-center rounded-full text-red-500 transition-all duration-200 focus:outline-none hover:bg-gray-100 focus:bg-gray-100 hover:text-red-600 focus:text-red-600"
            :title="$t('text-delete')"
            @click="clearItemFromCart"
          >
            <span class="sr-only">{{ $t("text-close") }}</span>
            <CloseIcon class="w-3 h-3" />
          </button>
        </div>
        <div class="flex-shrink-0 mr-5">
          <Counter
            v-model="quantity"
            @decrement="handleRemoveClick"
            @increment="handleIncrement"
            :on-change="handleOnChange"
            variant="pill"
            :disabled="outOfStock"
          />
        </div>
      </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { computed } from "vue";
import { formatString } from "@utils/format-string";
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';
import { siteSettings } from "@settings/site.settings";
import usePrice from "@utils/use-price";
import Image from "@components/ui/image";
import Counter from "@components/ui/counter";
import CloseIcon from "@components/icons/close-icon";

const props = defineProps({
  data: {
    type: Object,
    default: () => { }
  },
  currency: {
    type: Object,
    default: () => { }
  }
});

const cart = useCartStore();
const store = useSelectedStore();
const { $eventBus } = useNuxtApp();
const quantity = ref(+props.data.quantity);

watch(() => props.data.quantity, (value) => quantity.value = value);

const outOfStock = !cart.isInStock(props.data?.variant?.id);

const handleRemoveClick = (e) => {
  e.stopPropagation();
  cartRemoveItemOrQuantity(props.data?.variant?.id, 1);
};

const handleIncrement = async (e) => {
  e.stopPropagation();
  await cartAddItemToCart(props.data?.variant, 1);
};

const handleOnChange = async (e) => {
  if (+e.target.value) {
    await cartUpdateItemInCart(props.data?.variant?.id, +e.target.value);
  } else if (/^\d+?$/.test(e.target.value)) {
    clearItemFromCart(e);
  } else {
    quantity.value = props.data.quantity;
  }
};

const clearItemFromCart = (e) => {
  e.stopPropagation();
  store.setVariant(props.data?.variant);
  $eventBus.emit('sidebar:close');
  $eventBus.emit('modal:close');
  $eventBus.emit('modal:open', 'CART_REMOVED_VIEW');
};

$eventBus.on('cart:item:refresh', () => {
  quantity.value = props.data.quantity;
});

onUnmounted(() => {
  $eventBus.off('cart:item:refresh');
});

const price = computed(() => usePrice({
  amount: props.data?.price || 0,
  currencyCode: props.currency?.code
}).price);

const itemPrice = computed(() => usePrice({
  amount: props.data?.sum || 0,
  currencyCode: props.currency?.code
}).price);

</script>
