<template>
    <Button :variant="variant" size="big" @click="handleAdd">
        {{ $t("text-add-ad") }}
    </Button>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import Button from "@components/ui/button";

const props = defineProps({
    variant: {
        type: String,
        default: 'accent'
    }
});

const { $eventBus } = useNuxtApp();

const auth = useAuthStore();
const store = useSelectedStore();
const localePath = useLocalePath();

const handleAdd = async () => { 
    $eventBus.emit('sidebar:close');
    if (auth.isLoggedIn) {
        await navigateTo(translatePath(ROUTES.AD_ADD));
    } else {
        store.setAction('ADD_NEW_AD');
        $eventBus.emit('modal:open', 'LOGIN_VIEW');
    }
};

</script>
