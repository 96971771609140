<template>
  <Alert
    v-if="alertMsg && customer.status === 'waiting'"
    :variant="alertType"
    :message="alertMsg"
    class="w-full"
    :centered="centered"
    :closeable="true"
    :on-close="() => alertMsg = ''"
  >
    &nbsp;<button
        v-if="!loading && !submitted"
        @click="handleSubmit"
        class="underline font-semibold transition-colors duration-200 focus:outline-none hover:no-underline focus:no-underline"
      >
        {{ $t('text-send-activate-account') }}
      </button>
  </Alert>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { useCustomerStore } from '@stores/customer';
import Alert from "@components/ui/alert";
import Button from "@components/ui/button";

const props = defineProps({
  centered: {
      type: Boolean,
      default: false
  }
});

const customer = useCustomerStore();
const alertMsg = ref('text-account-not-activated');
const alertType = ref('warning');
const loading = ref(false);
const submitted = ref(false);

const activateAcount = async (callbacks) => {
  loading.value = true;
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_ACCOUNT_ACTIVATION, {
    method: 'POST'
  });
  if (status.value === 'error') {
    callbacks.onError(error.value);
  } else {
    await callbacks.onSuccess(data.value);
  }
  loading.value = false;
  submitted.value = true;
};

const handleSubmit = async () => {
  await activateAcount(
    {
      onSuccess: async (data) => {
        alertType.value = 'success';
        alertMsg.value = 'text-activate-account-sent';
      },
      onError: (error) => {
        alertType.value = 'error';
        if (error.statusCode === 400) {
          alertMsg.value = 'activate-account.error.already-set';
        } else if (error.statusCode === 404) {
          alertMsg.value = 'activate-account.error.not-found';
        } else {
          alertMsg.value = 'activate-account.error.unknown';
        }
        sentryCaptureEvent({
          message: 'activation-alert.handleSubmit',
          level: 'error',
          extra: { error }
        });
      }
    }
  );
}

</script>
