<template>
  <button
    v-if="variant === 'disabled'"
    @click="onClick"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-primary-light rounded-full transition-colors bg-accent-light"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'outline'"
    @click="onClick"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-primary-dark rounded-full transition-colors bg-light border border-primary-dark hover:border-primary-dark-hover hover:text-light hover:bg-primary-dark-hover"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'simple'"
    @click="onClick"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark-hover hover:bg-primary-dark-hover focus:bg-primary-dark-hover"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'offer'"
    @click="onClick"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark hover:bg-accent-dark-hover"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'wanted'"
    @click="onClick"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark hover:bg-accent-light-hover"
  >
    <slot />
  </button>
</template>

<script setup>
import { useCartStore } from '@stores/cart';

const props = defineProps({
  data: {
    type: Object,
    required: true 
  },
  variant: {
    type: String,
    required: false,
    default: () => '' 
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false 
  },
  onClick: {
    type: Function,
    default: () => {}
  }
});

const cart = useCartStore();

</script>
