<template>
  <div class="w-full flex flex-col justify-center mb-5">
    <label
      htmlFor="phone"
      class="text-body-dark font-semibold text-sm leading-tight mb-1.5"
    >
      <div class="truncate">{{ $t('text-phone') }}<span class="text-red-500">&nbsp;*</span></div>
    </label>
    <div class="w-full flex justify-stretch">
      <Combobox
        v-model="modelValue.idCalling"
        :options="enums.countries"
        :display-value="callingValue"
        :show-selected-check="false"
        name="country"
        :error="errors?.phone?.message ? ' ' : ''"
        variant="outline"
        class-name="w-36"
        class-border="border-0 border-t border-l border-b rounded-l"
      >
        <template v-slot:option="slotProps">
          {{ slotProps.option.code }} +{{ slotProps.option.callingCode || '' }}
        </template>
      </Combobox>
      <Input
        v-model="modelValue.phone"
        name="phone"
        :error="errors?.phone?.message ? $t(errors.phone.message) : ''"
        :required="true"
        variant="outline"
        class-name="w-full pt-1.5"
        class-border="border-0 border-t border-r border-b rounded-r !pl-0"
      />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';
import Input from "@components/ui/input";
import Combobox from "@components/ui/combobox";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    heading: {
        type: String,
        default: ''
    },
    errors: {
        type: Object,
        default: () => {}
    }
});

const enums = useEnumStore();

const callingValue = (option) => `${option.code} +${option.callingCode}`;

onMounted(() => {
  props.modelValue.idCalling = _.get(props.modelValue, 'calling.id', props.modelValue?.idCalling || null);
});

</script>
