import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';

export default async function (idOrder, statusValue) {
  const auth = useAuthStore();

  if (!auth.isLoggedIn || !auth.idSupplier) {
    return;
  }

  const { $eventBus } = useNuxtApp();

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_ORDER_ID_STATUS, {
    method: 'PUT',
    path: { idOrder },
    params: { status: statusValue }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'order-update-status',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  } else {
    $eventBus.emit('order:update', { 
      id: idOrder, 
      status: statusValue
    });
  }

  return data.value;
}
