import { default as _91hash_93eswwpaB4LwMeta } from "/home/aquarist/www/prod/pages/activate-account/[hash].vue?macro=true";
import { default as appB5miAHyDpQMeta } from "/home/aquarist/www/prod/pages/app.vue?macro=true";
import { default as _91slug_93dmpDMR9xBHMeta } from "/home/aquarist/www/prod/pages/category/[slug].vue?macro=true";
import { default as indexd5Yw5YZSpuMeta } from "/home/aquarist/www/prod/pages/category/index.vue?macro=true";
import { default as change_45passwordk5wVzUGMfzMeta } from "/home/aquarist/www/prod/pages/change-password.vue?macro=true";
import { default as _91cart_id_93fKniYhGJsqMeta } from "/home/aquarist/www/prod/pages/checkout/[cart_id].vue?macro=true";
import { default as index6N2Kyglef0Meta } from "/home/aquarist/www/prod/pages/checkout/index.vue?macro=true";
import { default as _91ad_id_93enIn0eRKbIMeta } from "/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue?macro=true";
import { default as newqF3K9B1Nn3Meta } from "/home/aquarist/www/prod/pages/classified-ad/new.vue?macro=true";
import { default as indexJkgZq6o2JnMeta } from "/home/aquarist/www/prod/pages/classified-ads/index.vue?macro=true";
import { default as myp1sW3nF7ONMeta } from "/home/aquarist/www/prod/pages/classified-ads/my.vue?macro=true";
import { default as contactu93tYJ4mkQMeta } from "/home/aquarist/www/prod/pages/contact.vue?macro=true";
import { default as _91hash_935Q43ibMduyMeta } from "/home/aquarist/www/prod/pages/delete-account/[hash].vue?macro=true";
import { default as favoritesuYUUm280XNMeta } from "/home/aquarist/www/prod/pages/favorites.vue?macro=true";
import { default as forgot_45password1nAsOd7bZXMeta } from "/home/aquarist/www/prod/pages/forgot-password.vue?macro=true";
import { default as helpM0fuJk0hfyMeta } from "/home/aquarist/www/prod/pages/help.vue?macro=true";
import { default as indexjNGEdGYgnOMeta } from "/home/aquarist/www/prod/pages/index.vue?macro=true";
import { default as _91slug_93UeT9KRdaY4Meta } from "/home/aquarist/www/prod/pages/inspiration/[slug].vue?macro=true";
import { default as addTNhi6bfHzgMeta } from "/home/aquarist/www/prod/pages/inspiration/add.vue?macro=true";
import { default as _91content_id_93NQOmhqY6mxMeta } from "/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue?macro=true";
import { default as indexxZl64Y2VyUMeta } from "/home/aquarist/www/prod/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93MJSAER1vFZMeta } from "/home/aquarist/www/prod/pages/inspiration/type/[slug].vue?macro=true";
import { default as loginO4PQPZrUdGMeta } from "/home/aquarist/www/prod/pages/login.vue?macro=true";
import { default as logoutixCudY8mQ5Meta } from "/home/aquarist/www/prod/pages/logout.vue?macro=true";
import { default as _91slug_93r0DdiXNiXGMeta } from "/home/aquarist/www/prod/pages/news/[slug].vue?macro=true";
import { default as _91slug_93tJPGhIXm5OMeta } from "/home/aquarist/www/prod/pages/news/about/[slug].vue?macro=true";
import { default as indexaps9REVB6uMeta } from "/home/aquarist/www/prod/pages/news/index.vue?macro=true";
import { default as _91slug_93ETNZaqS9XkMeta } from "/home/aquarist/www/prod/pages/offers/[slug].vue?macro=true";
import { default as indexI7GXPuEPptMeta } from "/home/aquarist/www/prod/pages/offers/index.vue?macro=true";
import { default as _91cart_id_93Rnr0Pghxq8Meta } from "/home/aquarist/www/prod/pages/orders/[cart_id].vue?macro=true";
import { default as indexJRZ20ZQj6EMeta } from "/home/aquarist/www/prod/pages/orders/index.vue?macro=true";
import { default as privacy8vIdl4MH0DMeta } from "/home/aquarist/www/prod/pages/privacy.vue?macro=true";
import { default as _91slug_932FLyuAd54aMeta } from "/home/aquarist/www/prod/pages/product/[slug].vue?macro=true";
import { default as profileMhUqW6cbF7Meta } from "/home/aquarist/www/prod/pages/profile.vue?macro=true";
import { default as _91slug_934K2BktOLBzMeta } from "/home/aquarist/www/prod/pages/reference/[slug].vue?macro=true";
import { default as registerd0HqpGimOyMeta } from "/home/aquarist/www/prod/pages/register.vue?macro=true";
import { default as _91slug_934MUTTUtmLDMeta } from "/home/aquarist/www/prod/pages/requests/[slug].vue?macro=true";
import { default as indexlKgoQvJ2htMeta } from "/home/aquarist/www/prod/pages/requests/index.vue?macro=true";
import { default as _91hash_93yeuyM22kF1Meta } from "/home/aquarist/www/prod/pages/reset-password/[hash].vue?macro=true";
import { default as rulesn3XwR57bcDMeta } from "/home/aquarist/www/prod/pages/rules.vue?macro=true";
import { default as _91cart_id_93N8O540GIJyMeta } from "/home/aquarist/www/prod/pages/sales/[cart_id].vue?macro=true";
import { default as indexs4hR3DrB06Meta } from "/home/aquarist/www/prod/pages/sales/index.vue?macro=true";
import { default as notificationsIyjKSVvqbXMeta } from "/home/aquarist/www/prod/pages/settings/notifications.vue?macro=true";
import { default as _91supplier_id_93xbSn9EYT8wMeta } from "/home/aquarist/www/prod/pages/supplier/[supplier_id].vue?macro=true";
import { default as terms7cdYR5pWxkMeta } from "/home/aquarist/www/prod/pages/terms.vue?macro=true";
import { default as _91cart_id_93yUpHDoC1nvMeta } from "/home/aquarist/www/prod/pages/unfinished/[cart_id].vue?macro=true";
import { default as indexAbxjC6ntdJMeta } from "/home/aquarist/www/prod/pages/unfinished/index.vue?macro=true";
import { default as why_45with_45usspdMZjGNdNMeta } from "/home/aquarist/www/prod/pages/why-with-us.vue?macro=true";
export default [
  {
    name: _91hash_93eswwpaB4LwMeta?.name ?? "activate-account-hash___cs",
    path: _91hash_93eswwpaB4LwMeta?.path ?? "/aktivace-uctu/:hash",
    meta: _91hash_93eswwpaB4LwMeta || {},
    alias: _91hash_93eswwpaB4LwMeta?.alias || [],
    redirect: _91hash_93eswwpaB4LwMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93eswwpaB4LwMeta?.name ?? "activate-account-hash___en",
    path: _91hash_93eswwpaB4LwMeta?.path ?? "/en/activate-account/:hash",
    meta: _91hash_93eswwpaB4LwMeta || {},
    alias: _91hash_93eswwpaB4LwMeta?.alias || [],
    redirect: _91hash_93eswwpaB4LwMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93eswwpaB4LwMeta?.name ?? "activate-account-hash___pl",
    path: _91hash_93eswwpaB4LwMeta?.path ?? "/pl/aktywacja-konta/:hash",
    meta: _91hash_93eswwpaB4LwMeta || {},
    alias: _91hash_93eswwpaB4LwMeta?.alias || [],
    redirect: _91hash_93eswwpaB4LwMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93eswwpaB4LwMeta?.name ?? "activate-account-hash___sk",
    path: _91hash_93eswwpaB4LwMeta?.path ?? "/sk/aktivacia-uctu/:hash",
    meta: _91hash_93eswwpaB4LwMeta || {},
    alias: _91hash_93eswwpaB4LwMeta?.alias || [],
    redirect: _91hash_93eswwpaB4LwMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93eswwpaB4LwMeta?.name ?? "activate-account-hash___de",
    path: _91hash_93eswwpaB4LwMeta?.path ?? "/de/kontoaktivierung/:hash",
    meta: _91hash_93eswwpaB4LwMeta || {},
    alias: _91hash_93eswwpaB4LwMeta?.alias || [],
    redirect: _91hash_93eswwpaB4LwMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: appB5miAHyDpQMeta?.name ?? "app___cs",
    path: appB5miAHyDpQMeta?.path ?? "/aplikace",
    meta: appB5miAHyDpQMeta || {},
    alias: appB5miAHyDpQMeta?.alias || [],
    redirect: appB5miAHyDpQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/app.vue").then(m => m.default || m)
  },
  {
    name: appB5miAHyDpQMeta?.name ?? "app___en",
    path: appB5miAHyDpQMeta?.path ?? "/en/app",
    meta: appB5miAHyDpQMeta || {},
    alias: appB5miAHyDpQMeta?.alias || [],
    redirect: appB5miAHyDpQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/app.vue").then(m => m.default || m)
  },
  {
    name: appB5miAHyDpQMeta?.name ?? "app___pl",
    path: appB5miAHyDpQMeta?.path ?? "/pl/aplikacja",
    meta: appB5miAHyDpQMeta || {},
    alias: appB5miAHyDpQMeta?.alias || [],
    redirect: appB5miAHyDpQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/app.vue").then(m => m.default || m)
  },
  {
    name: appB5miAHyDpQMeta?.name ?? "app___sk",
    path: appB5miAHyDpQMeta?.path ?? "/sk/aplikacia",
    meta: appB5miAHyDpQMeta || {},
    alias: appB5miAHyDpQMeta?.alias || [],
    redirect: appB5miAHyDpQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/app.vue").then(m => m.default || m)
  },
  {
    name: appB5miAHyDpQMeta?.name ?? "app___de",
    path: appB5miAHyDpQMeta?.path ?? "/de/app",
    meta: appB5miAHyDpQMeta || {},
    alias: appB5miAHyDpQMeta?.alias || [],
    redirect: appB5miAHyDpQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dmpDMR9xBHMeta?.name ?? "category-slug___cs",
    path: _91slug_93dmpDMR9xBHMeta?.path ?? "/kategorie/:slug",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    alias: _91slug_93dmpDMR9xBHMeta?.alias || [],
    redirect: _91slug_93dmpDMR9xBHMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dmpDMR9xBHMeta?.name ?? "category-slug___en",
    path: _91slug_93dmpDMR9xBHMeta?.path ?? "/en/category/:slug",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    alias: _91slug_93dmpDMR9xBHMeta?.alias || [],
    redirect: _91slug_93dmpDMR9xBHMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dmpDMR9xBHMeta?.name ?? "category-slug___pl",
    path: _91slug_93dmpDMR9xBHMeta?.path ?? "/pl/kategoria/:slug",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    alias: _91slug_93dmpDMR9xBHMeta?.alias || [],
    redirect: _91slug_93dmpDMR9xBHMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dmpDMR9xBHMeta?.name ?? "category-slug___sk",
    path: _91slug_93dmpDMR9xBHMeta?.path ?? "/sk/kategoria/:slug",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    alias: _91slug_93dmpDMR9xBHMeta?.alias || [],
    redirect: _91slug_93dmpDMR9xBHMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dmpDMR9xBHMeta?.name ?? "category-slug___de",
    path: _91slug_93dmpDMR9xBHMeta?.path ?? "/de/kategorie/:slug",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    alias: _91slug_93dmpDMR9xBHMeta?.alias || [],
    redirect: _91slug_93dmpDMR9xBHMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexd5Yw5YZSpuMeta?.name ?? "category___cs",
    path: indexd5Yw5YZSpuMeta?.path ?? "/kategorie",
    meta: indexd5Yw5YZSpuMeta || {},
    alias: indexd5Yw5YZSpuMeta?.alias || [],
    redirect: indexd5Yw5YZSpuMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexd5Yw5YZSpuMeta?.name ?? "category___en",
    path: indexd5Yw5YZSpuMeta?.path ?? "/en/category",
    meta: indexd5Yw5YZSpuMeta || {},
    alias: indexd5Yw5YZSpuMeta?.alias || [],
    redirect: indexd5Yw5YZSpuMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexd5Yw5YZSpuMeta?.name ?? "category___pl",
    path: indexd5Yw5YZSpuMeta?.path ?? "/pl/kategoria",
    meta: indexd5Yw5YZSpuMeta || {},
    alias: indexd5Yw5YZSpuMeta?.alias || [],
    redirect: indexd5Yw5YZSpuMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexd5Yw5YZSpuMeta?.name ?? "category___sk",
    path: indexd5Yw5YZSpuMeta?.path ?? "/sk/kategoria",
    meta: indexd5Yw5YZSpuMeta || {},
    alias: indexd5Yw5YZSpuMeta?.alias || [],
    redirect: indexd5Yw5YZSpuMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexd5Yw5YZSpuMeta?.name ?? "category___de",
    path: indexd5Yw5YZSpuMeta?.path ?? "/de/kategorie",
    meta: indexd5Yw5YZSpuMeta || {},
    alias: indexd5Yw5YZSpuMeta?.alias || [],
    redirect: indexd5Yw5YZSpuMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk5wVzUGMfzMeta?.name ?? "change-password___cs",
    path: change_45passwordk5wVzUGMfzMeta?.path ?? "/zmena-hesla",
    meta: change_45passwordk5wVzUGMfzMeta || {},
    alias: change_45passwordk5wVzUGMfzMeta?.alias || [],
    redirect: change_45passwordk5wVzUGMfzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk5wVzUGMfzMeta?.name ?? "change-password___en",
    path: change_45passwordk5wVzUGMfzMeta?.path ?? "/en/change-password",
    meta: change_45passwordk5wVzUGMfzMeta || {},
    alias: change_45passwordk5wVzUGMfzMeta?.alias || [],
    redirect: change_45passwordk5wVzUGMfzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk5wVzUGMfzMeta?.name ?? "change-password___pl",
    path: change_45passwordk5wVzUGMfzMeta?.path ?? "/pl/zmiana-hasla",
    meta: change_45passwordk5wVzUGMfzMeta || {},
    alias: change_45passwordk5wVzUGMfzMeta?.alias || [],
    redirect: change_45passwordk5wVzUGMfzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk5wVzUGMfzMeta?.name ?? "change-password___sk",
    path: change_45passwordk5wVzUGMfzMeta?.path ?? "/sk/zmena-hesla",
    meta: change_45passwordk5wVzUGMfzMeta || {},
    alias: change_45passwordk5wVzUGMfzMeta?.alias || [],
    redirect: change_45passwordk5wVzUGMfzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk5wVzUGMfzMeta?.name ?? "change-password___de",
    path: change_45passwordk5wVzUGMfzMeta?.path ?? "/de/passwort-andern",
    meta: change_45passwordk5wVzUGMfzMeta || {},
    alias: change_45passwordk5wVzUGMfzMeta?.alias || [],
    redirect: change_45passwordk5wVzUGMfzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93fKniYhGJsqMeta?.name ?? "checkout-cart_id___cs",
    path: _91cart_id_93fKniYhGJsqMeta?.path ?? "/kosik/:cart_id",
    meta: _91cart_id_93fKniYhGJsqMeta || {},
    alias: _91cart_id_93fKniYhGJsqMeta?.alias || [],
    redirect: _91cart_id_93fKniYhGJsqMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93fKniYhGJsqMeta?.name ?? "checkout-cart_id___en",
    path: _91cart_id_93fKniYhGJsqMeta?.path ?? "/en/checkout/:cart_id",
    meta: _91cart_id_93fKniYhGJsqMeta || {},
    alias: _91cart_id_93fKniYhGJsqMeta?.alias || [],
    redirect: _91cart_id_93fKniYhGJsqMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93fKniYhGJsqMeta?.name ?? "checkout-cart_id___pl",
    path: _91cart_id_93fKniYhGJsqMeta?.path ?? "/pl/kosz/:cart_id",
    meta: _91cart_id_93fKniYhGJsqMeta || {},
    alias: _91cart_id_93fKniYhGJsqMeta?.alias || [],
    redirect: _91cart_id_93fKniYhGJsqMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93fKniYhGJsqMeta?.name ?? "checkout-cart_id___sk",
    path: _91cart_id_93fKniYhGJsqMeta?.path ?? "/sk/kosik/:cart_id",
    meta: _91cart_id_93fKniYhGJsqMeta || {},
    alias: _91cart_id_93fKniYhGJsqMeta?.alias || [],
    redirect: _91cart_id_93fKniYhGJsqMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93fKniYhGJsqMeta?.name ?? "checkout-cart_id___de",
    path: _91cart_id_93fKniYhGJsqMeta?.path ?? "/de/korb/:cart_id",
    meta: _91cart_id_93fKniYhGJsqMeta || {},
    alias: _91cart_id_93fKniYhGJsqMeta?.alias || [],
    redirect: _91cart_id_93fKniYhGJsqMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: index6N2Kyglef0Meta?.name ?? "checkout___cs",
    path: index6N2Kyglef0Meta?.path ?? "/kosik",
    meta: index6N2Kyglef0Meta || {},
    alias: index6N2Kyglef0Meta?.alias || [],
    redirect: index6N2Kyglef0Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index6N2Kyglef0Meta?.name ?? "checkout___en",
    path: index6N2Kyglef0Meta?.path ?? "/en/checkout",
    meta: index6N2Kyglef0Meta || {},
    alias: index6N2Kyglef0Meta?.alias || [],
    redirect: index6N2Kyglef0Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index6N2Kyglef0Meta?.name ?? "checkout___pl",
    path: index6N2Kyglef0Meta?.path ?? "/pl/kosz",
    meta: index6N2Kyglef0Meta || {},
    alias: index6N2Kyglef0Meta?.alias || [],
    redirect: index6N2Kyglef0Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index6N2Kyglef0Meta?.name ?? "checkout___sk",
    path: index6N2Kyglef0Meta?.path ?? "/sk/kosik",
    meta: index6N2Kyglef0Meta || {},
    alias: index6N2Kyglef0Meta?.alias || [],
    redirect: index6N2Kyglef0Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index6N2Kyglef0Meta?.name ?? "checkout___de",
    path: index6N2Kyglef0Meta?.path ?? "/de/korb",
    meta: index6N2Kyglef0Meta || {},
    alias: index6N2Kyglef0Meta?.alias || [],
    redirect: index6N2Kyglef0Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91ad_id_93enIn0eRKbIMeta?.name ?? "classified-ad-edit-ad_id___cs",
    path: _91ad_id_93enIn0eRKbIMeta?.path ?? "/inzerat/editace/:ad_id",
    meta: _91ad_id_93enIn0eRKbIMeta || {},
    alias: _91ad_id_93enIn0eRKbIMeta?.alias || [],
    redirect: _91ad_id_93enIn0eRKbIMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue").then(m => m.default || m)
  },
  {
    name: _91ad_id_93enIn0eRKbIMeta?.name ?? "classified-ad-edit-ad_id___en",
    path: _91ad_id_93enIn0eRKbIMeta?.path ?? "/en/classified-ad/edit/:ad_id",
    meta: _91ad_id_93enIn0eRKbIMeta || {},
    alias: _91ad_id_93enIn0eRKbIMeta?.alias || [],
    redirect: _91ad_id_93enIn0eRKbIMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue").then(m => m.default || m)
  },
  {
    name: _91ad_id_93enIn0eRKbIMeta?.name ?? "classified-ad-edit-ad_id___pl",
    path: _91ad_id_93enIn0eRKbIMeta?.path ?? "/pl/ogloszenie/redagowanie/:ad_id",
    meta: _91ad_id_93enIn0eRKbIMeta || {},
    alias: _91ad_id_93enIn0eRKbIMeta?.alias || [],
    redirect: _91ad_id_93enIn0eRKbIMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue").then(m => m.default || m)
  },
  {
    name: _91ad_id_93enIn0eRKbIMeta?.name ?? "classified-ad-edit-ad_id___sk",
    path: _91ad_id_93enIn0eRKbIMeta?.path ?? "/sk/inzerat/editacia/:ad_id",
    meta: _91ad_id_93enIn0eRKbIMeta || {},
    alias: _91ad_id_93enIn0eRKbIMeta?.alias || [],
    redirect: _91ad_id_93enIn0eRKbIMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue").then(m => m.default || m)
  },
  {
    name: _91ad_id_93enIn0eRKbIMeta?.name ?? "classified-ad-edit-ad_id___de",
    path: _91ad_id_93enIn0eRKbIMeta?.path ?? "/de/anzeige/editierung/:ad_id",
    meta: _91ad_id_93enIn0eRKbIMeta || {},
    alias: _91ad_id_93enIn0eRKbIMeta?.alias || [],
    redirect: _91ad_id_93enIn0eRKbIMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue").then(m => m.default || m)
  },
  {
    name: newqF3K9B1Nn3Meta?.name ?? "classified-ad-new___cs",
    path: newqF3K9B1Nn3Meta?.path ?? "/inzerat/novy",
    meta: newqF3K9B1Nn3Meta || {},
    alias: newqF3K9B1Nn3Meta?.alias || [],
    redirect: newqF3K9B1Nn3Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue").then(m => m.default || m)
  },
  {
    name: newqF3K9B1Nn3Meta?.name ?? "classified-ad-new___en",
    path: newqF3K9B1Nn3Meta?.path ?? "/en/classified-ad/new",
    meta: newqF3K9B1Nn3Meta || {},
    alias: newqF3K9B1Nn3Meta?.alias || [],
    redirect: newqF3K9B1Nn3Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue").then(m => m.default || m)
  },
  {
    name: newqF3K9B1Nn3Meta?.name ?? "classified-ad-new___pl",
    path: newqF3K9B1Nn3Meta?.path ?? "/pl/ogloszenie/nowe",
    meta: newqF3K9B1Nn3Meta || {},
    alias: newqF3K9B1Nn3Meta?.alias || [],
    redirect: newqF3K9B1Nn3Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue").then(m => m.default || m)
  },
  {
    name: newqF3K9B1Nn3Meta?.name ?? "classified-ad-new___sk",
    path: newqF3K9B1Nn3Meta?.path ?? "/sk/inzerat/novy",
    meta: newqF3K9B1Nn3Meta || {},
    alias: newqF3K9B1Nn3Meta?.alias || [],
    redirect: newqF3K9B1Nn3Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue").then(m => m.default || m)
  },
  {
    name: newqF3K9B1Nn3Meta?.name ?? "classified-ad-new___de",
    path: newqF3K9B1Nn3Meta?.path ?? "/de/anzeige/neue",
    meta: newqF3K9B1Nn3Meta || {},
    alias: newqF3K9B1Nn3Meta?.alias || [],
    redirect: newqF3K9B1Nn3Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue").then(m => m.default || m)
  },
  {
    name: indexJkgZq6o2JnMeta?.name ?? "classified-ads___cs",
    path: indexJkgZq6o2JnMeta?.path ?? "/inzeraty",
    meta: indexJkgZq6o2JnMeta || {},
    alias: indexJkgZq6o2JnMeta?.alias || [],
    redirect: indexJkgZq6o2JnMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexJkgZq6o2JnMeta?.name ?? "classified-ads___en",
    path: indexJkgZq6o2JnMeta?.path ?? "/en/classified-ads",
    meta: indexJkgZq6o2JnMeta || {},
    alias: indexJkgZq6o2JnMeta?.alias || [],
    redirect: indexJkgZq6o2JnMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexJkgZq6o2JnMeta?.name ?? "classified-ads___pl",
    path: indexJkgZq6o2JnMeta?.path ?? "/pl/ogloszenia",
    meta: indexJkgZq6o2JnMeta || {},
    alias: indexJkgZq6o2JnMeta?.alias || [],
    redirect: indexJkgZq6o2JnMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexJkgZq6o2JnMeta?.name ?? "classified-ads___sk",
    path: indexJkgZq6o2JnMeta?.path ?? "/sk/inzeraty",
    meta: indexJkgZq6o2JnMeta || {},
    alias: indexJkgZq6o2JnMeta?.alias || [],
    redirect: indexJkgZq6o2JnMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexJkgZq6o2JnMeta?.name ?? "classified-ads___de",
    path: indexJkgZq6o2JnMeta?.path ?? "/de/anzeigen",
    meta: indexJkgZq6o2JnMeta || {},
    alias: indexJkgZq6o2JnMeta?.alias || [],
    redirect: indexJkgZq6o2JnMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue").then(m => m.default || m)
  },
  {
    name: myp1sW3nF7ONMeta?.name ?? "classified-ads-my___cs",
    path: myp1sW3nF7ONMeta?.path ?? "/inzeraty/moje",
    meta: myp1sW3nF7ONMeta || {},
    alias: myp1sW3nF7ONMeta?.alias || [],
    redirect: myp1sW3nF7ONMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue").then(m => m.default || m)
  },
  {
    name: myp1sW3nF7ONMeta?.name ?? "classified-ads-my___en",
    path: myp1sW3nF7ONMeta?.path ?? "/en/classified-ads/my",
    meta: myp1sW3nF7ONMeta || {},
    alias: myp1sW3nF7ONMeta?.alias || [],
    redirect: myp1sW3nF7ONMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue").then(m => m.default || m)
  },
  {
    name: myp1sW3nF7ONMeta?.name ?? "classified-ads-my___pl",
    path: myp1sW3nF7ONMeta?.path ?? "/pl/ogloszenia/moje",
    meta: myp1sW3nF7ONMeta || {},
    alias: myp1sW3nF7ONMeta?.alias || [],
    redirect: myp1sW3nF7ONMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue").then(m => m.default || m)
  },
  {
    name: myp1sW3nF7ONMeta?.name ?? "classified-ads-my___sk",
    path: myp1sW3nF7ONMeta?.path ?? "/sk/inzeraty/moje",
    meta: myp1sW3nF7ONMeta || {},
    alias: myp1sW3nF7ONMeta?.alias || [],
    redirect: myp1sW3nF7ONMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue").then(m => m.default || m)
  },
  {
    name: myp1sW3nF7ONMeta?.name ?? "classified-ads-my___de",
    path: myp1sW3nF7ONMeta?.path ?? "/de/anzeigen/meine",
    meta: myp1sW3nF7ONMeta || {},
    alias: myp1sW3nF7ONMeta?.alias || [],
    redirect: myp1sW3nF7ONMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue").then(m => m.default || m)
  },
  {
    name: contactu93tYJ4mkQMeta?.name ?? "contact___cs",
    path: contactu93tYJ4mkQMeta?.path ?? "/kontakt",
    meta: contactu93tYJ4mkQMeta || {},
    alias: contactu93tYJ4mkQMeta?.alias || [],
    redirect: contactu93tYJ4mkQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactu93tYJ4mkQMeta?.name ?? "contact___en",
    path: contactu93tYJ4mkQMeta?.path ?? "/en/contact",
    meta: contactu93tYJ4mkQMeta || {},
    alias: contactu93tYJ4mkQMeta?.alias || [],
    redirect: contactu93tYJ4mkQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactu93tYJ4mkQMeta?.name ?? "contact___pl",
    path: contactu93tYJ4mkQMeta?.path ?? "/pl/kontakt",
    meta: contactu93tYJ4mkQMeta || {},
    alias: contactu93tYJ4mkQMeta?.alias || [],
    redirect: contactu93tYJ4mkQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactu93tYJ4mkQMeta?.name ?? "contact___sk",
    path: contactu93tYJ4mkQMeta?.path ?? "/sk/kontakt",
    meta: contactu93tYJ4mkQMeta || {},
    alias: contactu93tYJ4mkQMeta?.alias || [],
    redirect: contactu93tYJ4mkQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactu93tYJ4mkQMeta?.name ?? "contact___de",
    path: contactu93tYJ4mkQMeta?.path ?? "/de/kontakt",
    meta: contactu93tYJ4mkQMeta || {},
    alias: contactu93tYJ4mkQMeta?.alias || [],
    redirect: contactu93tYJ4mkQMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91hash_935Q43ibMduyMeta?.name ?? "delete-account-hash___cs",
    path: _91hash_935Q43ibMduyMeta?.path ?? "/smazani-uctu/:hash",
    meta: _91hash_935Q43ibMduyMeta || {},
    alias: _91hash_935Q43ibMduyMeta?.alias || [],
    redirect: _91hash_935Q43ibMduyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_935Q43ibMduyMeta?.name ?? "delete-account-hash___en",
    path: _91hash_935Q43ibMduyMeta?.path ?? "/en/delete-account/:hash",
    meta: _91hash_935Q43ibMduyMeta || {},
    alias: _91hash_935Q43ibMduyMeta?.alias || [],
    redirect: _91hash_935Q43ibMduyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_935Q43ibMduyMeta?.name ?? "delete-account-hash___pl",
    path: _91hash_935Q43ibMduyMeta?.path ?? "/pl/usuniecie-konta/:hash",
    meta: _91hash_935Q43ibMduyMeta || {},
    alias: _91hash_935Q43ibMduyMeta?.alias || [],
    redirect: _91hash_935Q43ibMduyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_935Q43ibMduyMeta?.name ?? "delete-account-hash___sk",
    path: _91hash_935Q43ibMduyMeta?.path ?? "/sk/zmazanie-uctu/:hash",
    meta: _91hash_935Q43ibMduyMeta || {},
    alias: _91hash_935Q43ibMduyMeta?.alias || [],
    redirect: _91hash_935Q43ibMduyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_935Q43ibMduyMeta?.name ?? "delete-account-hash___de",
    path: _91hash_935Q43ibMduyMeta?.path ?? "/de/kontoloschung/:hash",
    meta: _91hash_935Q43ibMduyMeta || {},
    alias: _91hash_935Q43ibMduyMeta?.alias || [],
    redirect: _91hash_935Q43ibMduyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue").then(m => m.default || m)
  },
  {
    name: favoritesuYUUm280XNMeta?.name ?? "favorites___cs",
    path: favoritesuYUUm280XNMeta?.path ?? "/oblibene",
    meta: favoritesuYUUm280XNMeta || {},
    alias: favoritesuYUUm280XNMeta?.alias || [],
    redirect: favoritesuYUUm280XNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesuYUUm280XNMeta?.name ?? "favorites___en",
    path: favoritesuYUUm280XNMeta?.path ?? "/en/favorites",
    meta: favoritesuYUUm280XNMeta || {},
    alias: favoritesuYUUm280XNMeta?.alias || [],
    redirect: favoritesuYUUm280XNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesuYUUm280XNMeta?.name ?? "favorites___pl",
    path: favoritesuYUUm280XNMeta?.path ?? "/pl/ulubione",
    meta: favoritesuYUUm280XNMeta || {},
    alias: favoritesuYUUm280XNMeta?.alias || [],
    redirect: favoritesuYUUm280XNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesuYUUm280XNMeta?.name ?? "favorites___sk",
    path: favoritesuYUUm280XNMeta?.path ?? "/sk/oblubene",
    meta: favoritesuYUUm280XNMeta || {},
    alias: favoritesuYUUm280XNMeta?.alias || [],
    redirect: favoritesuYUUm280XNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesuYUUm280XNMeta?.name ?? "favorites___de",
    path: favoritesuYUUm280XNMeta?.path ?? "/de/beliebte",
    meta: favoritesuYUUm280XNMeta || {},
    alias: favoritesuYUUm280XNMeta?.alias || [],
    redirect: favoritesuYUUm280XNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1nAsOd7bZXMeta?.name ?? "forgot-password___cs",
    path: forgot_45password1nAsOd7bZXMeta?.path ?? "/zapomenute-heslo",
    meta: forgot_45password1nAsOd7bZXMeta || {},
    alias: forgot_45password1nAsOd7bZXMeta?.alias || [],
    redirect: forgot_45password1nAsOd7bZXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1nAsOd7bZXMeta?.name ?? "forgot-password___en",
    path: forgot_45password1nAsOd7bZXMeta?.path ?? "/en/forgot-password",
    meta: forgot_45password1nAsOd7bZXMeta || {},
    alias: forgot_45password1nAsOd7bZXMeta?.alias || [],
    redirect: forgot_45password1nAsOd7bZXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1nAsOd7bZXMeta?.name ?? "forgot-password___pl",
    path: forgot_45password1nAsOd7bZXMeta?.path ?? "/pl/zapomniane-haslo",
    meta: forgot_45password1nAsOd7bZXMeta || {},
    alias: forgot_45password1nAsOd7bZXMeta?.alias || [],
    redirect: forgot_45password1nAsOd7bZXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1nAsOd7bZXMeta?.name ?? "forgot-password___sk",
    path: forgot_45password1nAsOd7bZXMeta?.path ?? "/sk/zabudnute-heslo",
    meta: forgot_45password1nAsOd7bZXMeta || {},
    alias: forgot_45password1nAsOd7bZXMeta?.alias || [],
    redirect: forgot_45password1nAsOd7bZXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1nAsOd7bZXMeta?.name ?? "forgot-password___de",
    path: forgot_45password1nAsOd7bZXMeta?.path ?? "/de/passwort-vergessen",
    meta: forgot_45password1nAsOd7bZXMeta || {},
    alias: forgot_45password1nAsOd7bZXMeta?.alias || [],
    redirect: forgot_45password1nAsOd7bZXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: helpM0fuJk0hfyMeta?.name ?? "help___cs",
    path: helpM0fuJk0hfyMeta?.path ?? "/pomoc",
    meta: helpM0fuJk0hfyMeta || {},
    alias: helpM0fuJk0hfyMeta?.alias || [],
    redirect: helpM0fuJk0hfyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpM0fuJk0hfyMeta?.name ?? "help___en",
    path: helpM0fuJk0hfyMeta?.path ?? "/en/help",
    meta: helpM0fuJk0hfyMeta || {},
    alias: helpM0fuJk0hfyMeta?.alias || [],
    redirect: helpM0fuJk0hfyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpM0fuJk0hfyMeta?.name ?? "help___pl",
    path: helpM0fuJk0hfyMeta?.path ?? "/pl/pomoc",
    meta: helpM0fuJk0hfyMeta || {},
    alias: helpM0fuJk0hfyMeta?.alias || [],
    redirect: helpM0fuJk0hfyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpM0fuJk0hfyMeta?.name ?? "help___sk",
    path: helpM0fuJk0hfyMeta?.path ?? "/sk/pomoc",
    meta: helpM0fuJk0hfyMeta || {},
    alias: helpM0fuJk0hfyMeta?.alias || [],
    redirect: helpM0fuJk0hfyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpM0fuJk0hfyMeta?.name ?? "help___de",
    path: helpM0fuJk0hfyMeta?.path ?? "/de/hilfe",
    meta: helpM0fuJk0hfyMeta || {},
    alias: helpM0fuJk0hfyMeta?.alias || [],
    redirect: helpM0fuJk0hfyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexjNGEdGYgnOMeta?.name ?? "index___cs",
    path: indexjNGEdGYgnOMeta?.path ?? "/",
    meta: indexjNGEdGYgnOMeta || {},
    alias: indexjNGEdGYgnOMeta?.alias || [],
    redirect: indexjNGEdGYgnOMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNGEdGYgnOMeta?.name ?? "index___en",
    path: indexjNGEdGYgnOMeta?.path ?? "/en",
    meta: indexjNGEdGYgnOMeta || {},
    alias: indexjNGEdGYgnOMeta?.alias || [],
    redirect: indexjNGEdGYgnOMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNGEdGYgnOMeta?.name ?? "index___pl",
    path: indexjNGEdGYgnOMeta?.path ?? "/pl",
    meta: indexjNGEdGYgnOMeta || {},
    alias: indexjNGEdGYgnOMeta?.alias || [],
    redirect: indexjNGEdGYgnOMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNGEdGYgnOMeta?.name ?? "index___sk",
    path: indexjNGEdGYgnOMeta?.path ?? "/sk",
    meta: indexjNGEdGYgnOMeta || {},
    alias: indexjNGEdGYgnOMeta?.alias || [],
    redirect: indexjNGEdGYgnOMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNGEdGYgnOMeta?.name ?? "index___de",
    path: indexjNGEdGYgnOMeta?.path ?? "/de",
    meta: indexjNGEdGYgnOMeta || {},
    alias: indexjNGEdGYgnOMeta?.alias || [],
    redirect: indexjNGEdGYgnOMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeT9KRdaY4Meta?.name ?? "inspiration-slug___cs",
    path: _91slug_93UeT9KRdaY4Meta?.path ?? "/inspirace/:slug",
    meta: _91slug_93UeT9KRdaY4Meta || {},
    alias: _91slug_93UeT9KRdaY4Meta?.alias || [],
    redirect: _91slug_93UeT9KRdaY4Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeT9KRdaY4Meta?.name ?? "inspiration-slug___en",
    path: _91slug_93UeT9KRdaY4Meta?.path ?? "/en/inspiration/:slug",
    meta: _91slug_93UeT9KRdaY4Meta || {},
    alias: _91slug_93UeT9KRdaY4Meta?.alias || [],
    redirect: _91slug_93UeT9KRdaY4Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeT9KRdaY4Meta?.name ?? "inspiration-slug___pl",
    path: _91slug_93UeT9KRdaY4Meta?.path ?? "/pl/inspiracja/:slug",
    meta: _91slug_93UeT9KRdaY4Meta || {},
    alias: _91slug_93UeT9KRdaY4Meta?.alias || [],
    redirect: _91slug_93UeT9KRdaY4Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeT9KRdaY4Meta?.name ?? "inspiration-slug___sk",
    path: _91slug_93UeT9KRdaY4Meta?.path ?? "/sk/inspiracia/:slug",
    meta: _91slug_93UeT9KRdaY4Meta || {},
    alias: _91slug_93UeT9KRdaY4Meta?.alias || [],
    redirect: _91slug_93UeT9KRdaY4Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeT9KRdaY4Meta?.name ?? "inspiration-slug___de",
    path: _91slug_93UeT9KRdaY4Meta?.path ?? "/de/inspiration/:slug",
    meta: _91slug_93UeT9KRdaY4Meta || {},
    alias: _91slug_93UeT9KRdaY4Meta?.alias || [],
    redirect: _91slug_93UeT9KRdaY4Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: addTNhi6bfHzgMeta?.name ?? "inspiration-add___cs",
    path: addTNhi6bfHzgMeta?.path ?? "/inspirace/nova",
    meta: addTNhi6bfHzgMeta || {},
    alias: addTNhi6bfHzgMeta?.alias || [],
    redirect: addTNhi6bfHzgMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue").then(m => m.default || m)
  },
  {
    name: addTNhi6bfHzgMeta?.name ?? "inspiration-add___en",
    path: addTNhi6bfHzgMeta?.path ?? "/en/inspiration/add",
    meta: addTNhi6bfHzgMeta || {},
    alias: addTNhi6bfHzgMeta?.alias || [],
    redirect: addTNhi6bfHzgMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue").then(m => m.default || m)
  },
  {
    name: addTNhi6bfHzgMeta?.name ?? "inspiration-add___pl",
    path: addTNhi6bfHzgMeta?.path ?? "/pl/inspiracja/nowa",
    meta: addTNhi6bfHzgMeta || {},
    alias: addTNhi6bfHzgMeta?.alias || [],
    redirect: addTNhi6bfHzgMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue").then(m => m.default || m)
  },
  {
    name: addTNhi6bfHzgMeta?.name ?? "inspiration-add___sk",
    path: addTNhi6bfHzgMeta?.path ?? "/sk/inspiracia/nova",
    meta: addTNhi6bfHzgMeta || {},
    alias: addTNhi6bfHzgMeta?.alias || [],
    redirect: addTNhi6bfHzgMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue").then(m => m.default || m)
  },
  {
    name: addTNhi6bfHzgMeta?.name ?? "inspiration-add___de",
    path: addTNhi6bfHzgMeta?.path ?? "/de/inspiration/neue",
    meta: addTNhi6bfHzgMeta || {},
    alias: addTNhi6bfHzgMeta?.alias || [],
    redirect: addTNhi6bfHzgMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue").then(m => m.default || m)
  },
  {
    name: _91content_id_93NQOmhqY6mxMeta?.name ?? "inspiration-edit-content_id___cs",
    path: _91content_id_93NQOmhqY6mxMeta?.path ?? "/inspirace/editace/:content_id",
    meta: _91content_id_93NQOmhqY6mxMeta || {},
    alias: _91content_id_93NQOmhqY6mxMeta?.alias || [],
    redirect: _91content_id_93NQOmhqY6mxMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue").then(m => m.default || m)
  },
  {
    name: _91content_id_93NQOmhqY6mxMeta?.name ?? "inspiration-edit-content_id___en",
    path: _91content_id_93NQOmhqY6mxMeta?.path ?? "/en/inspiration/edit/:content_id",
    meta: _91content_id_93NQOmhqY6mxMeta || {},
    alias: _91content_id_93NQOmhqY6mxMeta?.alias || [],
    redirect: _91content_id_93NQOmhqY6mxMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue").then(m => m.default || m)
  },
  {
    name: _91content_id_93NQOmhqY6mxMeta?.name ?? "inspiration-edit-content_id___pl",
    path: _91content_id_93NQOmhqY6mxMeta?.path ?? "/pl/inspiracja/redagowanie/:content_id",
    meta: _91content_id_93NQOmhqY6mxMeta || {},
    alias: _91content_id_93NQOmhqY6mxMeta?.alias || [],
    redirect: _91content_id_93NQOmhqY6mxMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue").then(m => m.default || m)
  },
  {
    name: _91content_id_93NQOmhqY6mxMeta?.name ?? "inspiration-edit-content_id___sk",
    path: _91content_id_93NQOmhqY6mxMeta?.path ?? "/sk/inspiracia/editacia/:content_id",
    meta: _91content_id_93NQOmhqY6mxMeta || {},
    alias: _91content_id_93NQOmhqY6mxMeta?.alias || [],
    redirect: _91content_id_93NQOmhqY6mxMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue").then(m => m.default || m)
  },
  {
    name: _91content_id_93NQOmhqY6mxMeta?.name ?? "inspiration-edit-content_id___de",
    path: _91content_id_93NQOmhqY6mxMeta?.path ?? "/de/inspiration/editierung/:content_id",
    meta: _91content_id_93NQOmhqY6mxMeta || {},
    alias: _91content_id_93NQOmhqY6mxMeta?.alias || [],
    redirect: _91content_id_93NQOmhqY6mxMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue").then(m => m.default || m)
  },
  {
    name: indexxZl64Y2VyUMeta?.name ?? "inspiration___cs",
    path: indexxZl64Y2VyUMeta?.path ?? "/inspirace",
    meta: indexxZl64Y2VyUMeta || {},
    alias: indexxZl64Y2VyUMeta?.alias || [],
    redirect: indexxZl64Y2VyUMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZl64Y2VyUMeta?.name ?? "inspiration___en",
    path: indexxZl64Y2VyUMeta?.path ?? "/en/inspiration",
    meta: indexxZl64Y2VyUMeta || {},
    alias: indexxZl64Y2VyUMeta?.alias || [],
    redirect: indexxZl64Y2VyUMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZl64Y2VyUMeta?.name ?? "inspiration___pl",
    path: indexxZl64Y2VyUMeta?.path ?? "/pl/inspiracja",
    meta: indexxZl64Y2VyUMeta || {},
    alias: indexxZl64Y2VyUMeta?.alias || [],
    redirect: indexxZl64Y2VyUMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZl64Y2VyUMeta?.name ?? "inspiration___sk",
    path: indexxZl64Y2VyUMeta?.path ?? "/sk/inspiracia",
    meta: indexxZl64Y2VyUMeta || {},
    alias: indexxZl64Y2VyUMeta?.alias || [],
    redirect: indexxZl64Y2VyUMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZl64Y2VyUMeta?.name ?? "inspiration___de",
    path: indexxZl64Y2VyUMeta?.path ?? "/de/inspiration",
    meta: indexxZl64Y2VyUMeta || {},
    alias: indexxZl64Y2VyUMeta?.alias || [],
    redirect: indexxZl64Y2VyUMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MJSAER1vFZMeta?.name ?? "inspiration-type-slug___cs",
    path: _91slug_93MJSAER1vFZMeta?.path ?? "/inspirace/typu/:slug",
    meta: _91slug_93MJSAER1vFZMeta || {},
    alias: _91slug_93MJSAER1vFZMeta?.alias || [],
    redirect: _91slug_93MJSAER1vFZMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MJSAER1vFZMeta?.name ?? "inspiration-type-slug___en",
    path: _91slug_93MJSAER1vFZMeta?.path ?? "/en/inspiration/type/:slug",
    meta: _91slug_93MJSAER1vFZMeta || {},
    alias: _91slug_93MJSAER1vFZMeta?.alias || [],
    redirect: _91slug_93MJSAER1vFZMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MJSAER1vFZMeta?.name ?? "inspiration-type-slug___pl",
    path: _91slug_93MJSAER1vFZMeta?.path ?? "/pl/inspiracja/typ/:slug",
    meta: _91slug_93MJSAER1vFZMeta || {},
    alias: _91slug_93MJSAER1vFZMeta?.alias || [],
    redirect: _91slug_93MJSAER1vFZMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MJSAER1vFZMeta?.name ?? "inspiration-type-slug___sk",
    path: _91slug_93MJSAER1vFZMeta?.path ?? "/sk/inspiracia/typu/:slug",
    meta: _91slug_93MJSAER1vFZMeta || {},
    alias: _91slug_93MJSAER1vFZMeta?.alias || [],
    redirect: _91slug_93MJSAER1vFZMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MJSAER1vFZMeta?.name ?? "inspiration-type-slug___de",
    path: _91slug_93MJSAER1vFZMeta?.path ?? "/de/inspiration/art/:slug",
    meta: _91slug_93MJSAER1vFZMeta || {},
    alias: _91slug_93MJSAER1vFZMeta?.alias || [],
    redirect: _91slug_93MJSAER1vFZMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue").then(m => m.default || m)
  },
  {
    name: loginO4PQPZrUdGMeta?.name ?? "login___cs",
    path: loginO4PQPZrUdGMeta?.path ?? "/prihlaseni",
    meta: loginO4PQPZrUdGMeta || {},
    alias: loginO4PQPZrUdGMeta?.alias || [],
    redirect: loginO4PQPZrUdGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginO4PQPZrUdGMeta?.name ?? "login___en",
    path: loginO4PQPZrUdGMeta?.path ?? "/en/login",
    meta: loginO4PQPZrUdGMeta || {},
    alias: loginO4PQPZrUdGMeta?.alias || [],
    redirect: loginO4PQPZrUdGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginO4PQPZrUdGMeta?.name ?? "login___pl",
    path: loginO4PQPZrUdGMeta?.path ?? "/pl/logowanie",
    meta: loginO4PQPZrUdGMeta || {},
    alias: loginO4PQPZrUdGMeta?.alias || [],
    redirect: loginO4PQPZrUdGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginO4PQPZrUdGMeta?.name ?? "login___sk",
    path: loginO4PQPZrUdGMeta?.path ?? "/sk/prihlasenie",
    meta: loginO4PQPZrUdGMeta || {},
    alias: loginO4PQPZrUdGMeta?.alias || [],
    redirect: loginO4PQPZrUdGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginO4PQPZrUdGMeta?.name ?? "login___de",
    path: loginO4PQPZrUdGMeta?.path ?? "/de/einloggen",
    meta: loginO4PQPZrUdGMeta || {},
    alias: loginO4PQPZrUdGMeta?.alias || [],
    redirect: loginO4PQPZrUdGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutixCudY8mQ5Meta?.name ?? "logout___cs",
    path: logoutixCudY8mQ5Meta?.path ?? "/odhlaseni",
    meta: logoutixCudY8mQ5Meta || {},
    alias: logoutixCudY8mQ5Meta?.alias || [],
    redirect: logoutixCudY8mQ5Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutixCudY8mQ5Meta?.name ?? "logout___en",
    path: logoutixCudY8mQ5Meta?.path ?? "/en/logout",
    meta: logoutixCudY8mQ5Meta || {},
    alias: logoutixCudY8mQ5Meta?.alias || [],
    redirect: logoutixCudY8mQ5Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutixCudY8mQ5Meta?.name ?? "logout___pl",
    path: logoutixCudY8mQ5Meta?.path ?? "/pl/wylogowanie",
    meta: logoutixCudY8mQ5Meta || {},
    alias: logoutixCudY8mQ5Meta?.alias || [],
    redirect: logoutixCudY8mQ5Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutixCudY8mQ5Meta?.name ?? "logout___sk",
    path: logoutixCudY8mQ5Meta?.path ?? "/sk/odhlasenie",
    meta: logoutixCudY8mQ5Meta || {},
    alias: logoutixCudY8mQ5Meta?.alias || [],
    redirect: logoutixCudY8mQ5Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutixCudY8mQ5Meta?.name ?? "logout___de",
    path: logoutixCudY8mQ5Meta?.path ?? "/de/ausloggen",
    meta: logoutixCudY8mQ5Meta || {},
    alias: logoutixCudY8mQ5Meta?.alias || [],
    redirect: logoutixCudY8mQ5Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r0DdiXNiXGMeta?.name ?? "news-slug___cs",
    path: _91slug_93r0DdiXNiXGMeta?.path ?? "/novinky/:slug",
    meta: _91slug_93r0DdiXNiXGMeta || {},
    alias: _91slug_93r0DdiXNiXGMeta?.alias || [],
    redirect: _91slug_93r0DdiXNiXGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r0DdiXNiXGMeta?.name ?? "news-slug___en",
    path: _91slug_93r0DdiXNiXGMeta?.path ?? "/en/news/:slug",
    meta: _91slug_93r0DdiXNiXGMeta || {},
    alias: _91slug_93r0DdiXNiXGMeta?.alias || [],
    redirect: _91slug_93r0DdiXNiXGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r0DdiXNiXGMeta?.name ?? "news-slug___pl",
    path: _91slug_93r0DdiXNiXGMeta?.path ?? "/pl/nowosc/:slug",
    meta: _91slug_93r0DdiXNiXGMeta || {},
    alias: _91slug_93r0DdiXNiXGMeta?.alias || [],
    redirect: _91slug_93r0DdiXNiXGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r0DdiXNiXGMeta?.name ?? "news-slug___sk",
    path: _91slug_93r0DdiXNiXGMeta?.path ?? "/sk/novinky/:slug",
    meta: _91slug_93r0DdiXNiXGMeta || {},
    alias: _91slug_93r0DdiXNiXGMeta?.alias || [],
    redirect: _91slug_93r0DdiXNiXGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r0DdiXNiXGMeta?.name ?? "news-slug___de",
    path: _91slug_93r0DdiXNiXGMeta?.path ?? "/de/nachrichten/:slug",
    meta: _91slug_93r0DdiXNiXGMeta || {},
    alias: _91slug_93r0DdiXNiXGMeta?.alias || [],
    redirect: _91slug_93r0DdiXNiXGMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tJPGhIXm5OMeta?.name ?? "news-about-slug___cs",
    path: _91slug_93tJPGhIXm5OMeta?.path ?? "/novinky/tema/:slug",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    alias: _91slug_93tJPGhIXm5OMeta?.alias || [],
    redirect: _91slug_93tJPGhIXm5OMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tJPGhIXm5OMeta?.name ?? "news-about-slug___en",
    path: _91slug_93tJPGhIXm5OMeta?.path ?? "/en/news/about/:slug",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    alias: _91slug_93tJPGhIXm5OMeta?.alias || [],
    redirect: _91slug_93tJPGhIXm5OMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tJPGhIXm5OMeta?.name ?? "news-about-slug___pl",
    path: _91slug_93tJPGhIXm5OMeta?.path ?? "/pl/nowosc/temat/:slug",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    alias: _91slug_93tJPGhIXm5OMeta?.alias || [],
    redirect: _91slug_93tJPGhIXm5OMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tJPGhIXm5OMeta?.name ?? "news-about-slug___sk",
    path: _91slug_93tJPGhIXm5OMeta?.path ?? "/sk/novinky/tema/:slug",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    alias: _91slug_93tJPGhIXm5OMeta?.alias || [],
    redirect: _91slug_93tJPGhIXm5OMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tJPGhIXm5OMeta?.name ?? "news-about-slug___de",
    path: _91slug_93tJPGhIXm5OMeta?.path ?? "/de/nachrichten/thema/:slug",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    alias: _91slug_93tJPGhIXm5OMeta?.alias || [],
    redirect: _91slug_93tJPGhIXm5OMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaps9REVB6uMeta?.name ?? "news___cs",
    path: indexaps9REVB6uMeta?.path ?? "/novinky",
    meta: indexaps9REVB6uMeta || {},
    alias: indexaps9REVB6uMeta?.alias || [],
    redirect: indexaps9REVB6uMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexaps9REVB6uMeta?.name ?? "news___en",
    path: indexaps9REVB6uMeta?.path ?? "/en/news",
    meta: indexaps9REVB6uMeta || {},
    alias: indexaps9REVB6uMeta?.alias || [],
    redirect: indexaps9REVB6uMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexaps9REVB6uMeta?.name ?? "news___pl",
    path: indexaps9REVB6uMeta?.path ?? "/pl/nowosc",
    meta: indexaps9REVB6uMeta || {},
    alias: indexaps9REVB6uMeta?.alias || [],
    redirect: indexaps9REVB6uMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexaps9REVB6uMeta?.name ?? "news___sk",
    path: indexaps9REVB6uMeta?.path ?? "/sk/novinky",
    meta: indexaps9REVB6uMeta || {},
    alias: indexaps9REVB6uMeta?.alias || [],
    redirect: indexaps9REVB6uMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexaps9REVB6uMeta?.name ?? "news___de",
    path: indexaps9REVB6uMeta?.path ?? "/de/nachrichten",
    meta: indexaps9REVB6uMeta || {},
    alias: indexaps9REVB6uMeta?.alias || [],
    redirect: indexaps9REVB6uMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETNZaqS9XkMeta?.name ?? "offers-slug___cs",
    path: _91slug_93ETNZaqS9XkMeta?.path ?? "/nabidky/:slug",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    alias: _91slug_93ETNZaqS9XkMeta?.alias || [],
    redirect: _91slug_93ETNZaqS9XkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETNZaqS9XkMeta?.name ?? "offers-slug___en",
    path: _91slug_93ETNZaqS9XkMeta?.path ?? "/en/offers/:slug",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    alias: _91slug_93ETNZaqS9XkMeta?.alias || [],
    redirect: _91slug_93ETNZaqS9XkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETNZaqS9XkMeta?.name ?? "offers-slug___pl",
    path: _91slug_93ETNZaqS9XkMeta?.path ?? "/pl/oferty/:slug",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    alias: _91slug_93ETNZaqS9XkMeta?.alias || [],
    redirect: _91slug_93ETNZaqS9XkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETNZaqS9XkMeta?.name ?? "offers-slug___sk",
    path: _91slug_93ETNZaqS9XkMeta?.path ?? "/sk/ponuky/:slug",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    alias: _91slug_93ETNZaqS9XkMeta?.alias || [],
    redirect: _91slug_93ETNZaqS9XkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETNZaqS9XkMeta?.name ?? "offers-slug___de",
    path: _91slug_93ETNZaqS9XkMeta?.path ?? "/de/angeboten/:slug",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    alias: _91slug_93ETNZaqS9XkMeta?.alias || [],
    redirect: _91slug_93ETNZaqS9XkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexI7GXPuEPptMeta?.name ?? "offers___cs",
    path: indexI7GXPuEPptMeta?.path ?? "/nabidky",
    meta: indexI7GXPuEPptMeta || {},
    alias: indexI7GXPuEPptMeta?.alias || [],
    redirect: indexI7GXPuEPptMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7GXPuEPptMeta?.name ?? "offers___en",
    path: indexI7GXPuEPptMeta?.path ?? "/en/offers",
    meta: indexI7GXPuEPptMeta || {},
    alias: indexI7GXPuEPptMeta?.alias || [],
    redirect: indexI7GXPuEPptMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7GXPuEPptMeta?.name ?? "offers___pl",
    path: indexI7GXPuEPptMeta?.path ?? "/pl/oferty",
    meta: indexI7GXPuEPptMeta || {},
    alias: indexI7GXPuEPptMeta?.alias || [],
    redirect: indexI7GXPuEPptMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7GXPuEPptMeta?.name ?? "offers___sk",
    path: indexI7GXPuEPptMeta?.path ?? "/sk/ponuky",
    meta: indexI7GXPuEPptMeta || {},
    alias: indexI7GXPuEPptMeta?.alias || [],
    redirect: indexI7GXPuEPptMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexI7GXPuEPptMeta?.name ?? "offers___de",
    path: indexI7GXPuEPptMeta?.path ?? "/de/angeboten",
    meta: indexI7GXPuEPptMeta || {},
    alias: indexI7GXPuEPptMeta?.alias || [],
    redirect: indexI7GXPuEPptMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93Rnr0Pghxq8Meta?.name ?? "orders-cart_id___cs",
    path: _91cart_id_93Rnr0Pghxq8Meta?.path ?? "/nakupy/:cart_id",
    meta: _91cart_id_93Rnr0Pghxq8Meta || {},
    alias: _91cart_id_93Rnr0Pghxq8Meta?.alias || [],
    redirect: _91cart_id_93Rnr0Pghxq8Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93Rnr0Pghxq8Meta?.name ?? "orders-cart_id___en",
    path: _91cart_id_93Rnr0Pghxq8Meta?.path ?? "/en/orders/:cart_id",
    meta: _91cart_id_93Rnr0Pghxq8Meta || {},
    alias: _91cart_id_93Rnr0Pghxq8Meta?.alias || [],
    redirect: _91cart_id_93Rnr0Pghxq8Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93Rnr0Pghxq8Meta?.name ?? "orders-cart_id___pl",
    path: _91cart_id_93Rnr0Pghxq8Meta?.path ?? "/pl/kupowanie/:cart_id",
    meta: _91cart_id_93Rnr0Pghxq8Meta || {},
    alias: _91cart_id_93Rnr0Pghxq8Meta?.alias || [],
    redirect: _91cart_id_93Rnr0Pghxq8Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93Rnr0Pghxq8Meta?.name ?? "orders-cart_id___sk",
    path: _91cart_id_93Rnr0Pghxq8Meta?.path ?? "/sk/nakupy/:cart_id",
    meta: _91cart_id_93Rnr0Pghxq8Meta || {},
    alias: _91cart_id_93Rnr0Pghxq8Meta?.alias || [],
    redirect: _91cart_id_93Rnr0Pghxq8Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93Rnr0Pghxq8Meta?.name ?? "orders-cart_id___de",
    path: _91cart_id_93Rnr0Pghxq8Meta?.path ?? "/de/kaufen/:cart_id",
    meta: _91cart_id_93Rnr0Pghxq8Meta || {},
    alias: _91cart_id_93Rnr0Pghxq8Meta?.alias || [],
    redirect: _91cart_id_93Rnr0Pghxq8Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: indexJRZ20ZQj6EMeta?.name ?? "orders___cs",
    path: indexJRZ20ZQj6EMeta?.path ?? "/nakupy",
    meta: indexJRZ20ZQj6EMeta || {},
    alias: indexJRZ20ZQj6EMeta?.alias || [],
    redirect: indexJRZ20ZQj6EMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexJRZ20ZQj6EMeta?.name ?? "orders___en",
    path: indexJRZ20ZQj6EMeta?.path ?? "/en/orders",
    meta: indexJRZ20ZQj6EMeta || {},
    alias: indexJRZ20ZQj6EMeta?.alias || [],
    redirect: indexJRZ20ZQj6EMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexJRZ20ZQj6EMeta?.name ?? "orders___pl",
    path: indexJRZ20ZQj6EMeta?.path ?? "/pl/kupowanie",
    meta: indexJRZ20ZQj6EMeta || {},
    alias: indexJRZ20ZQj6EMeta?.alias || [],
    redirect: indexJRZ20ZQj6EMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexJRZ20ZQj6EMeta?.name ?? "orders___sk",
    path: indexJRZ20ZQj6EMeta?.path ?? "/sk/nakupy",
    meta: indexJRZ20ZQj6EMeta || {},
    alias: indexJRZ20ZQj6EMeta?.alias || [],
    redirect: indexJRZ20ZQj6EMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexJRZ20ZQj6EMeta?.name ?? "orders___de",
    path: indexJRZ20ZQj6EMeta?.path ?? "/de/kaufen",
    meta: indexJRZ20ZQj6EMeta || {},
    alias: indexJRZ20ZQj6EMeta?.alias || [],
    redirect: indexJRZ20ZQj6EMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: privacy8vIdl4MH0DMeta?.name ?? "privacy___cs",
    path: privacy8vIdl4MH0DMeta?.path ?? "/gdpr",
    meta: privacy8vIdl4MH0DMeta || {},
    alias: privacy8vIdl4MH0DMeta?.alias || [],
    redirect: privacy8vIdl4MH0DMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8vIdl4MH0DMeta?.name ?? "privacy___en",
    path: privacy8vIdl4MH0DMeta?.path ?? "/en/privacy",
    meta: privacy8vIdl4MH0DMeta || {},
    alias: privacy8vIdl4MH0DMeta?.alias || [],
    redirect: privacy8vIdl4MH0DMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8vIdl4MH0DMeta?.name ?? "privacy___pl",
    path: privacy8vIdl4MH0DMeta?.path ?? "/pl/gdpr",
    meta: privacy8vIdl4MH0DMeta || {},
    alias: privacy8vIdl4MH0DMeta?.alias || [],
    redirect: privacy8vIdl4MH0DMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8vIdl4MH0DMeta?.name ?? "privacy___sk",
    path: privacy8vIdl4MH0DMeta?.path ?? "/sk/gdpr",
    meta: privacy8vIdl4MH0DMeta || {},
    alias: privacy8vIdl4MH0DMeta?.alias || [],
    redirect: privacy8vIdl4MH0DMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8vIdl4MH0DMeta?.name ?? "privacy___de",
    path: privacy8vIdl4MH0DMeta?.path ?? "/de/gdpr",
    meta: privacy8vIdl4MH0DMeta || {},
    alias: privacy8vIdl4MH0DMeta?.alias || [],
    redirect: privacy8vIdl4MH0DMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932FLyuAd54aMeta?.name ?? "product-slug___cs",
    path: _91slug_932FLyuAd54aMeta?.path ?? "/produkt/:slug",
    meta: _91slug_932FLyuAd54aMeta || {},
    alias: _91slug_932FLyuAd54aMeta?.alias || [],
    redirect: _91slug_932FLyuAd54aMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932FLyuAd54aMeta?.name ?? "product-slug___en",
    path: _91slug_932FLyuAd54aMeta?.path ?? "/en/product/:slug",
    meta: _91slug_932FLyuAd54aMeta || {},
    alias: _91slug_932FLyuAd54aMeta?.alias || [],
    redirect: _91slug_932FLyuAd54aMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932FLyuAd54aMeta?.name ?? "product-slug___pl",
    path: _91slug_932FLyuAd54aMeta?.path ?? "/pl/produkt/:slug",
    meta: _91slug_932FLyuAd54aMeta || {},
    alias: _91slug_932FLyuAd54aMeta?.alias || [],
    redirect: _91slug_932FLyuAd54aMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932FLyuAd54aMeta?.name ?? "product-slug___sk",
    path: _91slug_932FLyuAd54aMeta?.path ?? "/sk/produkt/:slug",
    meta: _91slug_932FLyuAd54aMeta || {},
    alias: _91slug_932FLyuAd54aMeta?.alias || [],
    redirect: _91slug_932FLyuAd54aMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932FLyuAd54aMeta?.name ?? "product-slug___de",
    path: _91slug_932FLyuAd54aMeta?.path ?? "/de/produkt/:slug",
    meta: _91slug_932FLyuAd54aMeta || {},
    alias: _91slug_932FLyuAd54aMeta?.alias || [],
    redirect: _91slug_932FLyuAd54aMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: profileMhUqW6cbF7Meta?.name ?? "profile___cs",
    path: profileMhUqW6cbF7Meta?.path ?? "/profil",
    meta: profileMhUqW6cbF7Meta || {},
    alias: profileMhUqW6cbF7Meta?.alias || [],
    redirect: profileMhUqW6cbF7Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMhUqW6cbF7Meta?.name ?? "profile___en",
    path: profileMhUqW6cbF7Meta?.path ?? "/en/profile",
    meta: profileMhUqW6cbF7Meta || {},
    alias: profileMhUqW6cbF7Meta?.alias || [],
    redirect: profileMhUqW6cbF7Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMhUqW6cbF7Meta?.name ?? "profile___pl",
    path: profileMhUqW6cbF7Meta?.path ?? "/pl/profil",
    meta: profileMhUqW6cbF7Meta || {},
    alias: profileMhUqW6cbF7Meta?.alias || [],
    redirect: profileMhUqW6cbF7Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMhUqW6cbF7Meta?.name ?? "profile___sk",
    path: profileMhUqW6cbF7Meta?.path ?? "/sk/profil",
    meta: profileMhUqW6cbF7Meta || {},
    alias: profileMhUqW6cbF7Meta?.alias || [],
    redirect: profileMhUqW6cbF7Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMhUqW6cbF7Meta?.name ?? "profile___de",
    path: profileMhUqW6cbF7Meta?.path ?? "/de/profil",
    meta: profileMhUqW6cbF7Meta || {},
    alias: profileMhUqW6cbF7Meta?.alias || [],
    redirect: profileMhUqW6cbF7Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934K2BktOLBzMeta?.name ?? "reference-slug___cs",
    path: _91slug_934K2BktOLBzMeta?.path ?? "/reference/:slug",
    meta: _91slug_934K2BktOLBzMeta || {},
    alias: _91slug_934K2BktOLBzMeta?.alias || [],
    redirect: _91slug_934K2BktOLBzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934K2BktOLBzMeta?.name ?? "reference-slug___en",
    path: _91slug_934K2BktOLBzMeta?.path ?? "/en/reference/:slug",
    meta: _91slug_934K2BktOLBzMeta || {},
    alias: _91slug_934K2BktOLBzMeta?.alias || [],
    redirect: _91slug_934K2BktOLBzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934K2BktOLBzMeta?.name ?? "reference-slug___pl",
    path: _91slug_934K2BktOLBzMeta?.path ?? "/pl/referencja/:slug",
    meta: _91slug_934K2BktOLBzMeta || {},
    alias: _91slug_934K2BktOLBzMeta?.alias || [],
    redirect: _91slug_934K2BktOLBzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934K2BktOLBzMeta?.name ?? "reference-slug___sk",
    path: _91slug_934K2BktOLBzMeta?.path ?? "/sk/referencia/:slug",
    meta: _91slug_934K2BktOLBzMeta || {},
    alias: _91slug_934K2BktOLBzMeta?.alias || [],
    redirect: _91slug_934K2BktOLBzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934K2BktOLBzMeta?.name ?? "reference-slug___de",
    path: _91slug_934K2BktOLBzMeta?.path ?? "/de/referenz/:slug",
    meta: _91slug_934K2BktOLBzMeta || {},
    alias: _91slug_934K2BktOLBzMeta?.alias || [],
    redirect: _91slug_934K2BktOLBzMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue").then(m => m.default || m)
  },
  {
    name: registerd0HqpGimOyMeta?.name ?? "register___cs",
    path: registerd0HqpGimOyMeta?.path ?? "/registrace",
    meta: registerd0HqpGimOyMeta || {},
    alias: registerd0HqpGimOyMeta?.alias || [],
    redirect: registerd0HqpGimOyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerd0HqpGimOyMeta?.name ?? "register___en",
    path: registerd0HqpGimOyMeta?.path ?? "/en/registration",
    meta: registerd0HqpGimOyMeta || {},
    alias: registerd0HqpGimOyMeta?.alias || [],
    redirect: registerd0HqpGimOyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerd0HqpGimOyMeta?.name ?? "register___pl",
    path: registerd0HqpGimOyMeta?.path ?? "/pl/rejestracja",
    meta: registerd0HqpGimOyMeta || {},
    alias: registerd0HqpGimOyMeta?.alias || [],
    redirect: registerd0HqpGimOyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerd0HqpGimOyMeta?.name ?? "register___sk",
    path: registerd0HqpGimOyMeta?.path ?? "/sk/registracia",
    meta: registerd0HqpGimOyMeta || {},
    alias: registerd0HqpGimOyMeta?.alias || [],
    redirect: registerd0HqpGimOyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerd0HqpGimOyMeta?.name ?? "register___de",
    path: registerd0HqpGimOyMeta?.path ?? "/de/anmeldung",
    meta: registerd0HqpGimOyMeta || {},
    alias: registerd0HqpGimOyMeta?.alias || [],
    redirect: registerd0HqpGimOyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934MUTTUtmLDMeta?.name ?? "requests-slug___cs",
    path: _91slug_934MUTTUtmLDMeta?.path ?? "/poptavky/:slug",
    meta: _91slug_934MUTTUtmLDMeta || {},
    alias: _91slug_934MUTTUtmLDMeta?.alias || [],
    redirect: _91slug_934MUTTUtmLDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934MUTTUtmLDMeta?.name ?? "requests-slug___en",
    path: _91slug_934MUTTUtmLDMeta?.path ?? "/en/requests/:slug",
    meta: _91slug_934MUTTUtmLDMeta || {},
    alias: _91slug_934MUTTUtmLDMeta?.alias || [],
    redirect: _91slug_934MUTTUtmLDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934MUTTUtmLDMeta?.name ?? "requests-slug___pl",
    path: _91slug_934MUTTUtmLDMeta?.path ?? "/pl/popyt/:slug",
    meta: _91slug_934MUTTUtmLDMeta || {},
    alias: _91slug_934MUTTUtmLDMeta?.alias || [],
    redirect: _91slug_934MUTTUtmLDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934MUTTUtmLDMeta?.name ?? "requests-slug___sk",
    path: _91slug_934MUTTUtmLDMeta?.path ?? "/sk/dopyty/:slug",
    meta: _91slug_934MUTTUtmLDMeta || {},
    alias: _91slug_934MUTTUtmLDMeta?.alias || [],
    redirect: _91slug_934MUTTUtmLDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934MUTTUtmLDMeta?.name ?? "requests-slug___de",
    path: _91slug_934MUTTUtmLDMeta?.path ?? "/de/nachfragen/:slug",
    meta: _91slug_934MUTTUtmLDMeta || {},
    alias: _91slug_934MUTTUtmLDMeta?.alias || [],
    redirect: _91slug_934MUTTUtmLDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlKgoQvJ2htMeta?.name ?? "requests___cs",
    path: indexlKgoQvJ2htMeta?.path ?? "/poptavky",
    meta: indexlKgoQvJ2htMeta || {},
    alias: indexlKgoQvJ2htMeta?.alias || [],
    redirect: indexlKgoQvJ2htMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexlKgoQvJ2htMeta?.name ?? "requests___en",
    path: indexlKgoQvJ2htMeta?.path ?? "/en/requests",
    meta: indexlKgoQvJ2htMeta || {},
    alias: indexlKgoQvJ2htMeta?.alias || [],
    redirect: indexlKgoQvJ2htMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexlKgoQvJ2htMeta?.name ?? "requests___pl",
    path: indexlKgoQvJ2htMeta?.path ?? "/pl/popyt",
    meta: indexlKgoQvJ2htMeta || {},
    alias: indexlKgoQvJ2htMeta?.alias || [],
    redirect: indexlKgoQvJ2htMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexlKgoQvJ2htMeta?.name ?? "requests___sk",
    path: indexlKgoQvJ2htMeta?.path ?? "/sk/dopyty",
    meta: indexlKgoQvJ2htMeta || {},
    alias: indexlKgoQvJ2htMeta?.alias || [],
    redirect: indexlKgoQvJ2htMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexlKgoQvJ2htMeta?.name ?? "requests___de",
    path: indexlKgoQvJ2htMeta?.path ?? "/de/nachfragen",
    meta: indexlKgoQvJ2htMeta || {},
    alias: indexlKgoQvJ2htMeta?.alias || [],
    redirect: indexlKgoQvJ2htMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yeuyM22kF1Meta?.name ?? "reset-password-hash___cs",
    path: _91hash_93yeuyM22kF1Meta?.path ?? "/reset-hesla/:hash",
    meta: _91hash_93yeuyM22kF1Meta || {},
    alias: _91hash_93yeuyM22kF1Meta?.alias || [],
    redirect: _91hash_93yeuyM22kF1Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yeuyM22kF1Meta?.name ?? "reset-password-hash___en",
    path: _91hash_93yeuyM22kF1Meta?.path ?? "/en/reset-password/:hash",
    meta: _91hash_93yeuyM22kF1Meta || {},
    alias: _91hash_93yeuyM22kF1Meta?.alias || [],
    redirect: _91hash_93yeuyM22kF1Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yeuyM22kF1Meta?.name ?? "reset-password-hash___pl",
    path: _91hash_93yeuyM22kF1Meta?.path ?? "/pl/reset-hasla/:hash",
    meta: _91hash_93yeuyM22kF1Meta || {},
    alias: _91hash_93yeuyM22kF1Meta?.alias || [],
    redirect: _91hash_93yeuyM22kF1Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yeuyM22kF1Meta?.name ?? "reset-password-hash___sk",
    path: _91hash_93yeuyM22kF1Meta?.path ?? "/sk/reset-hesla/:hash",
    meta: _91hash_93yeuyM22kF1Meta || {},
    alias: _91hash_93yeuyM22kF1Meta?.alias || [],
    redirect: _91hash_93yeuyM22kF1Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yeuyM22kF1Meta?.name ?? "reset-password-hash___de",
    path: _91hash_93yeuyM22kF1Meta?.path ?? "/de/passwort-reset/:hash",
    meta: _91hash_93yeuyM22kF1Meta || {},
    alias: _91hash_93yeuyM22kF1Meta?.alias || [],
    redirect: _91hash_93yeuyM22kF1Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue").then(m => m.default || m)
  },
  {
    name: rulesn3XwR57bcDMeta?.name ?? "rules___cs",
    path: rulesn3XwR57bcDMeta?.path ?? "/pravidla",
    meta: rulesn3XwR57bcDMeta || {},
    alias: rulesn3XwR57bcDMeta?.alias || [],
    redirect: rulesn3XwR57bcDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesn3XwR57bcDMeta?.name ?? "rules___en",
    path: rulesn3XwR57bcDMeta?.path ?? "/en/rules",
    meta: rulesn3XwR57bcDMeta || {},
    alias: rulesn3XwR57bcDMeta?.alias || [],
    redirect: rulesn3XwR57bcDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesn3XwR57bcDMeta?.name ?? "rules___pl",
    path: rulesn3XwR57bcDMeta?.path ?? "/pl/zasady",
    meta: rulesn3XwR57bcDMeta || {},
    alias: rulesn3XwR57bcDMeta?.alias || [],
    redirect: rulesn3XwR57bcDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesn3XwR57bcDMeta?.name ?? "rules___sk",
    path: rulesn3XwR57bcDMeta?.path ?? "/sk/pravidla",
    meta: rulesn3XwR57bcDMeta || {},
    alias: rulesn3XwR57bcDMeta?.alias || [],
    redirect: rulesn3XwR57bcDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesn3XwR57bcDMeta?.name ?? "rules___de",
    path: rulesn3XwR57bcDMeta?.path ?? "/de/nutzungsregeln",
    meta: rulesn3XwR57bcDMeta || {},
    alias: rulesn3XwR57bcDMeta?.alias || [],
    redirect: rulesn3XwR57bcDMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93N8O540GIJyMeta?.name ?? "sales-cart_id___cs",
    path: _91cart_id_93N8O540GIJyMeta?.path ?? "/prodeje/:cart_id",
    meta: _91cart_id_93N8O540GIJyMeta || {},
    alias: _91cart_id_93N8O540GIJyMeta?.alias || [],
    redirect: _91cart_id_93N8O540GIJyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93N8O540GIJyMeta?.name ?? "sales-cart_id___en",
    path: _91cart_id_93N8O540GIJyMeta?.path ?? "/en/sales/:cart_id",
    meta: _91cart_id_93N8O540GIJyMeta || {},
    alias: _91cart_id_93N8O540GIJyMeta?.alias || [],
    redirect: _91cart_id_93N8O540GIJyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93N8O540GIJyMeta?.name ?? "sales-cart_id___pl",
    path: _91cart_id_93N8O540GIJyMeta?.path ?? "/pl/sprzedaze/:cart_id",
    meta: _91cart_id_93N8O540GIJyMeta || {},
    alias: _91cart_id_93N8O540GIJyMeta?.alias || [],
    redirect: _91cart_id_93N8O540GIJyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93N8O540GIJyMeta?.name ?? "sales-cart_id___sk",
    path: _91cart_id_93N8O540GIJyMeta?.path ?? "/sk/predaje/:cart_id",
    meta: _91cart_id_93N8O540GIJyMeta || {},
    alias: _91cart_id_93N8O540GIJyMeta?.alias || [],
    redirect: _91cart_id_93N8O540GIJyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93N8O540GIJyMeta?.name ?? "sales-cart_id___de",
    path: _91cart_id_93N8O540GIJyMeta?.path ?? "/de/verkaufe/:cart_id",
    meta: _91cart_id_93N8O540GIJyMeta || {},
    alias: _91cart_id_93N8O540GIJyMeta?.alias || [],
    redirect: _91cart_id_93N8O540GIJyMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: indexs4hR3DrB06Meta?.name ?? "sales___cs",
    path: indexs4hR3DrB06Meta?.path ?? "/prodeje",
    meta: indexs4hR3DrB06Meta || {},
    alias: indexs4hR3DrB06Meta?.alias || [],
    redirect: indexs4hR3DrB06Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexs4hR3DrB06Meta?.name ?? "sales___en",
    path: indexs4hR3DrB06Meta?.path ?? "/en/sales",
    meta: indexs4hR3DrB06Meta || {},
    alias: indexs4hR3DrB06Meta?.alias || [],
    redirect: indexs4hR3DrB06Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexs4hR3DrB06Meta?.name ?? "sales___pl",
    path: indexs4hR3DrB06Meta?.path ?? "/pl/sprzedaze",
    meta: indexs4hR3DrB06Meta || {},
    alias: indexs4hR3DrB06Meta?.alias || [],
    redirect: indexs4hR3DrB06Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexs4hR3DrB06Meta?.name ?? "sales___sk",
    path: indexs4hR3DrB06Meta?.path ?? "/sk/predaje",
    meta: indexs4hR3DrB06Meta || {},
    alias: indexs4hR3DrB06Meta?.alias || [],
    redirect: indexs4hR3DrB06Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexs4hR3DrB06Meta?.name ?? "sales___de",
    path: indexs4hR3DrB06Meta?.path ?? "/de/verkaufe",
    meta: indexs4hR3DrB06Meta || {},
    alias: indexs4hR3DrB06Meta?.alias || [],
    redirect: indexs4hR3DrB06Meta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsIyjKSVvqbXMeta?.name ?? "settings-notifications___cs",
    path: notificationsIyjKSVvqbXMeta?.path ?? "/nastaveni/notifikace",
    meta: notificationsIyjKSVvqbXMeta || {},
    alias: notificationsIyjKSVvqbXMeta?.alias || [],
    redirect: notificationsIyjKSVvqbXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsIyjKSVvqbXMeta?.name ?? "settings-notifications___en",
    path: notificationsIyjKSVvqbXMeta?.path ?? "/en/settings/notifications",
    meta: notificationsIyjKSVvqbXMeta || {},
    alias: notificationsIyjKSVvqbXMeta?.alias || [],
    redirect: notificationsIyjKSVvqbXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsIyjKSVvqbXMeta?.name ?? "settings-notifications___pl",
    path: notificationsIyjKSVvqbXMeta?.path ?? "/pl/ustawienia/powiadomien",
    meta: notificationsIyjKSVvqbXMeta || {},
    alias: notificationsIyjKSVvqbXMeta?.alias || [],
    redirect: notificationsIyjKSVvqbXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsIyjKSVvqbXMeta?.name ?? "settings-notifications___sk",
    path: notificationsIyjKSVvqbXMeta?.path ?? "/sk/nastavenie/notifikacie",
    meta: notificationsIyjKSVvqbXMeta || {},
    alias: notificationsIyjKSVvqbXMeta?.alias || [],
    redirect: notificationsIyjKSVvqbXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsIyjKSVvqbXMeta?.name ?? "settings-notifications___de",
    path: notificationsIyjKSVvqbXMeta?.path ?? "/de/einstellungen/benachrichtigung",
    meta: notificationsIyjKSVvqbXMeta || {},
    alias: notificationsIyjKSVvqbXMeta?.alias || [],
    redirect: notificationsIyjKSVvqbXMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91supplier_id_93xbSn9EYT8wMeta?.name ?? "supplier-supplier_id___cs",
    path: _91supplier_id_93xbSn9EYT8wMeta?.path ?? "/prodejce/:supplier_id",
    meta: _91supplier_id_93xbSn9EYT8wMeta || {},
    alias: _91supplier_id_93xbSn9EYT8wMeta?.alias || [],
    redirect: _91supplier_id_93xbSn9EYT8wMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue").then(m => m.default || m)
  },
  {
    name: _91supplier_id_93xbSn9EYT8wMeta?.name ?? "supplier-supplier_id___en",
    path: _91supplier_id_93xbSn9EYT8wMeta?.path ?? "/en/supplier/:supplier_id",
    meta: _91supplier_id_93xbSn9EYT8wMeta || {},
    alias: _91supplier_id_93xbSn9EYT8wMeta?.alias || [],
    redirect: _91supplier_id_93xbSn9EYT8wMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue").then(m => m.default || m)
  },
  {
    name: _91supplier_id_93xbSn9EYT8wMeta?.name ?? "supplier-supplier_id___pl",
    path: _91supplier_id_93xbSn9EYT8wMeta?.path ?? "/pl/sprzedawca/:supplier_id",
    meta: _91supplier_id_93xbSn9EYT8wMeta || {},
    alias: _91supplier_id_93xbSn9EYT8wMeta?.alias || [],
    redirect: _91supplier_id_93xbSn9EYT8wMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue").then(m => m.default || m)
  },
  {
    name: _91supplier_id_93xbSn9EYT8wMeta?.name ?? "supplier-supplier_id___sk",
    path: _91supplier_id_93xbSn9EYT8wMeta?.path ?? "/sk/predajca/:supplier_id",
    meta: _91supplier_id_93xbSn9EYT8wMeta || {},
    alias: _91supplier_id_93xbSn9EYT8wMeta?.alias || [],
    redirect: _91supplier_id_93xbSn9EYT8wMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue").then(m => m.default || m)
  },
  {
    name: _91supplier_id_93xbSn9EYT8wMeta?.name ?? "supplier-supplier_id___de",
    path: _91supplier_id_93xbSn9EYT8wMeta?.path ?? "/de/anbieter/:supplier_id",
    meta: _91supplier_id_93xbSn9EYT8wMeta || {},
    alias: _91supplier_id_93xbSn9EYT8wMeta?.alias || [],
    redirect: _91supplier_id_93xbSn9EYT8wMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue").then(m => m.default || m)
  },
  {
    name: terms7cdYR5pWxkMeta?.name ?? "terms___cs",
    path: terms7cdYR5pWxkMeta?.path ?? "/podminky",
    meta: terms7cdYR5pWxkMeta || {},
    alias: terms7cdYR5pWxkMeta?.alias || [],
    redirect: terms7cdYR5pWxkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms7cdYR5pWxkMeta?.name ?? "terms___en",
    path: terms7cdYR5pWxkMeta?.path ?? "/en/terms",
    meta: terms7cdYR5pWxkMeta || {},
    alias: terms7cdYR5pWxkMeta?.alias || [],
    redirect: terms7cdYR5pWxkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms7cdYR5pWxkMeta?.name ?? "terms___pl",
    path: terms7cdYR5pWxkMeta?.path ?? "/pl/warunki",
    meta: terms7cdYR5pWxkMeta || {},
    alias: terms7cdYR5pWxkMeta?.alias || [],
    redirect: terms7cdYR5pWxkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms7cdYR5pWxkMeta?.name ?? "terms___sk",
    path: terms7cdYR5pWxkMeta?.path ?? "/sk/podmienky",
    meta: terms7cdYR5pWxkMeta || {},
    alias: terms7cdYR5pWxkMeta?.alias || [],
    redirect: terms7cdYR5pWxkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms7cdYR5pWxkMeta?.name ?? "terms___de",
    path: terms7cdYR5pWxkMeta?.path ?? "/de/bedingungen",
    meta: terms7cdYR5pWxkMeta || {},
    alias: terms7cdYR5pWxkMeta?.alias || [],
    redirect: terms7cdYR5pWxkMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93yUpHDoC1nvMeta?.name ?? "unfinished-cart_id___cs",
    path: _91cart_id_93yUpHDoC1nvMeta?.path ?? "/cekajici/:cart_id",
    meta: _91cart_id_93yUpHDoC1nvMeta || {},
    alias: _91cart_id_93yUpHDoC1nvMeta?.alias || [],
    redirect: _91cart_id_93yUpHDoC1nvMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93yUpHDoC1nvMeta?.name ?? "unfinished-cart_id___en",
    path: _91cart_id_93yUpHDoC1nvMeta?.path ?? "/en/unfinished/:cart_id",
    meta: _91cart_id_93yUpHDoC1nvMeta || {},
    alias: _91cart_id_93yUpHDoC1nvMeta?.alias || [],
    redirect: _91cart_id_93yUpHDoC1nvMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93yUpHDoC1nvMeta?.name ?? "unfinished-cart_id___pl",
    path: _91cart_id_93yUpHDoC1nvMeta?.path ?? "/pl/czekajac/:cart_id",
    meta: _91cart_id_93yUpHDoC1nvMeta || {},
    alias: _91cart_id_93yUpHDoC1nvMeta?.alias || [],
    redirect: _91cart_id_93yUpHDoC1nvMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93yUpHDoC1nvMeta?.name ?? "unfinished-cart_id___sk",
    path: _91cart_id_93yUpHDoC1nvMeta?.path ?? "/sk/cakajuci/:cart_id",
    meta: _91cart_id_93yUpHDoC1nvMeta || {},
    alias: _91cart_id_93yUpHDoC1nvMeta?.alias || [],
    redirect: _91cart_id_93yUpHDoC1nvMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: _91cart_id_93yUpHDoC1nvMeta?.name ?? "unfinished-cart_id___de",
    path: _91cart_id_93yUpHDoC1nvMeta?.path ?? "/de/warten/:cart_id",
    meta: _91cart_id_93yUpHDoC1nvMeta || {},
    alias: _91cart_id_93yUpHDoC1nvMeta?.alias || [],
    redirect: _91cart_id_93yUpHDoC1nvMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: indexAbxjC6ntdJMeta?.name ?? "unfinished___cs",
    path: indexAbxjC6ntdJMeta?.path ?? "/cekajici",
    meta: indexAbxjC6ntdJMeta || {},
    alias: indexAbxjC6ntdJMeta?.alias || [],
    redirect: indexAbxjC6ntdJMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue").then(m => m.default || m)
  },
  {
    name: indexAbxjC6ntdJMeta?.name ?? "unfinished___en",
    path: indexAbxjC6ntdJMeta?.path ?? "/en/unfinished",
    meta: indexAbxjC6ntdJMeta || {},
    alias: indexAbxjC6ntdJMeta?.alias || [],
    redirect: indexAbxjC6ntdJMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue").then(m => m.default || m)
  },
  {
    name: indexAbxjC6ntdJMeta?.name ?? "unfinished___pl",
    path: indexAbxjC6ntdJMeta?.path ?? "/pl/czekajac",
    meta: indexAbxjC6ntdJMeta || {},
    alias: indexAbxjC6ntdJMeta?.alias || [],
    redirect: indexAbxjC6ntdJMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue").then(m => m.default || m)
  },
  {
    name: indexAbxjC6ntdJMeta?.name ?? "unfinished___sk",
    path: indexAbxjC6ntdJMeta?.path ?? "/sk/cakajuce",
    meta: indexAbxjC6ntdJMeta || {},
    alias: indexAbxjC6ntdJMeta?.alias || [],
    redirect: indexAbxjC6ntdJMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue").then(m => m.default || m)
  },
  {
    name: indexAbxjC6ntdJMeta?.name ?? "unfinished___de",
    path: indexAbxjC6ntdJMeta?.path ?? "/de/unfertig",
    meta: indexAbxjC6ntdJMeta || {},
    alias: indexAbxjC6ntdJMeta?.alias || [],
    redirect: indexAbxjC6ntdJMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue").then(m => m.default || m)
  },
  {
    name: why_45with_45usspdMZjGNdNMeta?.name ?? "why-with-us___cs",
    path: why_45with_45usspdMZjGNdNMeta?.path ?? "/proc-inzerovat-u-nas",
    meta: why_45with_45usspdMZjGNdNMeta || {},
    alias: why_45with_45usspdMZjGNdNMeta?.alias || [],
    redirect: why_45with_45usspdMZjGNdNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue").then(m => m.default || m)
  },
  {
    name: why_45with_45usspdMZjGNdNMeta?.name ?? "why-with-us___en",
    path: why_45with_45usspdMZjGNdNMeta?.path ?? "/en/why-advertise-with-us",
    meta: why_45with_45usspdMZjGNdNMeta || {},
    alias: why_45with_45usspdMZjGNdNMeta?.alias || [],
    redirect: why_45with_45usspdMZjGNdNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue").then(m => m.default || m)
  },
  {
    name: why_45with_45usspdMZjGNdNMeta?.name ?? "why-with-us___pl",
    path: why_45with_45usspdMZjGNdNMeta?.path ?? "/pl/dlaczego-warto-sie-u-nas-reklamowac",
    meta: why_45with_45usspdMZjGNdNMeta || {},
    alias: why_45with_45usspdMZjGNdNMeta?.alias || [],
    redirect: why_45with_45usspdMZjGNdNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue").then(m => m.default || m)
  },
  {
    name: why_45with_45usspdMZjGNdNMeta?.name ?? "why-with-us___sk",
    path: why_45with_45usspdMZjGNdNMeta?.path ?? "/sk/preco-inzerovat-u-nas",
    meta: why_45with_45usspdMZjGNdNMeta || {},
    alias: why_45with_45usspdMZjGNdNMeta?.alias || [],
    redirect: why_45with_45usspdMZjGNdNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue").then(m => m.default || m)
  },
  {
    name: why_45with_45usspdMZjGNdNMeta?.name ?? "why-with-us___de",
    path: why_45with_45usspdMZjGNdNMeta?.path ?? "/de/warum-bei-uns-werben",
    meta: why_45with_45usspdMZjGNdNMeta || {},
    alias: why_45with_45usspdMZjGNdNMeta?.alias || [],
    redirect: why_45with_45usspdMZjGNdNMeta?.redirect || undefined,
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue").then(m => m.default || m)
  }
]