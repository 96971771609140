import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (id) {
  try {
    const config = useRuntimeConfig();
    const url = `${config.public.appBaseUrl}${API_ENDPOINTS.CUSTOMER_FOLLOWINGS_ID.replace('{id}', id)}`;
    await $fetch(url, {
      method: 'DELETE'
    });

    return true;
  } catch (error) {
    Sentry.captureException(error);
  }

  return false;
}
