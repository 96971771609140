
<template>
  <div class="w-full">
    <h2 class="text-base text-primary-dark font-bold pb-3">
      {{ $t('text-questions-and-comments') }}
    </h2>
    <ChatMessages 
      :parent="variant" 
      type="variant" 
      :fetch-url="fetchUrl"
      loadError="error.product-detail.load.comments"
      postError="error.product-detail.add.comment"
      placeholder="text-ask-or-add-comment"
      :is-add-blocked="isAddBlocked"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCustomerStore } from '@stores/customer';
import ChatMessages from "@components/chat/chat-messages";

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  }
});

const config = useRuntimeConfig();
const customer = useCustomerStore();

const fetchUrl = computed(() => config?.public?.appBaseUrl + API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_ID_MESSAGE.replace('{idVariant}', props.variant.id));

const isAddBlocked = computed(() => {
  return !!_.chain(customer)
    .get('supplier.blockedBy', [])
    .filter({ id: props?.variant?.product?.supplier?.id })
    .value()
    .length;
});

</script>
