<template>
  <div class="w-full pt-4 border-t border-primary-neutral">
    <button
      :class="[ 'flex items-center w-full text-start outline-none focus:outline-none focus:ring-0 focus:text-primary-dark-accent hover:no-underline text-primary-dark text-base font-bold' ]"
    >
      <div class="flex justify-between items-center w-full cursor-pointer" @click="toggleCollapse">
        <div>
          {{ $t('text-distance-from-location') }}
        </div>
        <div class="ms-auto text-primary-dark">
          <MinusIcon v-if="isOpen" class="w-6 h-6" />
          <PlusIcon v-else class="w-6 h-6" />
        </div>
      </div>
    </button>
    <div v-if="isOpen" class="mt-2">
      <p v-if="error" class="my-2 text-xs text-primary-dark">
          {{ error }}
      </p>
      <div class="flex relative text-sm h-10 mt-2">
        <input
          id="city"
          ref="inputRef"
          :placeholder="$t('text-distance-name-or-zip')"
          :class="[ 'w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover hide-arrows px-5', modelValue ? 'placeholder:text-heading' : 'placeholder:text-primary-light' ]"
          @change="handleChange"
          @keyup.enter="handleChange"
        />
        <button
          v-if="!modelValue"
          type="button"
          @click="handleGetCoordinates"
          class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
          :title="$t('text-allow-geolocation-here')"
        >
          <MapPinIcon class="w-4 h-4" />
        </button>
        <button
          v-else
          type="button"
          @click="handleClear"
          class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
        >
          <span class="sr-only">{{ $t('text-close') }}</span>
          <CloseIcon class="w-3.5 h-3.5 md:w-3 md:h-3" />
        </button>
        <slot />
      </div>
      <Selectbox
        v-model="distance"
        :options="distances"
        name="distance"
        variant="outline"
        dimension="tiny"
        class-name="w-full flex item-center appearance-none transition duration-300 ease-in-out text-xs font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover mt-2"
        :input-class-name="modelValue && (distance === '0' || distance === 0) ? 'text-primary-light' : 'text-heading'"
      />
      <Selectbox
        v-if="showCountry"
        v-model="countryId"
        :options="countries"
        name="country"
        variant="outline"
        dimension="tiny"
        class-name="w-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs placeholder:text-primary-dark font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover mt-2"
      />
    </div>
    <div ref="mapRef" class="hidden" /> 
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { useEnumStore } from '@stores/enum';
import MapPinIcon from "@components/icons/map-pin-icon";
import CloseIcon from "@components/icons/close-icon";
import PlusIcon from "@components/icons/plus-icon";
import MinusIcon from "@components/icons/minus-icon";
import Selectbox from "@components/ui/selectbox";

const props = defineProps({
  showCountry: {
    type: Boolean,
    default: true
  }
});

const config = useRuntimeConfig();
const cookie = useCookie('i18n_redirected');
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const ui = useUIStore();
const store = useSelectedStore();
const customer = useCustomerStore();
const enums = useEnumStore();
const geolocation = useGeolocation();
const route = useRoute();
const router = useRouter();
const name = computed(() => store.hasCurrentLocation() ? 'maxKmFromLocation' : 'maxKmFromHome');
const modelValue = ref(_.get(route.query, 'location', ''));
const countryId = ref(null);
const distance = ref(+_.get(route.query, 'maxKmFromHome', _.get(route.query, 'maxKmFromLocation', 0)));
const error = ref(t('text-distance-name-or-zip-note'));

const inputRef = ref(null);
const mapRef = ref(null);
const map = ref(null);
const service = ref(null);
const autocomplete = ref(null);

const isOpen = ref(true);
const distances = ref([
  { id: 0, name: t('text-distance-select') },
  { id: 5, name: t('text-distance-near-km', { number: 5 }) },
  { id: 10, name: t('text-distance-near-km', { number: 10 }) },
  { id: 20, name: t('text-distance-near-km', { number: 20 }) },
  { id: 30, name: t('text-distance-near-km', { number: 30 }) },
  { id: 50, name: t('text-distance-near-km', { number: 50 }) },
  { id: 70, name: t('text-distance-near-km', { number: 70 }) },
  { id: 100, name: t('text-distance-near-km', { number: 100 }) },
  { id: 120, name: t('text-distance-near-km', { number: 120 }) },
  { id: 150, name: t('text-distance-near-km', { number: 150 }) },
]);

if (!_.find(distances.value, { id: distance.value })) {
  distances.value.push({ id: distance.value, name: t('text-distance-near-km', { number: distance.value }) });
  distances.value = _.sortBy(distances.value, ['id']);
}

const countries = computed(() => _.concat([{ id: null, name: '', code: '' }], _.filter(enums?.countries || [], item => _.includes(['CZ', 'SK', 'PL', 'DE'], item.code))));

const setupCountry = (values) => {
  if (countryId.value === null && customer?.language?.code) {
    const code = { cs: 'cz', en: 'gb' }[customer.language.code] || customer.language.code;
    countryId.value = _.chain(values)
      .filter({ code: _.toUpper(code) })
      .first()
      .get('id')
      .value();
  }
}

const country = computed(() => _.chain(enums.countries).filter({ id: countryId.value }).first().value() || { id: 0, code: '', name: '' });

watch(() => enums.countries, (values) => {
  setupCountry(values);
});

watch(() => route.query, (query) => {
  distance.value = +_.get(query, 'maxKmFromHome', _.get(query, 'maxKmFromLocation', 0));
  modelValue.value = _.get(query, 'location', '');
});

watch(() => distance.value, (value) => {
  if (value === 0 || value === '0') {
    handleClear();
  } else {
    if (ui.displaySidebar) {
      closeSidebar();
    }
    updateQueryParams();
  }
});

watch(() => countryId.value, (value) => {
  if (autocomplete.value && enums?.countries && enums?.countries?.length) {
    const code = _.chain(enums.countries).filter({ id: parseInt(value) }).first().get('code').toLower().value();
    autocomplete.value.setComponentRestrictions({country: code ? code : [] });
  }
});

watch(() => modelValue.value, (value) => {
  inputRef.value.value = value;
  onSubmit(+distance.value, value);
});

const toggleCollapse = () => {
  isOpen.value = !isOpen.value;
}

const closeSidebar = () => { 
  $eventBus.emit('sidebar:close');
};

const routeName = computed(() => route?.name || '');

const updateQuery = (maxKmFromHome, maxKmFromLocation, location) => {
  const params = { ...route.query };
  if (maxKmFromHome) {
    params.maxKmFromHome = maxKmFromHome;
  } else {
    delete params['maxKmFromHome'];
  }
  if (maxKmFromLocation) {
    params.maxKmFromLocation = maxKmFromLocation;
  } else {
    delete params['maxKmFromLocation'];
  }
  if (location) {
    params.location = location;
  } else {
    delete params['location'];
  }
  if (geolocation.coordinates.value.latitude && (maxKmFromHome || maxKmFromLocation || location)) {
    params.latitude = geolocation.coordinates.value.latitude;
  } else {
    delete params['latitude'];
  }
  if (geolocation.coordinates.value.longitude && (maxKmFromHome || maxKmFromLocation || location)) {
    params.longitude = geolocation.coordinates.value.longitude;
  } else {
    delete params['longitude'];
  }
  if (routeName.value.startsWith('classified-ads___') || 
    routeName.value.startsWith('category-slug___') ||
    routeName.value.startsWith('offers___') || 
    routeName.value.startsWith('offers-slug___') ||
    routeName.value.startsWith('requests___') ||
    routeName.value.startsWith('requests-slug___')
  ) {
    router.push({ path: routeName.value.startsWith('classified-ads___') || 
      routeName.value.startsWith('category-slug___') ||
      routeName.value.startsWith('offers___') || 
      routeName.value.startsWith('offers-slug___') ||
      routeName.value.startsWith('requests___') ||
      routeName.value.startsWith('requests-slug___') ? route.path : translatePath(ROUTES.ADS), query: params });
  }
}

const onSubmit = (value, location) => {
  store.setLoadResetOthers();
  if (value && !customer.hasHomeLocation() && !store.hasCurrentLocation()) {
    $toast.error(t('error.filter.maxKm.check'));
    updateQuery(null, null, '');
  } else if (name.value === 'maxKmFromHome') {
    if (value && !customer.hasHomeLocation()) {
      $toast.error(t('error.filter.maxKmFromHome.check'));
    } else {
      updateQuery(value, null, location);
    }
  } else if (name.value === 'maxKmFromLocation') {
    if (value && !store.hasCurrentLocation()) {
      $toast.error(t('error.filter.maxKmFromLocation.check'));
    } else {
      updateQuery(null, value, location);
    }
  } else {
    updateQuery(null, null, location);
  }
}

const handleGetCoordinates = async () => {
  geolocation.withLocation(async () => {
    error.value = ' ';
    if (store.hasCurrentLocation()) {
      $toast.success(t('success.geolocation.accepted'));
    } else {
      $toast.error(t('error.geolocation.denied'));
    }
  });
};

const updateQueryParams = () => onSubmit(+distance.value, modelValue.value || inputRef.value.value);

const handleChange = (event) => _.delay(() => {
  if (modelValue.value !== inputRef.value.value || (modelValue.value === '' && inputRef.value.value === '')) {
    error.value = t('text-distance-name-or-zip-error');
    inputRef.value.value = '';
  }
}, 200);

const handleSubmit = (event) => {
  if (ui.displaySidebar) {
    closeSidebar();
  }
  updateQueryParams();
};

const handleClear = (event) => {
  modelValue.value = null;
  inputRef.value.value = null;
  geolocation.coordinates.value.latitude = null;
  geolocation.coordinates.value.longitude = null;
  store.setCoordinates(geolocation.coordinates.value);
  distance.value = 0;
  if (ui.displaySidebar) {
    closeSidebar();
  }
  updateQueryParams();
  error.value = t('text-distance-name-or-zip-note');
}

const updateByGeolocationPlace = () => {
  modelValue.value = geolocation.place.value?.formatted_address || '';
  if (geolocation.place.value?.geometry?.location) {
    geolocation.coordinates.value.latitude = geolocation.place.value.geometry.location.latitude = geolocation.place.value.geometry.location.lat();
    geolocation.coordinates.value.longitude = geolocation.place.value.geometry.location.longitude = geolocation.place.value.geometry.location.lng();
  }
}

const initAutocomplete = () => {
  if (!window.google) return;

  map.value = map.value || new window.google.maps.Map(mapRef.value, { mapId: config.public.googleMapId });
  service.value = service.value || new window.google.maps.places.PlacesService(map.value);

  if (autocomplete.value) {
    autocomplete.value.unbindAll();
    autocomplete.value = null;
  }

  const input = inputRef.value;
  const options = {
    types: ['locality', 'postal_code'],
    language: customer?.language?.code || cookie.value || config.public.defaultLanguage
  };

  autocomplete.value = new window.google.maps.places.Autocomplete(input, options);

  autocomplete.value.addListener('place_changed', () => {
    error.value = ' ';
    geolocation.place.value = autocomplete.value.getPlace() || {};

    const request = {
      placeId: geolocation.place.value.place_id,
      language: customer?.language?.code || cookie.value || config.public.defaultLanguage
    };

    service.value.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        geolocation.place.value = place;
      }
      updateByGeolocationPlace();
    });

    updateByGeolocationPlace();
  });
}

const placeUpdate = (value) => {
  modelValue.value = geolocation.place.value?.formatted_address || geolocation.place.value?.formattedAddress || '';
};

onMounted(async () => {
  if (enums?.countries && enums?.countries?.length) {
    setupCountry(enums.countries);
  }
  if (process.client && window && window.google) {
    initAutocomplete();
  }
  if (process.client && modelValue.value) {
    inputRef.value.value = modelValue.value;
  }
  $eventBus.on('place:update', placeUpdate);
});

onUnmounted(() => {
  $eventBus.off('place:update', placeUpdate);
});

</script>

<style scoped>
/* Custom CSS to hide number input arrows */
.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-arrows {
  -moz-appearance: textfield; /* Firefox */
}
</style>
