<template>
    <SidebarWrapper>
      <div class="h-full max-h-full overflow-hidden">
        <Scrollbar class="w-full h-full max-h-screen">
          <div v-show="!categories.loaded" class="block xl:hidden">
            <div class="w-full mt-8 px-2">
              <CategoryLoader />
            </div>
          </div>
          <ErrorMessage v-show="!categories.loading && categories.error && categories.error?.message" :message="categories.error?.message" />
          <div v-show="!categories.loading && !categories.error && !categories.error?.message && (categories[type] || []).length" class="py-3 px-6">
            <SidebarMenu :type="type" :items="categories[type]" :show-my="true" class="mb-5" />
            <div v-if="type === CATEGORY_TYPE.ESHOP">
              <LocationFilter class="mb-5" />
              <FilterCategoryParameters />
            </div>
            <div v-else>
              <AddInspirationButton />
            </div>
          </div>
          <div v-show="!categories.loading && !categories.error && !categories.error?.message && !(categories[type] || []).length" class="min-h-full pt-6 pb-8 px-4 lg:p-8">
            <NotFound text="text-not-found" class="h-96" />
          </div>
        </Scrollbar>
      </div>
    </SidebarWrapper>
</template>

<script setup>
import { CATEGORY_TYPE } from "@utils/constants";
import { useCategoryStore } from '@stores/category';
import { useUIStore } from '@stores/ui';
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";
import CategoryLoader from "@components/ui/loaders/category-loader";
import ErrorMessage from "@components/ui/error-message";
import Scrollbar from "@components/ui/scrollbar";
import SidebarMenu from "@components/ui/sidebar-menu";
import NotFound from "@components/common/not-found";
import LocationFilter from "@components/filter/location-filter";
import FilterCategoryParameters from "@components/filter/filter-category-parameters";
import AddInspirationButton from "@components/content/add-inspiration-button";

const route = useRoute();
const categories = useCategoryStore();
const ui = useUIStore();

const routeName = computed(() => route?.name || '');

const type = computed(() => routeName.value.startsWith('inspiration___') || routeName.value.startsWith('inspiration-type-slug___') ? CATEGORY_TYPE.CONTENT : CATEGORY_TYPE.ESHOP);

</script>
