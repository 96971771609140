<template>
    <div class="flex flex-row items-center space-x-0.5 cursor-pointer" @click="toggle">
        <HeartSolid v-if="variant.isInCustomerLikes" :class="[ 'w-4 h-4', accent ? 'text-accent-dark hover:text-accent-dark-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]" />
        <Heart v-else :class="[ 'w-4 h-4', accent ? 'text-accent-dark hover:text-accent-dark-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]" />
        <div v-if="variant.likesCount" :class="[ 'text-xs font-normal', accent ? 'text-light hover:text-light-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]">
            {{ variant.likesCount }}
        </div>
        <div v-if="label" :class="[ 'text-xs font-normal pr-5', accent ? 'text-light hover:text-light-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]">
            {{ $t(label) }}
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import Heart from "@components/icons/outline/heart";
import HeartSolid from "@components/icons/solid/heart-solid";

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  accent: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const loading = ref(false);
const auth = useAuthStore();

const toggle = async () => {
  if (loading.value || !checkAuth()) {
      return;
  }
  loading.value = true;
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTENT_POST_ID_LIKE, {
    method: props.variant.isInCustomerLikes ? 'DELETE' : 'POST',
    path: {
      idPost: props.variant.id
    }
  });
  if (status.value === 'error') {
    if (process.client) {
      $toast.error(t('error.content-detail.likes'));
    }
    sentryCaptureEvent({
      message: 'content-likes.toggle',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    $eventBus.emit('content:update', { 
      id: props.variant.id,
      isInCustomerLikes: !props.variant.isInCustomerLikes,
      likesCount: props.variant.isInCustomerLikes ? props.variant.likesCount - 1 : props.variant.likesCount + 1
    });
  }
  loading.value = false;
}

</script>
