import { ROUTES } from "@utils/routes";
import FacebookIcon from "@components/icons/social/facebook-icon";
import XTwitterIcon from "@components/icons/social/x-twitter-icon";

export const siteSettings = {
  name: "Aquarist",
  description: "Vše pro vaše akvárium",
  logo: {
    url: "/logo_black.svg",
    alt: "Aquarist",
    href: "/",
    width: '110px',
    height: '52px',
  },
  language: {
    id: 1,
    code: "cs",
    locale: "cs_CZ",
    name: "Čeština",
  },
  currency: {
    code: "CZK",
    name: "Koruna česká",
    sign: "Kč",
    signPosition: "after",
    decimals: 2,
    decimalsSeparator: ",",
    thousandsSeparator: "",
    currencyRate: 1
  },
  product: {
    placeholderImage: "/aquarist-placeholder.svg",
    card: {
      tags: {
        parameters: [ 101, 201, 301, 1 ]
      }
    },
    edit: {
      product: {
        show: {
          category: false,
          status: false
        }
      },
      variant: {
        show: {
          add: false,
          header: false,
          category: true,
          vatRate: false
        },
        required: {
          price: false
        },
        placeholder: {
          name: 'text-ad-name-placeholder',
          content: 'text-ad-content-placeholder'
        }
      },
      fields: {
        show: {
          optional: false
        }
      }
    }
  },
  author: {
    name: "Aquarist",
    logo: {
      url: "/aquarist.svg"
    },
    websiteUrl: "https://www.foraquarist.com",
    address: "Rumunská 1798/1, 120 00 Praha - Nové Město, Česká republika",
    email: "info@foraquarist.com",
    phone: "+420 603 449 602",
    social: [
      {
        link: "https://www.facebook.com/profile.php?id=61556821824752",
        icon: "FacebookIcon",
        hoverClass: "text-social-facebook",
      }
    ],
  },
  shareLinks: [
    {
      href: "https://www.facebook.com/sharer/sharer.php?u={URL}",
      icon: FacebookIcon,
      label: "text-facebook"
    },
    {
      href: "https://twitter.com/intent/tweet?url={URL}&text={TEXT}",
      icon: XTwitterIcon,
      label: "text-x-twitter"
    }
  ],
  homeLinks: [
    { href: ROUTES.HOME, icon: null, label: "nav-home", minWidth: 1280, className: 'hidden lg:block' },
    { href: ROUTES.INSPIRATION, icon: null, label: "nav-menu-inspiration", minWidth: 1024, className: '' },
    { href: ROUTES.NEWS, icon: null, label: 'nav-menu-news', minWidth: 1280, className: 'hidden lg:block' },
    { href: ROUTES.HELP, icon: null, label: 'nav-menu-faq', minWidth: 1366, className: 'hidden xl:block' },
  ],
  headerLinks: [
    { href: ROUTES.HOME, icon: null, label: "nav-home", minWidth: 1024, className: 'hidden lg:block' },
    { href: ROUTES.OFFERS, icon: null, label: "nav-menu-offers", minWidth: 1280, className: 'hidden lg:block' },
    { href: ROUTES.REQUESTS, icon: null, label: 'nav-menu-requests', minWidth: 1536, className: 'hidden xl:block' },
    { href: ROUTES.NEWS, icon: null, label: 'nav-menu-news', minWidth: 1536, className: 'hidden xl:block' },
    { href: ROUTES.HELP, icon: null, label: 'nav-menu-faq', minWidth: 1280, className: 'hidden xl:block' },
  ],
  mobileMenuLinks: [
    { href: ROUTES.HOME, icon: null, label: "nav-home" },
    { href: ROUTES.OFFERS, icon: null, label: "nav-menu-offers" },
    { href: ROUTES.REQUESTS, icon: null, label: "nav-menu-requests" },
    { href: ROUTES.NEWS, icon: null, label: 'text-read-news' },
    { href: ROUTES.HELP, icon: null, label: "nav-menu-faq" },
    { href: ROUTES.INSPIRATION, icon: null, label: "nav-menu-inspiration" },
    { href: ROUTES.FAVORITES, icon: null, label: "nav-menu-my-favorites" },
  ],
  authorizedLinks: [
    // { href: ROUTES.WAITING, label: "auth-menu-waiting-orders", role: 'supplier' },
    { href: ROUTES.ADS_MY, label: "auth-menu-my-ads", role: 'supplier' },
    { href: ROUTES.SALES, label: "auth-menu-my-sales", role: 'supplier', badge: 'unreadSalesMessagesCount' },
    { href: ROUTES.ORDERS, label: "auth-menu-my-orders", role: 'supplier', badge: 'unreadPurchasesMessagesCount' },
    { href: ROUTES.PROFILE, label: "auth-menu-profile", role: 'customer' },
    { href: ROUTES.SETTINGS_NOTIFICATION, label: "auth-menu-settings-notifications" },
    { href: ROUTES.LOGOUT, label: "auth-menu-logout", role: 'customer' },
  ],
  dashboardSidebarMenu: [
    // { href: ROUTES.WAITING, label: "profile-sidebar-waiting-orders", role: 'supplier', routeNamePrefix: 'unfinished___' },
    { href: ROUTES.SALES, label: "profile-sidebar-my-sales", role: 'supplier', routeNamePrefix: 'sales___' },
    { href: ROUTES.ORDERS, label: "profile-sidebar-my-orders", role: 'supplier', routeNamePrefix: 'orders___' },
    { href: ROUTES.PROFILE, label: "profile-sidebar-profile", role: 'customer', routeNamePrefix: 'profile___' },
    { href: ROUTES.SETTINGS_NOTIFICATION, label: "profile-sidebar-settings-notifications", role: 'customer', routeNamePrefix: 'settings-notifications___' },
    { href: ROUTES.HELP, label: "profile-sidebar-help", routeNamePrefix: 'help___' },
    { href: ROUTES.CONTACT, label: "profile-sidebar-contact", routeNamePrefix: 'contact___' },
  ],
  categoryHomePanel: [
    { id: 101, icon: 'Fish', label: 'text-fish' },
    { id: 103, icon: 'Plants' },
    { id: 104, icon: 'Equipment' },
    { id: 106, icon: 'Other' },
  ],
  footerLinks: [
    { 
      label: 'text-ads',
      links: [
        { href: ROUTES.AD_ADD, label: 'text-add-ad' },
        { href: ROUTES.ADS, label: 'text-browse-ads' },
        { href: ROUTES.FAVORITES, label: 'text-favorite-ads' },
        { href: ROUTES.RULES, label: 'text-rules-of-use' },
      ]
    },
    { 
      label: 'text-could-interest-you',
      links: [
        { href: ROUTES.HELP, label: 'text-faq' },
        { href: ROUTES.WHY_WITH_US, label: 'text-why-submit-ad' },
        { href: ROUTES.TERMS, label: 'text-terms-and-conditions', sidebar: 'TERMS' },
        { href: ROUTES.PRIVACY, label: 'text-gdpr', sidebar: 'PRIVACY' },
      ]
    },
    { 
      label: 'text-for-aquarist',
      links: [
        { href: ROUTES.HOME, label: "text-homepage" },
        { href: ROUTES.INSPIRATION, label: "nav-menu-inspiration" },
        { href: ROUTES.NEWS, label: 'text-read-news' },
        { href: ROUTES.APP, label: 'text-mobile-app' },
      ]
    },
    { 
      label: 'text-contact',
      links: [
        { href: ROUTES.CONTACT, label: "text-contact-us" },
        { href: 'mailto:info@foraquarist.com', title: 'info@foraquarist.com', external: true },
        { href: 'tel:+420603449602', title: '+420 603 449 602', external: true },
        { href: 'https://www.facebook.com/profile.php?id=61556821824752', label: 'text-facebook', external: true },
      ]
    }
  ]
};
