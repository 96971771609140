import _ from 'lodash-es';
import { siteSettings } from "@settings/site.settings";
import { useCustomerStore } from '@stores/customer';

export default function (data, type, fallback = '') {
  const locale = useLocale().value;
  const customer = useCustomerStore();
  const languages = _.has(data, 'languages') ? data.languages : (data || []);

  let value = customer.language?.locale ? _.find(languages, { type, language: { locale: customer.language.locale } }) : null;
  if (value && value?.content) return value?.content;

  value = locale ? _.find(languages, { type, language: { locale } }) : null;
  if (value && value?.content) return value?.content;

  value = _.find(languages, { type, language: { locale: siteSettings.language.locale } });
  if (value && value?.content) return value?.content;

  value = type ? _.find(languages, { type }) : null;
  if (value && value?.content) return value?.content;

  const contents = _.has(data, 'contents') ? data.contents : (data || []);
  
  value = _.find(contents, { type });
  if (value && value?.content) return value?.content;

  return _.get(data, type, fallback);
}
