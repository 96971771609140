import { ROUTES } from "@utils/routes";

export default function (content) {
  const localePath = useLocalePath();

  const match = content.match(/:\s+\[([^\]]+)\]\(advert\#(\d+)\)$/);
  if (match) {
    return [
      { type: 'text', content: content.replace(match[0], ': ') },
      { type: 'link', content: match[1], href: translatePath(`${ROUTES.PRODUCT}/${slugify(match[2], match[1])}`) }
    ];
  }

  return [ { type: 'text', content } ];
}
