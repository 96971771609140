<template>

</template>

<script setup>
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();

const toastSuccess = (message) => {
  $toast.success(t(message));
};

const toastError = (message) => {
  $toast.error(t(message));
};

onMounted(async () => {
  $eventBus.on('toast:success', toastSuccess);
  $eventBus.on('toast:error', toastError);
});

onUnmounted(() => {
  $eventBus.off('toast:success', toastSuccess);
  $eventBus.off('toast:error', toastError);
});

</script>
