import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';

export default async function (id) {
  const auth = useAuthStore();
  const { idSupplier } = auth;
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.SUPPLIER_ID_IMAGE_ID, {
    method: 'DELETE',
    path: { idSupplier, id }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'supplier-image-delete',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
