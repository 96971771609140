<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">   
    <ConfirmationCard 
      :icon="CloseIcon"
      :title="$t('text-block-user-name', { name: store?.order?.buyer?.name })"
      :description="$t('text-confirm-block-user-title')"
      confirm-btn-text="text-block-user"
      :confirm-btn-loading="loading"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { ORDER_STATUS } from "@utils/constants";
import { useSelectedStore } from '@stores/selected'
import { useCustomerStore } from '@stores/customer';
import ConfirmationCard from "@components/common/confirmation-card";
import CloseIcon from '@components/icons/close-icon';

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const store = useSelectedStore();
const customer = useCustomerStore();
const loading = ref(false);

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const handleSubmit = async () => {
  loading.value = true;
  if (await supplierBlockUser(store?.order?.buyer?.id)) {
    const blocked = _.get(customer, 'supplier.blocked', []);
    blocked.push({
      id: store?.order?.buyer?.id,
      name: store?.order?.buyer?.name,
      logo: store?.order?.buyer?.logo
    })
    _.assignIn(customer?.supplier || {}, { blocked });
    $eventBus.emit('order:status:update', {
      id: store?.order?.id,
      status: ORDER_STATUS.REFUSED
    });
  }
  closeModal();
}

const handleCancel = () => {
  closeModal();
}

</script>
