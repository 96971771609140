import _ from 'lodash-es';

export default function (id, name) {
  return _.chain(`${id}-${name ? name : ''}`)
    .deburr()
    .trim()
    .words()
    .join('-')
    .toLower()
    .value();
}
