export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const route = useRoute();
  const cookie = useCookie('i18n_redirected');
  const switchLocalePath = useSwitchLocalePath();

  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.google) {
      resolve(window.google);
      return;
    }

    const code = route.path !== switchLocalePath(cookie.value || config.public.defaultLanguage) ? route.name.slice(-2) : (cookie.value || config.public.defaultLanguage);

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.public.placesApiKey}&libraries=places,marker&language=${code}`;
    script.async = true;
    script.onload = () => resolve(window.google);
    document.head.appendChild(script);
  });  
});
