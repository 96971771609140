<template>
    <div ref="observedElement" class="flex flex-col p-2 flex-1">
      <div :class="[ 'px-1 text-sm text-left prose max-w-none', item.id === selected || !isRead ? '' : 'text-white' ]">
        <span 
          v-for="(chunk, idx) in markdown(item.content)"
          :key="idx"
        >
          <Link
            v-if="chunk.type === 'link'"
            :href="chunk.href"
            :class="[ 'underline hover:no-underline', item.id === selected || !isRead ? '' : 'text-white' ]"
            @click="closeModal"
          >
            {{ chunk.content }}
          </Link>
          <span v-else>
            {{ chunk.content }}
          </span>
        </span>
        {{ (!item.content && item?.images?.length ? '' : ' ') }}
      </div>
      <MessageImages v-if="item?.images?.length" :item="item" />
      <div v-if="item.insertedAt" class="flex items-center justify-start text-2xs italic whitespace-nowrap px-1 mt-1 text-right">
        <div :class="item.id === selected || !isRead ? 'text-gray-500' : 'text-gray-300'">{{ $dayjs(item.insertedAt).fromNow() }}</div>
        <div v-if="position === 'right'" class="flex items-center justify-center" :title="$t('text-is-read')">
          <CheckIcon v-if="item.isRead" :class="[ 'w-3 h-3 ml-2', item.id === selected ? 'text-accent-dark' : 'text-accent-light' ]" :stroke-width="4" />
        </div>
      </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import MessageImages from "@components/chat/message-images";
import Link from "@components/ui/link/link";
import CheckIcon from "@components/icons/check-icon";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  selected: {
    type: Number,
    default: null
  },
  isRead: {
    type: Boolean,
    default: true
  },
  position: {
    type: String,
    required: true
  }
});

const { $eventBus, $dayjs } = useNuxtApp();
const auth = useAuthStore();
const observedElement = ref(null);
const observer = ref(null);
const timeoutId = ref(null);

const emit = defineEmits(['read']);

const scheduleRead = () => {
  emit('read');
}

const closeModal = () => {
  $eventBus.emit('modal:close');
}

onMounted(() => {
  if (auth.isLoggedIn && auth.idSupplier) {
    observer.value = new window.IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!props.isRead) {
          if (entry.isIntersecting) {
            timeoutId.value = setTimeout(scheduleRead, 5000);
          } else if (timeoutId.value) {
            clearTimeout(timeoutId.value);
            timeoutId.value = null;
          }
        }
      });
    }, {});

    if (observedElement.value) {
      observer.value.observe(observedElement.value);
    }
  }
});

onUnmounted(() => {
  if (auth.isLoggedIn && auth.idSupplier && observedElement.value) {
    observer.value.unobserve(observedElement.value);
  }
});

</script>
