<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex font-semibold text-lg md:text-xl text-heading mb-2">
      {{ $t('text-report-inspiration') }} 
    </h2>
    <hr class="mb-2 md:mb-4" />
    <div class="text-sm truncate w-full mb-5">
      {{ $t('text-reporting-inspiration') }}: <span class="font-bold">{{ store?.content?.name || '' }}</span>
    </div>
    <form @submit.prevent="">
      <div class="flex flex-col w-full">
        <Input
          :label="$t('text-name')"
          v-model="name"
          name="name"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.name?.message ? $t(errors.name.message) : ''"
        />
        <Input
          :label="$t('text-email')"
          v-model="email"
          name="email"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.email?.message ? $t(errors.email.message) : ''"
        />
        <TextArea
          :label="$t('text-reporting-details')"
          v-model="description"
          name="description"
          type="text"
          variant="outline"
          class-name="w-full mb-5"
          :error="errors?.description?.message ? $t(errors.description.message) : ''"
        />
      </div>
    </form>
    <div class="flex flex-col sm:flex-row items-center justify-between w-full">
      <Button 
          class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit" 
          variant="outline"
          :on-click="handleClose"
      >
        {{ $t('text-cancel') }}
      </Button>
      <Button 
          class="mt-5 sm:mt-0 w-full sm:w-fit" 
          :disabled="loading"
          :loading="loading"
          :on-click="handleSubmit"
      >
        {{ $t('text-submit-report') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import Input from "@components/ui/input";
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";

const store = useSelectedStore();
const customer = useCustomerStore();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const localePath = useLocalePath();
const config = useRuntimeConfig();

const errors = ref({});
const loading = ref(false);
const name = ref(customer?.fullName || '');
const email = ref(customer?.email || '');
const description = ref('');

const recaptchaInstance = useReCaptcha();
const recaptcha = async () => {
    await recaptchaInstance?.recaptchaLoaded();
    return await recaptchaInstance?.executeRecaptcha('reportad');
};

const handleClose = () => {
  $eventBus.emit('modal:close');
  if (store.action === 'RETURN_TO_CONTENT_MODAL') {
    $eventBus.emit('modal:open', 'CONTENT_DETAILS');
    store.setAction(null);
  }
};

const handleSubmit = async () => {
  loading.value = true;
  const result = await sendContactForm({
    name: name.value,
    email: email.value,
    subject: `${t('text-reporting-inspiration')}: ${store?.content?.name || ''}`,
    content: _.trim(description.value + '\n\n' + config.public.appBaseUrl + translatePath(`${ROUTES.INSPIRATION}/${slugify(store?.content?.id, store?.content?.name)}`)),
    recaptchaToken: await recaptcha()
  });
  if (result !== null) {
    handleClose();
    $eventBus.emit('toast:success', 'success.report-content-form.submit');
  } else {
    $toast.error(t('error.report-content-form.submit'));
  }
  loading.value = false;
}

</script>
