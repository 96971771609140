import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useCustomerStore } from '@stores/customer';

export default async function (error, options = {}) {
  const route = useRoute();
  const localePath = useLocalePath();
  const auth = useAuthStore();
  const cart = useCartStore();
  const customer = useCustomerStore();

  if (error?.data?.data?.code === 1042 && error?.data?.data?.message && error?.data?.data?.message.includes('Visitor')) {
    cart.reset();
    customer.reset();
    auth.reset();
    if (route.path === translatePath(route.path)) {
      await navigateTo(translatePath(ROUTES.HOME));
    } else if (options?.redirect) {
      await navigateTo(translatePath(options.redirect));
    } else {
      await navigateTo({ path: translatePath(ROUTES.LOGIN), query: { redirect_uri: translatePath(route.path) } });
    }
  }
}
