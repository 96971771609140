<template>
  <div class="min-h-screen grid place-items-center p-4 sm:p-8">
    <div class="text-center">
      <p class=" text-body-dark text-sm uppercase tracking-widest mb-4 sm:mb-5">
        {{ $t('404-heading', { code: error?.statusCode || 404 }) }}
      </p>
      <h1 class="font-bold text-2xl leading-normal sm:text-3xl text-bolder mb-5">
        {{ $t('404-sub-heading') }}
      </h1>
      <VueJsonPretty v-if="isLocal" class="mt-10 block" :data="error" />
      <div v-else class="mb-11">
        <Image
          src="/no-result.svg"
          :alt="$t('404-heading')"
          :width="600"
          :height="453"
        />
      </div>
      <Link
        :href="translatePath(ROUTES.HOME)"
        class="inline-flex items-center sm:text-base text-bolder underline focus:outline-none hover:no-underline hover:text-body-dark"
      >
        {{ $t('404-back-home') }}
      </Link>
    </div>
  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import Image from "@components/ui/image";
import Link from "@components/ui/link/link";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const props = defineProps({
  error: Object
})

const { t } = useI18n();
const localePath = useLocalePath();
const config = useRuntimeConfig();

const isLocal = process.env.NODE_ENV === 'development' || config.apiBaseUrl === 'https://aquarist.cz.ofvyvoj.cz';

onMounted(() => {
  sentryCaptureEvent({
    message: 'pages/error',
    level: 'error',
    extra: { error: props.error }
  });
});

useSeoMeta({
  title: t('404-heading', { code: props.error?.statusCode || 404 }),
  ogTitle: t('404-heading'),
  description: t('404-sub-heading'),
  ogDescription: t('404-sub-heading'),
  ogImage: '/no-result.webp',
  ogType: 'website'
});

</script>
