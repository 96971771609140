<template>
    <div>
        <label 
            :htmlFor="name" 
            :class="[ 'relative inline-flex items-center cursor-pointer leading-none mb-3 w-full', labelClasses[variant] ]"
        >
          <input 
            :id="name"
            :name="name"
            type="checkbox" 
            ref="ref"
            :checked="modelValue"
            class="sr-only peer"
            :disabled="disabled"
            :required="required"
            @change="$emit('update:modelValue', $event.target.checked)"
          />
          <div :class="[ 'peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[\'\'] after:absolute after:top-[2px] after:start-[2px]', variantClasses[variant], sizeClasses[dimension], disabled ? 'cursor-not-allowed' : 'cursor-pointer' ]"></div>
          <span :class="['ms-3', labelClasses[variant] ]">
            {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
          </span>
        </label>
        <p v-if="error" class="my-2 text-xs text-red-500">
            {{ error }}
        </p>
    </div>
</template>

<script setup>
const sizeClasses = {
  small: "w-9 h-5",
  medium: "w-11 h-6",
  big: "w-14 h-7",
};

const labelClasses = {
  normal: "text-body-dark font-semibold text-sm truncate",
  navbar: "text-light text-sm truncate",
};

const variantClasses = {
  normal: "bg-accent-neutral after:bg-white after:border-light after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-dark peer-checked:after:border-white ",
  navbar: "bg-primary-dark border border-solid border-white after:bg-light after:border-primary-dark after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-dark peer-checked:border-accent-dark peer-checked:after:border-accent-dark peer-checked:after:bg-accent-dark",
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: ''
    }, 
    label: {
        type: String,
        default: ''
    }, 
    name: {
        type: String,
        default: ''
    }, 
    error: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'normal' // "normal" | "navbar"
    },
    dimension: {
        type: String,
        default: 'medium' // "small" | "medium" | "big"
    }
});

</script>
