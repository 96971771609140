<template>
  <div class="w-full sm:w-74 h-fit" :class="className">
    <Transition :name="effect">
      <Aquarist v-if="show" :variant="variant" :action="action" />
    </Transition>
  </div>
</template>

<script setup>
import Aquarist from "@components/product/product-card/aquarist";

const props = defineProps({
  show: {
    type: Boolean,
    default: true
  },
  variant: {
    type: Object,
    required: true,
    default: () => { }
  },
  action: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  },
  effect: {
    type: String,
    default: 'fade'
  }
});

const { $eventBus } = useNuxtApp();

$eventBus.on('product:update', (value) => {
  if (value.id === props.variant.product.id) {
    props.variant.product = { ...props.variant.product, ...value };
  }
});

</script>

<style scoped>
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s ease;
}
</style>
