import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (idOrder, body, update = false) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_ORDER_ID_RATING, {
    method: update ? 'PUT' : 'POST',
    path: { idOrder },
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'order-rating',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
