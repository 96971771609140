<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex justify-center font-semibold text-lg md:text-xl text-heading mb-4">
      {{ $t('text-added-to-cart') }}
    </h2>
    <div class="flex flex-col sm:flex-row items-center justify-center w-full">
      <Button 
          class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit" 
          variant="outline"
          size="small"
          :on-click="handleClose"
      >
        <span class="text-sm">{{ $t('text-continue-shopping') }}</span>
      </Button>
      <Button 
          class="mt-5 sm:mt-0 w-full sm:w-fit" 
          size="small"
          :on-click="handleNavigate"
      >
        <div class="flex items-center space-x-2 text-sm">
          <div>{{ $t('text-proceed-order') }}</div>
          <ChevronRight class="w-4 h-4" />
        </div>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import Button from "@components/ui/button";
import ChevronRight from "@components/icons/chevron-right";

const store = useSelectedStore();
const { $eventBus } = useNuxtApp();
const localePath = useLocalePath();

const handleClose = () => {
  $eventBus.emit('modal:close');
};

const handleNavigate = async () => {
  $eventBus.emit('modal:close');
  await navigateTo(translatePath(`${ROUTES.CHECKOUT}/${store.cart.id}`));
};

</script>
