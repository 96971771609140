<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">   
    <ConfirmationCard 
      :title="$t('text-delete-account-title')"
      :description="$t('text-confirm-delete-account')"
      confirm-btn-text="text-send-email-link"
      :confirm-btn-loading="loading"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import ConfirmationCard from "@components/common/confirmation-card";

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const route = useRoute();
const loading = ref(false);

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const sendDeleteAccountLink = async (callbacks) => {
  loading.value = true;
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_ACCOUNT_DELETE, {
    method: 'POST'
  });
  if (status.value === 'error') {
    callbacks.onError(error.value);
  } else {
    await callbacks.onSuccess(data.value);
  }
  loading.value = false;
};

const handleSubmit = async () => {
  await sendDeleteAccountLink(
    {
      onSuccess: async (data) => {
        $toast.success(t('success.delete-account-link.sent'));
        closeModal();
      },
      onError: (error) => {
        if (process.client) {
          $toast.success(t('delete-account.error.unknown'));
        }
        sentryCaptureEvent({
          message: 'confirm-delete-account.handleSubmit',
          level: 'error',
          extra: { error }
        });
        closeModal();
      }
    }
  );
}

const handleCancel = () => {
  closeModal();
}

</script>
