export const ROUTES = {
  HOME: "/",
  APP: "/aplikace",
  CHECKOUT: "/kosik",
  PROFILE: "/profil",
  ACTIVATE_ACCOUNT: "/aktivace-uctu",
  DELETE_ACCOUNT: "/smazani-uctu",
  FORGOT_PASSWORD: "/zapomenute-heslo",
  CHANGE_PASSWORD: "/zmena-hesla",
  RESET_PASSWORD: "/reset-hesla",
  ORDERS: "/nakupy",
  HELP: "/pomoc",
  REGISTER: "/registrace",
  LOGIN: "/prihlaseni",
  LOGOUT: "/odhlaseni",
  PRODUCT: "/produkt",
  SALES: "/prodeje",
  WAITING: "/cekajici",
  CATEGORY: "/kategorie",
  ADS: "/inzeraty",
  ADS_MY: "/inzeraty/moje",
  AD_ADD: "/inzerat/novy",
  AD_EDIT: "/inzerat/editace",
  AD_DELETE: "/inzerat/smazani",
  RATE_CART: "/hodnoceni",
  PRIVACY: "/gdpr",
  TERMS: "/podminky",
  WHY_WITH_US: "/proc-inzerovat-u-nas",
  CONTACT: "/kontakt",
  OFFERS: "/nabidky",
  REQUESTS: "/poptavky",
  INSPIRATION: "/inspirace",
  INSPIRATION_ADD: "/inspirace/nova",
  INSPIRATION_EDIT: "/inspirace/editace",
  INSPIRATION_TYPE: "/inspirace/typu",
  NEWS: "/novinky",
  NEWS_ABOUT: "/novinky/tema",
  REFERENCE: "/reference",
  FAVORITES: "/oblibene",
  SUPPLIER: "/prodejce",
  RULES: "/pravidla",
  SETTINGS_NOTIFICATION: "/nastaveni/notifikace",

  en: {
    HOME: "/",
    APP: "/app",
    CHECKOUT: "/checkout",
    PROFILE: "/profile",
    ACTIVATE_ACCOUNT: "/activate-account",
    DELETE_ACCOUNT: "/delete-account",
    FORGOT_PASSWORD: "/forgot-password",
    CHANGE_PASSWORD: "/change-password",
    RESET_PASSWORD: "/reset-password",
    ORDERS: "/orders",
    HELP: "/help",
    REGISTER: "/registration",
    LOGIN: "/login",
    LOGOUT: "/logout",
    PRODUCT: "/product",
    SALES: "/sales",
    WAITING: "/unfinished",
    CATEGORY: "/category",
    ADS: "/classified-ads",
    ADS_MY: "/classified-ads/my",
    AD_ADD: "/classified-ad/new",
    AD_EDIT: "/classified-ad/edit",
    AD_DELETE: "/classified-ad/delete",
    RATE_CART: "/rating",
    PRIVACY: "/privacy",
    TERMS: "/terms",
    WHY_WITH_US: "/why-advertise-with-us",
    CONTACT: "/contact",
    OFFERS: "/offers",
    REQUESTS: "/requests",
    INSPIRATION: "/inspiration",
    INSPIRATION_ADD: "/inspiration/add",
    INSPIRATION_EDIT: "/inspiration/edit",
    INSPIRATION_TYPE: "/inspiration/type",
    NEWS: "/news",
    NEWS_ABOUT: "/news/about",
    REFERENCE: "/reference",
    FAVORITES: "/favorites",
    SUPPLIER: "/supplier",
    RULES: "/rules",
    SETTINGS_NOTIFICATION: "/settings/notifications",
  },

  sk: {
    HOME: "/",
    APP: "/aplikacia",
    CHECKOUT: "/kosik",
    PROFILE: "/profil",
    ACTIVATE_ACCOUNT: "/aktivacia-uctu",
    DELETE_ACCOUNT: "/zmazanie-uctu",
    FORGOT_PASSWORD: "/zabudnute-heslo",
    CHANGE_PASSWORD: "/zmena-hesla",
    RESET_PASSWORD: "/reset-hesla",
    ORDERS: "/nakupy",
    HELP: "/pomoc",
    REGISTER: "/registracia",
    LOGIN: "/prihlasenie",
    LOGOUT: "/odhlasenie",
    PRODUCT: "/produkt",
    SALES: "/predaje",
    WAITING: "/cakajuce",
    CATEGORY: "/kategorie",
    ADS: "/inzeraty",
    ADS_MY: "/inzeraty/moje",
    AD_ADD: "/inzerat/novy",
    AD_EDIT: "/inzerat/editacia",
    AD_DELETE: "/inzerat/zmazanie",
    RATE_CART: "/hodnotenie",
    PRIVACY: "/gdpr",
    TERMS: "/podmienky",
    WHY_WITH_US: "/preco-inzerovat-u-nas",
    CONTACT: "/kontakt",
    OFFERS: "/ponuky",
    REQUESTS: "/dopyty",
    INSPIRATION: "/inspiracia",
    INSPIRATION_ADD: "/inspiracia/nova",
    INSPIRATION_EDIT: "/inspiracia/editacia",
    INSPIRATION_TYPE: "/inspiracia/typu",
    NEWS: "/novinky",
    NEWS_ABOUT: "/novinky/tema",
    REFERENCE: "/referencia",
    FAVORITES: "/oblubene",
    SUPPLIER: "/predajca",
    RULES: "/pravidla",
    SETTINGS_NOTIFICATION: "/nastavenie/notifikacie",
  },

  pl: {
    HOME: "/",
    APP: "/aplikacja",
    CHECKOUT: "/kosz",
    PROFILE: "/profil",
    ACTIVATE_ACCOUNT: "/aktywacja-konta",
    DELETE_ACCOUNT: "/usuniecie-konta",
    FORGOT_PASSWORD: "/zapomniane-haslo",
    CHANGE_PASSWORD: "/zmiana-hasla",
    RESET_PASSWORD: "/reset-hasla",
    ORDERS: "/kupowanie",
    HELP: "/pomoc",
    REGISTER: "/rejestracja",
    LOGIN: "/logowanie",
    LOGOUT: "/wylogowanie",
    PRODUCT: "/produkt",
    SALES: "/sprzedaze",
    WAITING: "/czekajac",
    CATEGORY: "/kategorie",
    ADS: "/ogloszenia",
    ADS_MY: "/ogloszenia/moje",
    AD_ADD: "/ogloszenie/nowe",
    AD_EDIT: "/ogloszenie/redagowanie",
    AD_DELETE: "/ogloszenie/skasowanie",
    RATE_CART: "/ocena",
    PRIVACY: "/gdpr",
    TERMS: "/warunki",
    WHY_WITH_US: "/dlaczego-warto-sie-u-nas-reklamowac",
    CONTACT: "/kontakt",
    OFFERS: "/oferty",
    REQUESTS: "/popyt",
    INSPIRATION: "/inspiracja",
    INSPIRATION_ADD: "/inspiracja/nowa",
    INSPIRATION_EDIT: "/inspiracja/redagowanie",
    INSPIRATION_TYPE: "/inspiracja/typ",
    NEWS: "/nowosc",
    NEWS_ABOUT: "/nowosc/temat",
    REFERENCE: "/referencja",
    FAVORITES: "/ulubione",
    SUPPLIER: "/sprzedawca",
    RULES: "/zasady",
    SETTINGS_NOTIFICATION: "/ustawienia/powiadomien",
  },

  de: {
    HOME: "/",
    APP: "/app",
    CHECKOUT: "/korb",
    PROFILE: "/profil",
    ACTIVATE_ACCOUNT: "/kontoaktivierung",
    DELETE_ACCOUNT: "/kontoloschung",
    FORGOT_PASSWORD: "/passwort-vergessen",
    CHANGE_PASSWORD: "/passwort-andern",
    RESET_PASSWORD: "/passwort-reset",
    ORDERS: "/kaufen",
    HELP: "/hilfe",
    REGISTER: "/anmeldung",
    LOGIN: "/einloggen",
    LOGOUT: "/ausloggen",
    PRODUCT: "/produkt",
    SALES: "/verkaufe",
    WAITING: "/unfertig",
    CATEGORY: "/category",
    ADS: "/anzeigen",
    ADS_MY: "/anzeigen/my",
    AD_ADD: "/anzeige/neue",
    AD_EDIT: "/anzeige/editierung",
    AD_DELETE: "/anzeige/loschung",
    RATE_CART: "/bewertung",
    PRIVACY: "/gdpr",
    TERMS: "/bedingungen",
    WHY_WITH_US: "/warum-bei-uns-werben",
    CONTACT: "/kontakt",
    OFFERS: "/angeboten",
    REQUESTS: "/nachfragen",
    INSPIRATION: "/inspiration",
    INSPIRATION_ADD: "/inspiration/neue",
    INSPIRATION_EDIT: "/inspiration/editierung",
    INSPIRATION_TYPE: "/inspiration/art",
    NEWS: "/nachrichten",
    NEWS_ABOUT: "/nachrichten/thema",
    REFERENCE: "/referenz",
    FAVORITES: "/beliebte",
    SUPPLIER: "/anbieter",
    RULES: "/nutzungsregeln",
    SETTINGS_NOTIFICATION: "/einstellungen/benachrichtigung",
  }
};
