<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">   
    <ConfirmationCard 
      :icon="CheckIcon"
      :title="$t('text-accept-order-number', { number: store?.order?.number })"
      :description="$t('text-confirm-accept-order-title')"
      cancel-btn-class-name="border border-border-400 bg-transparent text-body hover:text-light hover:bg-gray-600 hover:border-gray-600 focus:ring-gray-600"
      confirm-btn-text="text-accept-order"
      :confirm-btn-loading="loading"
      confirm-btn-class-name="bg-accent hover:bg-accent-hover focus:bg-accent-hover text-light"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { ORDER_STATUS } from "@utils/constants";
import { useSelectedStore } from '@stores/selected'
import ConfirmationCard from "@components/common/confirmation-card";
import CheckIcon from '@components/icons/check-icon';

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const store = useSelectedStore();
const loading = ref(false);

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const handleSubmit = async () => {
  loading.value = true;
  await orderUpdateStatus(store?.order?.id, ORDER_STATUS.ACCEPTED);
  closeModal();
}

const handleCancel = () => {
  closeModal();
}

</script>
