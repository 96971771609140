<template>
    <div v-bind="getRootProps()" :title="$t('text-insert-image')">
        <input v-bind="getInputProps()" :name="name" />
        <div v-if="(files.length || iconSrc) && preview" :class="[ 'relative cursor-pointer overflow-hidden rounded-full', classes[size] ]">
            <Image :alt="_.get(files, '0.name')" :src="_.get(files, '0.url', iconSrc)" layout="fill" :priority="true" />
        </div>
        <div v-else class="flex whitespace-nowrap">
            <ImageSolid :class="[ 'cursor-pointer text-body-dark hover:text-accent-hover', classes[size] ]" />
            <span v-if="required" class="text-red-500">&nbsp;*</span>
        </div>
        <p v-if="error" class="my-2 text-xs text-red-500">
            {{ error }}
        </p>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { useDropzone } from "vue3-dropzone";
import ImageSolid from "@components/icons/solid/image-solid";
import Uploader from "@components/common/uploader";
import Image from "@components/ui/image";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Array,
        default: []
    },
    name: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    required: {
        type: Boolean,
        default: false
    }, 
    preview: {
        type: Boolean,
        default: true
    }, 
    iconSrc: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: 'normal' 
    },
    onChange: {
        type: Function,
        default: null
    }
});

const classes = {
  normal: 'w-10 h-10',
  big: 'w-20 h-20',
  huge: 'w-40 h-40',
};

const files = ref(props.modelValue);
const setFiles = async (data) => {
    _.map(data, (file) => {
        encodeFileContent(file);
        file.url = URL.createObjectURL(file);
        return file;
    });
    files.value = data;
}

watch(() => props.modelValue, (value) => {
    if (_.isArray(value) && _.get(value, 'length', null) === 0) {
        files.value = [];
    }
});

const onDrop = (acceptedFiles, rejectedReasons) => {
    if (props.onChange) {
        props.onChange(acceptedFiles);
    }
    try {
        setFiles(acceptedFiles);
    } catch (error) {
        Sentry.captureException(error);
    }
    emit('update:modelValue', acceptedFiles);
}

const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, accept: 'image/*' });

</script>
