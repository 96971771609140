export const useLocale = () => {
  return useState<string>('locale', () => useDefaultLocale().value);
}

export const useDefaultLocale = (fallback = 'cs_CZ') => {
  const locale = ref(fallback);
  if (process.server) {    
    const reqLocale = useRequestHeaders()['accept-language']?.split(',')[0];
    if (reqLocale) {
      locale.value = reqLocale.split(';')[0].replace('-', '_');
    }
  } else if (process.client) {
    const navLang = navigator.language;
    if (navLang) {
      locale.value = navLang.split(';')[0].replace('-', '_');
    }
  }
  if (![ 'cs_CZ', 'sk_SK', 'en_US', 'pl_PL', 'de_DE' ].includes(locale.value)) {
    locale.value = fallback;
  }  
  return locale;
}

export const useLocales = () => {
  const locale = useLocale();
  const locales = ref([
    'cs_CZ',
    'sk_SK', 
    'en_US',
    'pl_PL', 
    'de_DE'
  ])
  if (!locales.value.includes(locale.value)) {
    locales.value.unshift(locale.value)
  }
  return locales
}
